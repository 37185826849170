import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getHexColorWithOpacity } from '../../common/commonFunctions';
import { colorConfig } from '../../theme/colorConfig';
import './ProductTypeSelectorMobile.scss';

const contentPrimary = colorConfig.content.primary;

const variantStyleMap = {
  'filled': {
    valueContainer: (base) => ({
      ...base,
      textAlign: 'left',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: `${colorConfig.primary.gold[600]} !important`
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    control: (base, state) => ({
      ...base,
      minHeight: '45px',
      border: `1.5px solid ${colorConfig.secondary.beige[300]}`,
      color: 'black',
      boxShadow: 'none',
      opacity: state.isDisabled ? 0.4 : 1,
      background: colorConfig.supporting.seashell,
      cursor: 'pointer',
    }),
    placeholder: (base) => ({
      marginLeft: '2px',
      color: contentPrimary,
      opacity: '0.3',
      position: 'absolute',
    }),
    singleValue: (base) => ({
      ...base,
      // marginLeft: '20px',
      color: contentPrimary,
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '13px',
      lineHeight: '21px',
      boxSizing: 'border-box',
    }),
    option: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: getHexColorWithOpacity(colorConfig.primary.gold[600], 0.05), 
        color: contentPrimary, 
        cursor: 'pointer'
      },
      backgroundColor: state.isSelected ? colorConfig.primary.gold[300] : colorConfig.supporting.white,
      color: contentPrimary,
      alignItems: 'left',
      padding: '20px 15px',
    }),
    menu: (base) => ({
      ...base,
      marginLeft: '2px',
    }),
  },
  'outlined': {
    valueContainer: (base) => ({
      ...base,
      textAlign: 'left',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    control: (base) => ({
      ...base,
      minHeight: '45px',
      border: `1.5px solid ${colorConfig.secondary.beige[300]}`,
      color: contentPrimary,
      boxShadow: 'none',
      background: '',
      cursor: 'pointer',
      borderRadius: '24px',
      minWidth: '180px',
      padding: '0 8px'
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: '2px',
      color: contentPrimary,
      opacity: '0.3',
      position: 'absolute',
    }),
    singleValue: (base) => ({
      ...base,
      color: contentPrimary,
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '13px',
      lineHeight: '21px',
      fontWeight: '700',
      textTransform: 'uppercase',
      boxSizing: 'border-box',
    }),
    option: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: getHexColorWithOpacity(colorConfig.primary.gold[600], 0.05),
        color: contentPrimary,
        cursor: 'pointer'
      },
      backgroundColor: state.isSelected ? colorConfig.primary.gold[300] : colorConfig.supporting.white,
      color: contentPrimary,
      alignItems: 'left',
      padding: '20px 15px',
    }),
    menu: (base) => ({
      ...base,
      marginLeft: '2px',
      borderRadius: '12px',
      overflowY: 'hidden',
      padding: '2px'
    }),
  }
};

const noop = () => '';

export default function ProductTypeSelectorMobile({
  value,
  onChange,
  productOptions = [],
  variant = 'filled',
  className,
  ...props
}) {
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    setSelectedValue(productOptions.find(opt => opt.value === value));
  }, [value]);

  const SingleValue = ({ children, ...rest }) => {
    let childElem;

    switch (variant) {
      case "filled":
        childElem = <div><b>Product:</b> <span style={{ color: colorConfig.content.tertiary }}>{children}</span></div>;
        break;
      case "outlined":
        childElem = <span style={{ color: colorConfig.content.tertiary }}>{children}</span>;
        break;
      default:
        childElem = "";
    }

    return (
      <components.SingleValue {...rest}>
        {childElem}
      </components.SingleValue>
    )
  };

  const onProductTypeChange = ({ value }) => {
    onChange(value);
  }

  return (
    <Select
      options={productOptions}
      styles={variantStyleMap[variant]}
      className={`iwd-product-select-mobile ${className}`}
      classNamePrefix="iwd-ps"
      onChange={onProductTypeChange}
      value={selectedValue}
      components={{ SingleValue, IndicatorSeparator: noop }}
      {...props}
    />
  );
}