import './IwdInput.scss';
import React, { useMemo } from 'react';
import TextField from 'material-ui/TextField';

const Input = ({ subtitle, hasError, ...props }) => {
    const onKeyDownCapture = (event) => {
        const code = event.keyCode;
        if (code === 13) {
            // eslint-disable-next-line no-unused-expressions
            props.onEnterPressed?.(event);
        }
    }

    const onKeyDown = useMemo(() => {
        return props.onKeyDown || onKeyDownCapture;
    }, [props.onKeyDown]);

    
    return (
        <>
            <TextField
                className='iwd-input'
                InputProps={{
                    disableUnderline: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                onKeyDown={onKeyDown}
                onKeyUp={props.onKeyUp}
                rowsMax="3"
                {...props}
            />
            {subtitle && <div className={`iwd-input-subtitle ${hasError && 'error'}`}>{subtitle}</div>}
        </>
    );
};

export default Input;

