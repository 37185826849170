exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}.iwd-holding-view-switch span.iwd-hvs-opt-text{font-weight:bold;font-size:13px;line-height:21px;letter-spacing:1px;text-transform:uppercase;color:#70747e}.iwd-holding-view-switch span.iwd-hvs-opt-text.selected{color:#c1a866}.iwd-holding-view-switch .MuiSwitch-disabled .MuiSwitch-icon{color:rgba(154,134,82,.4)}.iwd-holding-view-switch .MuiSwitch-icon{color:#9a8652}.iwd-holding-view-switch .MuiSwitch-bar{background-color:#c1a866 !important}.iwd-holding-view-switch .MuiIconButton-root{height:40px}@media only screen and (max-width: 450px){.iwd-holding-view-switch .MuiSwitch-root{margin:0 -6px}}", ""]);

// exports
