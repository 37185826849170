import { getConfig } from 'utils/functions';
import { subDays, startOfMonth, addMonths, endOfMonth, startOfYear } from 'date-fns';
import moment from 'moment';
import { isEmpty, numDifferentiationInr, nonRoundingToFixed, inrFormatDecimal } from '../../utils/validators';
import { GRAPH_COLOR_PALETTE } from './constants';
import cloneDeep from 'lodash/cloneDeep';
import repeat from 'lodash/repeat';
import startCase from 'lodash/startCase';
import { colorConfig } from '../theme/colorConfig';

export function navigate(pathname, params, replace) {
  if (!replace) {
    this.history.push({
      pathname: `/iw-dashboard/${pathname}`,
      search: getConfig().searchParams,
      params,
    });
  } else {
    /* Required for screens that don't require to be considered in
      the history sequence when moving back through history using
      history.goBack() */
    this.history.replace({
      pathname: `/iw-dashboard/${pathname}`,
      search: getConfig().searchParams,
      params,
    });
  }
}

export const formatGrowthData = (current_amt_arr = [], invested_amt_arr = []) => {
  if (!current_amt_arr.length || !invested_amt_arr.length) return { data: [] };
  const obj = {
    current_amount: [],
    invested_amount: [],
  };
  let max = current_amt_arr[0].close,
    min = current_amt_arr[0].close,
    curr_val,
    inv_val;

  for (let i = 0; i < current_amt_arr.length; i++) {
    curr_val = current_amt_arr[i];
    inv_val = invested_amt_arr[i];
    max = Math.max(max, Number(curr_val.close), Number(inv_val.close));
    min = Math.min(min, Number(curr_val.close), Number(inv_val.close));
    obj.current_amount.push({
      x: curr_val.date,
      y: curr_val.close,
      color: colorConfig.secondary.green,
    });
    obj.invested_amount.push({
      x: inv_val.date,
      y: inv_val.close,
      color: colorConfig.secondary.silverLake[200],
    });
  }

  return {
    min: min * 0.98, // Multiplying by a factor to provide some padding area
    max: max * 1.02, // Multiplying by a factor to provide some padding area
    data: [
      {
        id: 'current_amount',
        data: obj.current_amount,
      },
      {
        id: 'invested_amount',
        data: obj.invested_amount,
      },
    ],
  };
};

export const dateFormater = (date) => {
  if (!date) return "";
  return moment(date).format('YYYY-MM-DD');
};

export const formatNumVal = (val, showFullValue) => {
  if (isEmpty(val) || !val) return '--';
  if (showFullValue) {
    return inrFormatDecimal(val, 2);
  } else {
    return numDifferentiationInr(val, 2, false, true);
  }
};

export const formatPercentVal = (val) => {
  if (isEmpty(val) || !val) return '0%';
  else if (val < 0.1) return '<0.1%';
  return `${nonRoundingToFixed(val, 2)}%`;
};

export const date_range_selector = {
  past_seven_days: () => [subDays(new Date(), 6), new Date()],
  past_two_weeks: () => [subDays(new Date(), 13), new Date()],
  past_month: () => [
    startOfMonth(addMonths(new Date(), -1)),
    endOfMonth(addMonths(new Date(), -1)),
  ],
  month_to_date: () => [startOfMonth(new Date()), subDays(new Date(), 1)],
  year_to_date: () => [startOfYear(new Date()), subDays(new Date(), 1)],
};

export const scrollElementToPos = (elementClass, posX = 0, posY = 0) => {
  const [scrollElem] = document.getElementsByClassName(elementClass) || [];
  if (isEmpty(scrollElem)) return;
  scrollElem.scrollTo(posX, posY);
};

export const addGraphColors = (data = []) => {
  const dataCopy = cloneDeep(data);
  // 'data' must always be an array of objects
  return dataCopy.map((value, idx) => {
    value.color = GRAPH_COLOR_PALETTE[idx];
    return value;
  })
}

export const returnsFormatter = (value) => {
  const stringValue = `${value}`; // Value from server is usually a number, but converting here to be safe
  
  if (['null', 'undefined', 'NaN', ""].includes(stringValue)) {
    return '';
  }

  const numberValue = Number(value);

  if (numberValue > 0 && numberValue < 0.1) return '<0.1%';

  return numberValue.toFixed(1) + '%';
}

export const formatMobileNumber = (mobile) => mobile ? `+91-${mobile.toString().replace('91|', '')}` : '--';

export const obscureAccNumber = (accNum) => {
  return obscureNumber(accNum, 2, 3);
};

export const userNameFormatter = (value) => {
  if (!value) return "";
  return startCase(value.toLowerCase());
}

export const obscureNumber = (value, numberOfStartingDigits, numberOfEndingDigits) => {
  /*
    @params:
      numberOfStartingDigits => integer value for number of digits to display at the start of the string (leading digits)
      numberOfEndingDigits => integer value for number of digits to display at the end of the string (trailing digits)
  */

  if (!value) return "";

  const digitsToObscure = value.length - (numberOfStartingDigits + numberOfEndingDigits);
  const leadingDigits = value.slice(0, numberOfStartingDigits);
  const trailingDigits = value.slice(-numberOfEndingDigits);
  const obscuredDigits = repeat('*', digitsToObscure);

  return `${leadingDigits}${obscuredDigits}${trailingDigits}`;
}

export const getHexColorWithOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
