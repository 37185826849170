import IwdTooltip from "../IwdTooltip/IwdTooltip";
import "./IwdInfoTooltip.scss"
import React from 'react';


function IwdInfoTooltip({
    title, 
    content,
    iconStyle,
    ...props
}) {
    return(
        <IwdTooltip
          title={title}
          content={content}
          {...props}
        >
        <img
          src={require(`assets/fisdom/ic-info-primary.svg`)}
          width={14}
          id="wr-i-btn"
          alt=""
          style={iconStyle}
        />
      </IwdTooltip>
    )
}

export default React.memo(IwdInfoTooltip);