export const colors = {
  blue: '#388BFF',
  green: '#38FF97',
  purple: '#A507E4',
  golden: '#F1AD2F',
  brown: '#F76F11',
  pink: '#FF84C1',
  mint: '#20F080',
  red: '#F50D0D',
  darkGreen: '#09B541',
  grey: '#BCBCBC',
  skyBlue: '#27F7C6',
  yellow: '#F1E900',
};
