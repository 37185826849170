import React from 'react';
import FilterRadioItem from '../IwdFilter/FilterRadioItem';

export default function ProductTypeSelectorDesktop({ value, onChange, disabled, productOptions = [] }) {
  return (
    <div id="iwd-product-select-desktop">
      <div className='iwd-filter-header' style={{ marginBottom: '20px' }}>Product</div>
      <FilterRadioItem
        options={productOptions}   
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}