import "./IwdDialog.scss";
import React from "react";
import { Dialog, DialogContent, DialogActions} from "material-ui";
import { OutlinedButton, PrimaryButton } from "../../common/Button";

function IwdDialog({
    secondaryButtonProps,
    primaryButtonProps,
    children,
    ...props
}) {

        return (
            <div>
                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    className="iwd-dialog"
                    {...props}
                >
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        {secondaryButtonProps &&
                            <OutlinedButton className="iwd-diag-button1" {...secondaryButtonProps}>
                                {secondaryButtonProps.title}
                            </OutlinedButton>
                        }
                        {primaryButtonProps &&
                            <PrimaryButton className="iwd-diag-button2" autoFocus {...primaryButtonProps}>
                                {primaryButtonProps.title}
                            </PrimaryButton>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );

   
};

export default IwdDialog;