import "./IwdSwitchAccount.scss";
import React, { useMemo } from 'react';
import { useUserContext } from '../../common/UserContext';
import SingleItemDesktop from './SingleItemDesktop';
import SingleItemMobile from './SingleItemMobile';
import { navigate as navigateFunc } from "../../common/commonFunctions";
import { useHistory } from "react-router-dom";
import { nativeCallback } from "../../../utils/native_callback";
import isEmpty from 'lodash/isEmpty';

function IwdSwitchAccount({ isMobileView, showProfileIcon = true }){
    let history = useHistory();
    const navigate = navigateFunc.bind({ history });
    const {
        selectedChildUser,
        updateSelectedChildUser,
        linkedChildUsers = []
    } = useUserContext();

    const usersToList = useMemo(() => {
        if (isEmpty(selectedChildUser)) return linkedChildUsers;
        return [selectedChildUser].concat(linkedChildUsers.filter(user => user.pan !== selectedChildUser.pan));
    }, [linkedChildUsers, selectedChildUser]);

    const sendEvents = () => {
        const eventObj = {
            "event_name": 'internal dashboard hni',
            "properties": {
                screen_name: "family switch",
                "user_action": 'switch to child',
            }
        };

        nativeCallback({ events: eventObj });
    };

    const onAccountSwitch = (user) => {
        if (user?.pan === selectedChildUser?.pan) return;
        sendEvents();
        updateSelectedChildUser(user);
        navigate('main/dashboard');
    }

    const ItemComponent = isMobileView ? SingleItemMobile : SingleItemDesktop;

    return(
        <div className={isMobileView? "iwd-container-mobile" : ""}>
            {usersToList.map(linkedUser => (
                <ItemComponent
                    showProfileIcon={showProfileIcon}
                    key={linkedUser.pan}
                    user={linkedUser}
                    isSelected={selectedChildUser.pan === linkedUser.pan}
                    onClick={onAccountSwitch}                                        
                />
            ))}
        </div>
    );
}

export default IwdSwitchAccount;