import React from 'react';
import StarIcon from '@material-ui/icons/Star';

const RatingStar = ({ value, color, height = '14px', className = '' }) => {
  return (
    <div className={className} style={{ height }}>
      {[...Array(5)].map((_, idx) => {
        if (idx < value) {
          return <StarIcon key={idx} style={{ color, fontSize: height, height, marginRight: '8px' }} />;
        } else {
          return (
            <StarIcon
              key={idx}
              style={{ color: 'rgb(210 210 210)', fontSize: height, height, marginRight: '8px' }}
            />
          );
        }
      })}
    </div>
  );
};

RatingStar.defaultProps = {
  color: '#FFD951',
};
export default RatingStar;
