import React from 'react';
import './ForgotPinDialog.scss';
//  ---------- Image Imports ------------
import fisdomIcon from 'assets/fisdom/fisdom_icon_gold.svg';
import PageCloseBtn from '../PageCloseBtn';
import { getConfig } from '../../../utils/functions';
import { PrimaryButton } from '../../common/Button';
//  -------------------------------------
const { appLink } = getConfig();

const ForgotPinDialog = ({ onClose }) => {
  window.scrollTo(0, 0);

  const goToFisdomApp = () => {
    window.open(appLink, '_blank');
  }
  
  return (
    <div id="iwd-fgt-pwd" className="animatedFade">
      <img src={fisdomIcon} alt="fisdom" width="40" id="iwd-fp-logo" />
      <PageCloseBtn onClick={onClose} />
      <div id="iwd-fp-content">
        <div id="iwd-fpc-header">Forgot your Fisdom pin?</div>
        <div id="iwd-fpc-subtext">
          Please login to Fisdom app to change your pin
        </div>
        <PrimaryButton
          onClick={goToFisdomApp}
          fullWidth
          uppercaseText
        >
          go to fisdom app
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ForgotPinDialog;