exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}#iwd-family-dashboard{background:#e0d4b3}#iwd-family-dashboard .iwd-header-title{text-transform:capitalize}#iwd-family-dashboard .iwd-family-dashboard-pg1 .iwd-header-subtitle{display:none}#iwd-family-dashboard .iwd-family-dashboard-pg1 .iwd-header-title{color:#101828}#iwd-family-dashboard .iwd-scroll-child:nth-of-type(1) .iwd-dot-loader.spinner>div{background-color:#9a8652}#iwd-family-dashboard .iwd-scroll-child:nth-of-type(1) .fisdom-ecb-error-title,#iwd-family-dashboard .iwd-scroll-child:nth-of-type(1) .fisdom-ecb-error-text{color:#101828}", ""]);

// exports
