import React, { useEffect } from 'react';
import ProductTypeSelectorDesktop from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorDesktop';
import ProductTypeSelectorMobile from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorMobile';
import LeftPanel from '../../mini-components/LeftPanel';
import { ACCOUNT_PRODUCT_TYPE_OPTIONS } from './constants';
import ProfileInfoCard from './ProfileInfoCard';
import { chunk } from 'lodash';
import { getConfig } from 'utils/functions';
import IwdCarousel, { IwdCarouselCardViewContainer } from '../../mini-components/IwdCarousel/IwdCarousel';
import { useState } from 'react';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';
import { obscureAccNumber } from '../../common/commonFunctions';
import TabContentContainer from './TabContentContainer.js';
import isEmpty from 'lodash/isEmpty';
import { useUserContext } from '../../common/UserContext';
import { colorConfig } from '../../theme/colorConfig';

const isMobileView = getConfig().isMobileDevice;

const getMfBanksFromKyc = (kyc) => {
  let banks = [];
  banks.push({ ...kyc.bank.meta_data, isPrimary: true });
  banks = banks.concat(kyc.additional_approved_banks || []);
  return banks.filter(bank => bank.bank_status === "verified");
}
const getBrokingBanksFromKyc = (kyc) => {
  const eq_bank = kyc.equity_primary_bank;
  if (eq_bank.bank_status === "verified") return [{ ...eq_bank, isPrimary: true }];
  return [];
}

export default function BankDetailsTab() {
  const [selectedProductType, setSelectedProductType] = useState(PRODUCT_TYPE_KEY_MAP.mf);
  const [bankListMap, setBankListMap] = useState([]);
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [pageError, setPageError] = useState(false);
  
  const { userKycDetails = {} } = useUserContext();

  const onProductTypeChange = (value) => {
    setSelectedProductType(value);
  }

  const initializeData = async () => {
    try {
      if (isEmpty(userKycDetails)) {
        throw new Error("KYC not found");
      }
      setBankListMap({
        [PRODUCT_TYPE_KEY_MAP.mf]: getMfBanksFromKyc(userKycDetails),
        [PRODUCT_TYPE_KEY_MAP.broking]: getBrokingBanksFromKyc(userKycDetails)
      });
      setPageError(false);
    } catch (err) {
      console.log(err);
      setPageError(true);
    } finally {
      setIsApiRunning(false);
    }
  }

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: isMobileView ? "column" : "row" }}>
      {!isMobileView &&
        <LeftPanel className="iwd-bd-left-panel">
          <ProductTypeSelectorDesktop
            value={selectedProductType}
            onChange={onProductTypeChange}
            disabled={isApiRunning}
            productOptions={ACCOUNT_PRODUCT_TYPE_OPTIONS}
          />
        </LeftPanel>
      }
      <ProductTypeSelectorMobile
        value={selectedProductType}
        onChange={onProductTypeChange}
        className="iwd-bd-product-select-mobile"
        disabled={isApiRunning}
        variant="outlined"
        productOptions={ACCOUNT_PRODUCT_TYPE_OPTIONS}
      />
      <TabContentContainer
        isLoading={isApiRunning}
        noData={isEmpty(bankListMap[selectedProductType])}
        hasError={pageError}
      >
        <CardView data={bankListMap[selectedProductType]} key={selectedProductType} />
      </TabContentContainer>
    </div>
  );
}

const CardView = React.memo(({
  data = []
}) => {
  const chunkedList = chunk(data, 2);

  const ContainerComponent = isMobileView ? React.Fragment : IwdCarousel;

  return (
    <ContainerComponent>
      {chunkedList.map(([bank1, bank2]) => (
        <IwdCarouselCardViewContainer>
          {bank1 && <BankCard bank={bank1} />}
          {bank2 && <BankCard bank={bank2} />}
        </IwdCarouselCardViewContainer>
      ))}
    </ContainerComponent>
  );
});

const primaryTagStyle = {
  backgroundColor: colorConfig.secondary.moonstone[300],
  color: colorConfig.supporting.white,
  padding: '4px 6px',
  borderRadius: '6px',
  textTransform: 'uppercase',
  fontSize: '8px',
  lineHeight: '21px',
  fontWeight: '600',
  letterSpacing: '0.1',
  verticalAlign: 'middle',
  marginLeft: '10px'
}

const BANK_ACC_TYPE_MAP = {
  'SB': "Savings Bank",
  'CA': "Current Account",
  'CC': "Cash Credit",
  'SB-NRE': "Savings Bank NRE",
  'SB-NRO': "Savings Bank NRO",
  'other': "Other"
}

const BankCard = ({ bank = {} }) => {
  let title = bank.bank_name;
  // TODO: Improve this code
  let acc_no = obscureAccNumber(bank.account_number);

  if (bank.isPrimary) {
    title = (
      <>
        {title} <span style={primaryTagStyle}>Primary</span>
      </>
    )
  }

  return (
    <ProfileInfoCard
      headerText={title}
      style={{ width: "50%" }}
      columnCount={2}
      rowCount={2}
    >
      <ProfileInfoCard.Item label="Account Number">
        {acc_no}
      </ProfileInfoCard.Item>
      <ProfileInfoCard.Item label="Account type">
        {BANK_ACC_TYPE_MAP[bank.account_type]}
      </ProfileInfoCard.Item>
      <ProfileInfoCard.Item label="IFSC code">
        {bank.ifsc_code}
      </ProfileInfoCard.Item>
      <ProfileInfoCard.Item label="Branch name">
        <div title={`${bank.ifsc_details?.branch}, ${bank.ifsc_details?.state}`} className="iwd-bd-branch-name">
          {`${bank.ifsc_details?.branch}, ${bank.ifsc_details?.state}`}
        </div>
      {/* TODO: CHeck with Sanchit for text-casing */}
        
      </ProfileInfoCard.Item>
    </ProfileInfoCard>
  )
}