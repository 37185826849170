import React from "react";

function SingleItemDesktop({ user, isSelected, onClick, showProfileIcon = true }){
    const onItemClick = () => onClick(user);

    return(
        <>
            <div className={`iwd-ul-container ${isSelected ? 'selected' : ''}`} onClick={onItemClick}>
                {showProfileIcon && 
                    <div className="iwd-ul-icon-left">
                        <div className="iwd-ul-icon-content">
                            {user.name?.[0]}
                        </div>
                    </div>
                }
                <div className="iwd-ul-details-right">
                    <div className="iwd-uldr-name">{user.name}</div>
                    <div className="iwd-uldr-pan">{user.pan}</div>
                    <div className="iwd-uldr-relation">{user.relation}</div>
                </div>
            </div>
            <div id='iwd-ul-item-divider'></div>
        </>
    )
}

export default React.memo(SingleItemDesktop);