import React from "react";
import { Imgc } from "../common/ui/Imgc";
import { getConfig } from "../utils/functions";
import "./style.scss";

const STATUS = {
  subscribe: "us_continue",
  unsubscribe: "us_done",
};
const productName = getConfig().productName;

function UnsubscribeStatus(props) {
  const { params } = props.location;
  const type = params?.type || "subscribe";
  return (
    <div className="unsubscribe-container">
      <div className="content unsubscribe-status">
        <Imgc
          className="fisdom-logo"
          src={require(`assets/${productName}/unsubscribe_product_logo.svg`)}
          alt=""
        />

        <div
          className="content-box"
          style={{ boxShadow: "none", marginTop: 80 }}
        >
          <Imgc
            className="unsubscribe-img"
            src={require(`assets/${productName}/${STATUS[type]}.svg`)}
            alt=""
            style={{ marginBottom: 50 }}
          />
          {type === "unsubscribe" ? (
            <>
              <p className="title">You've unsubscribed</p>
              <p className="subtitle">
                You will no longer recieve any promotional emails from us
              </p>
            </>
          ) : (
            <p className="thank-you">
              Thank you! You will continue to receive promotional emails from us
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnsubscribeStatus;
