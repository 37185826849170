import React, { useEffect, useState } from "react";
import FINITY_UPGRADE from "../../../constants";
import CollapsibleSection from "common/components/CollapsibleSection";
import "./styles.scss";

const STRINGS = FINITY_UPGRADE.MF_ON_FINITY;
const KEY_MAPPER = FINITY_UPGRADE.ACCORDIAN_KEY_MAPPER;

const MfOnFinity = ({ currentAccordian, setCurrentAccrodian }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setCurrentAccrodian(KEY_MAPPER.MF_FINITY);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentAccordian !== KEY_MAPPER.MF_FINITY && isOpen) {
      setIsOpen(false);
    }
  }, [currentAccordian]);

  return (
    <CollapsibleSection title={STRINGS.title} isOpen={isOpen} onClick={onClick}>
      <div className="finity-upgrade-collapsible-content">
        <div className="fuc-subtext">{STRINGS.subtext}</div>
      </div>
    </CollapsibleSection>
  );
};

export default MfOnFinity;
