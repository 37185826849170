import fisdom_hni_logo from 'assets/fisdom/fisdom_hni_logo_gold.svg';

import React, { useEffect, useState } from 'react';
import { getConfig } from "utils/functions";
import NavBar from '../mini-components/NavBar';
import NavBarMobile from '../mini-components/NavBarMobile';
import Dashboard from './Dashboard';
import Analysis from './Analysis';
import Holdings from './Holdings';
import Statements from './Statements';
import { storageService } from '../../utils/validators';
import { navigate as navigateFunc } from '../common/commonFunctions';
import { CSSTransition } from 'react-transition-group';
import IwdScreenLoader from '../mini-components/IwdScreenLoader';
import IwdErrorScreen from '../mini-components/IwdErrorScreen';
import { nativeCallback } from '../../utils/native_callback';
import InternalStorage from '../InternalStorage';
import { pick } from 'lodash';
import MyAccount from './MyAccount/MyAccount';
import FamilyDashboard from './Dashboard/FamilyDashboard';
import { useUserContext } from '../common/UserContext';
import { getLinkedUsers, getUserDetails } from '../common/ApiCalls';
import IwdSwitchAccountBar from '../mini-components/IwdSwitchAccountBar/IwdSwitchAccountBar';
import { STORAGE_CONSTANTS, TABS_LIST_PATH_MAP } from '../common/constants';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Toast from '../../common/ui/Toast';


const isMobileView = getConfig().isMobileDevice;

const TABS_MAP = {
  [TABS_LIST_PATH_MAP.dashboard]: <Dashboard />,
  [TABS_LIST_PATH_MAP.analysis]: <Analysis />,
  [TABS_LIST_PATH_MAP.holdings]: <Holdings />,
  [TABS_LIST_PATH_MAP.statements]: <Statements />,
  [TABS_LIST_PATH_MAP.myAccount]:<MyAccount/>,
  [TABS_LIST_PATH_MAP.familyDashboard]: <FamilyDashboard />,
};

const Main = (props) => {
  const params = useParams();
  const navigate = navigateFunc.bind(props);
  const [loginErr, setLoginErr] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChildView, setIsLoadingChildView] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const {
    updateUserDetails,
    updateUserKycDetails,
    updateLinkedChildUsers,
    isChildUserSelected,
    selectedChildUser,
    isWmLogin
  } = useUserContext();

  useEffect(() => {
    /*
      On first time load of app, avoid child view handling.
      First time load here is set to true only after 
      the first user details fetch request is completed
    */
    if (initialLoadComplete) {
      setIsLoadingChildView(true);
      setTimeout(() => {
        setIsLoadingChildView(false);
      }, 2000);
    }
  }, [selectedChildUser]);

  useEffect(() => {
    changeContainerOverflow();
  }, []);

  const changeContainerOverflow = () => {
    /*
      This function helps reduce Cumulative Layout Shift caused by the entry animation
      (iwd-entry-animate). Since the animation is run only once, at the time of load,
      we keep the overflow as 'hidden' initially and then set it back to 'auto' after
      a few seconds.
    */
    const mainContainer = document.getElementById('iwd-main');
    mainContainer.style.overflow = 'hidden';
    setTimeout(() => mainContainer.style.overflow = 'auto', 5000);
  }

  const sendEvents = () => {
    const eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        "user_action": 'landed',
        "screen_name": 'Landing page',
        "login_by": isWmLogin ? 'RM' : 'user',
      }
    };

    const storage_val = storageService().get(STORAGE_CONSTANTS.dashboardLandedTime);
    const link_click_time = storage_val ? new Date(storage_val) : null;
    const current_time = new Date();

    // If user lands on this page again within 6 hours, will not log/trigger the event
    if (!link_click_time || (current_time - link_click_time) / 60000 > 6 * 60) {
      storageService().set(STORAGE_CONSTANTS.dashboardLandedTime, current_time);
      nativeCallback({ events: eventObj });
    }
  };

  const fetchDetailsForWMLinkedUser = async () => {
    const clientUserId = storageService().getObject(STORAGE_CONSTANTS.selectedWmClientId);

    if (!clientUserId) throw new Error("Something went wrong! Please logout and try logging in again");

    return await Promise.all([
      getUserDetails({ user_id: clientUserId }),
      getLinkedUsers({ user_id: clientUserId })
    ]);
  }

  const fetchDetailsForLoggedInUser = async () => {
    return Promise.all([
      getUserDetails(),
      getLinkedUsers()
    ]);
  }

  const fetchUserCreds = async () => {
    try {
      setIsLoading(true);
      const detailsFetchFunction = isWmLogin ? fetchDetailsForWMLinkedUser : fetchDetailsForLoggedInUser;
      
      const [userObj, linkedChildUsers] = await detailsFetchFunction();
      sendEvents();
      const kyc = get(userObj, 'kyc.kyc.data', {});
      let user = get(userObj, 'user.user.data', {});
      user.pan = get(kyc, 'pan.meta_data.pan_number', "");
      
      updateLinkedChildUsers(linkedChildUsers);
      updateUserDetails(
        pick(
          user,
          ['email', 'pan', 'name', 'mobile', 'dt_first_investment', 'iwd_account_type', 'user_id']
        )
      );
      updateUserKycDetails(kyc);
      
      InternalStorage.setData('isSuperAdmin', user.permissions?.superadmin);
      setIsLoading(false);
      setInitialLoadComplete(true);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      showError(e);
    }
  };

  const showError = () => {
    setLoginErr(true);
    if (isWmLogin) {
      Toast("Invalid user selected. Please contact support");
      navigate('wm-dashboard');
    } else {
      setTimeout(() => {
        navigate('login');
      }, 5000);
    }
  };

  useEffect(() => {
    fetchUserCreds();
  }, []);

  if(isEmpty(TABS_MAP[params.tab])) {
    navigate('not-found');
  }

  if (isLoading || isLoadingChildView) {
    return (
      <div id="iwd-main">
        <IwdScreenLoader
          classes={{
            container: 'iwd-m-loadscreen iwd-fade',
            text: 'iwd-ml-text',
            image: 'iwd-m-loadscreen-logo'
          }}
          templateImage={fisdom_hni_logo}
          loadingText={isLoadingChildView
            ? "Switching account..."
            : isWmLogin ? "Loading..." : "Verifying login..."
          }
        />
      </div>
    )
  } else if (loginErr) {
    return (
      <div id="iwd-main">
        <IwdErrorScreen
          templateErrText="Redirecting to login page..."
          templateErrTitle="You have been logged out."
          classes={{
            container: 'iwd-m-errorscreen',
            title: 'iwd-me-title',
            text: 'iwd-me-text'
          }}
        />
      </div>
    )
  }

  return (
    <div id="iwd-main">
      {/* TODO: Make bar on top expand vertically */}
      {(isWmLogin || isChildUserSelected) &&
        <IwdSwitchAccountBar />
      }
      {isMobileView
        ? <NavBarMobile hideLogout={isWmLogin} />
        : <NavBar />
      }
      <CSSTransition
        in
        appear
        enter={false}
        exit={false}
        classNames='iwd-entry-animate'
        timeout={3000}
      >
        {TABS_MAP[params.tab]}
      </CSSTransition>
    </div>
  );
};

export default Main;
