import './IwdTableContainer.scss';
import React from 'react';
import { Pagination } from 'rsuite';
import IwdErrorScreen from '../IwdErrorScreen';
import IwdScreenLoader from '../IwdScreenLoader';

const IwdTableContainer = ({ children, isLoading, noData, noDataMessage, loadingText, className, ...props }) => {
  const renderChild = () => {
    if (isLoading) {
      return (
        <IwdScreenLoader loadingText={loadingText || 'Fetching data...'} />
      );
    } else if (noData) {
      return (
        <IwdErrorScreen
          hasNoData
          templateErrText={noDataMessage}
        />
      );
    }
    return children;
  }

  return (
    <section
      key={isLoading || noData}
      className={`
        iwd-table-container
        iwd-animatedFade
        ${className}
      `}
      {...props}
    >
      {renderChild()}
    </section>
  );
}

const Header = ({ children, title }) => {
  return (
    <div className='iwd-tc-header'>
      {title &&
        <HeaderTitle>{title}</HeaderTitle>
      }
      {children}
    </div>
  );
}

IwdTableContainer.Header = Header;

const HeaderTitle = ({ children }) => (
  <div className='iwd-tch-title'>{children}</div>
);

IwdTableContainer.HeaderTitle = HeaderTitle;

const TableContent = ({ isLoading, loadingText, noData, noDataMessage, children }) => {
  const renderChild = () => {
    if (isLoading) {
      return (
        <IwdScreenLoader loadingText={loadingText || 'Fetching data...'} />
      );
    } else if (noData) {
      return (
        <IwdErrorScreen
          hasNoData
          templateErrText={noDataMessage}
        />
      );
    }
    return children;
  }
  return (
    <div className='iwd-tc-data'>
      {renderChild()}
    </div>
  );
}

IwdTableContainer.TableContent = TableContent;

const TablePagination = (props) => {
  return (
    <div className='iwd-tc-pagination'>
      <Pagination
        first
        prev
        next
        classPrefix='iwd-rs-pagination rs-pagination'
        {...props}
      ></Pagination>
    </div>
  );
}

IwdTableContainer.Pagination = TablePagination;

export default IwdTableContainer;