import './IwdTabs.scss';
import React from 'react';

export default function IwdTabs({ tabsList = [], currentTab, onTabClick, classes }) {
  return (
    <div
      className={`iwd-tabs ${classes?.root || ''}`}
      style={{ gridTemplateColumns: `repeat(${tabsList.length}, auto)` }}
    >
      {tabsList.map((tab) => (
        <IwdTabPill
          key={tab}
          tab={tab}
          selected={currentTab === tab}
          onClick={onTabClick}
          className={classes?.tab}
        />
      ))}
    </div>
  );
}

export const IwdTabPill = ({ tab, selected, onClick, className = '', ...props }) => {
  const onTabClick = () => {
    onClick(tab);
  }

  return (
    <button
      className={`iwd-tab-pill ${selected ? 'selected' : undefined} ${className}`}
      onClick={onTabClick}
      {...props}
    >
      {tab}
    </button>
  );
};