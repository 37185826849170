import React from "react";
import fisdom_hni_advisory_logo from 'assets/fisdom/fisdom_hni_advisory_logo.svg';

export default function ConsentFormPage() {
  return (
    <div id="iwd-family-view-consent-page">
      <div id="iwd-fvc-header">
        <h2 id="iwd-fvc-title">Portfolio Data Access Policy</h2>
        <img
          src={fisdom_hni_advisory_logo}
          alt="fisdom"
        />
      </div>
      <section>
        <h3>Terms and conditions</h3>
        <ul id="iwd-fvc-tnc-points">
          <li>
            I hereby give my consent to link my Finwizard Technology Private Limited (“Fisdom”) account to the Parent User associated with this request.
          </li>
          <li>
            I understand and agree that my investments through Fisdom, including but not limited to demat/ stock broking holdings,
            Mutual Funds, PMS, AIF, Bonds, Unlisted Shares, Insurance and any other investments made through Fisdom, at all times
            will be visible on the dashboard of the Family Member.
          </li>
          <li>
            I hereby give consent and authorise Fisdom to allow my Family Members to View/Access my investments on their dashboard,
            until I withdraw my consent provided to Fisdom explicitly in the manner required by Fisdom from time to time.
          </li>
          <li>
            I will not hold Fisdom responsible for any dispute between me and Family Member on account of such consent.
          </li>
          <li>
            I further affirm, confirm, and undertake that I have read and understood the Privacy Policy and Terms and Conditions of
            Fisdom available on their website/mobile application, and I agree to such terms prescribed under the said Privacy Policy
            and Terms and Conditions.
          </li>
        </ul>
      </section>
    </div>
  );
}