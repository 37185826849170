import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import iv_stocks from "assets/iv_stocks.svg";
import FINITY_UPGRADE from "../../constants";
import "./upgradeLanding.scss";
import WhyMakeMove from "./subComponents/WhyMakeMove";
import EnhancedFeatures from "./subComponents/EnhancedFeatures";
import MfOnFinity from "./subComponents/MfOnFinity";
import FAQs from "./subComponents/FAQs";
import Button from "../../../common/ui/Button";
import CheckBox from "../../../common/ui/Checkbox";
import { get_migration_consent } from "../../common/ApiCalls";
import Toast from "../../../common/ui/Toast";
import { isEmpty } from "../../../utils/validators";
import { getConfig } from "../../../utils/functions";
import { nativeCallback } from "../../../utils/native_callback";
import { noop } from "lodash";

const STRINGS = FINITY_UPGRADE;

const UpgradeLanding = (props) => {

  useEffect(() => {
    if (getConfig().iOS) {
      nativeCallback({ action: 'hide_top_bar' });
    }  
  }, []);

  const [currentAccordian, setCurrentAccrodian] = useState("why_make_move");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = () => {
    const { url } = props.match;
    props.history.push({
      pathname: `${url}/request`,
      search: getConfig().searchParams,
    });
  };

  const handleCta = async () => {
    try {
      setShowLoader("button");
      const result = await get_migration_consent();

      if (!isEmpty(result)) {
        navigate();
      }
    } catch (error) {
      Toast(error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="ContainerWrapper upgrade-landing-wrapper">
      <Header showStatus iconStyle={{ marginLeft: "-16px" }} />
      <div className="header-container">
        <img className="header-icon" src={iv_stocks} alt="stocks" />
        <div className="header-title">{STRINGS.HEADER.title.text}</div>
        <div className="header-subtitle">{STRINGS.HEADER.subtitle.text}</div>
      </div>

      <div className="upgrade-accordian">
        <WhyMakeMove
          currentAccordian={currentAccordian}
          setCurrentAccrodian={setCurrentAccrodian}
        />
        <EnhancedFeatures
          currentAccordian={currentAccordian}
          setCurrentAccrodian={setCurrentAccrodian}
        />
        <MfOnFinity
          currentAccordian={currentAccordian}
          setCurrentAccrodian={setCurrentAccrodian}
        />
        <FAQs
          currentAccordian={currentAccordian}
          setCurrentAccrodian={setCurrentAccrodian}
        />
      </div>

      <div className="upgrade-footer">
        <div className="fuc-tnc">
          <CheckBox checked={true} handleChange={() => noop} />
          <div className="fuc-subtext">{STRINGS.TNC_NOTE.title}</div>
        </div>
        <Button
          classes={{
            button: "upgrade-button",
          }}
          showLoader={showLoader}
          buttonTitle={STRINGS.FOOTER.title}
          onClick={handleCta}
          color="default"
        />
      </div>
    </div>
  );
};

export default UpgradeLanding;
