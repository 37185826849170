import React from 'react';
import IwdCard from '../../mini-components/IwdCard';

export default function ProfileInfoCard({
  children,
  columnCount = 1,
  rowCount = 1,
  ...cardProps
}) {
  return (
    <IwdCard className='iwd-profile-info-card' {...cardProps}>
      <div
        className='iwd-pic-container'
        style={{
          gridTemplateColumns: `repeat(${columnCount}, auto)`,
          gridTemplateRows: `repeat(${rowCount}, auto)`
        }}
      >
        {children}
      </div>
    </IwdCard>
  );
}

ProfileInfoCard.Item = ({ label, style, children, lowercase }) => (
  <div className='iwd-pic-item' style={style}>
    <div className='iwd-field-label'>{label}</div>
    <div className={`iwd-pic-value ${lowercase && 'lowercase'}`}>{children || "--"}</div>
  </div>
);