import { Button } from 'material-ui';
import React, { memo } from 'react';
// ---------- Image Imports ------------
import fisdomLogo from 'assets/fisdom/fisdom_hni_logo_gold.svg';
import close from 'assets/ic_close_white.svg';
import banner from 'assets/fisdom/its_help.svg';
import IcSebi from '../../assets/fisdom/ic_sebi_white.svg';
import IcAMFI from '../../assets/fisdom/ic_amfi_white.svg';
import IcBSE from '../../assets/fisdom/ic_bse_white.svg';
// -------------------------------------

const HelpPage = (props) => {
  window.scrollTo(0, 0);
  return (
    <div id="iwd-help" className={props.className}>
      <h1>Help</h1>
      <p>
        Don’t worry! Managing money can be stressful at times. We are always
        here to help you.
      </p>
      <Button
        variant="flat"
        onClick={props.onClose}
        classes={{
          root: 'iwd-help-close',
        }}
      >
        <img src={close} alt="close" />
      </Button>
      <div className="iwd-help-contact">
        <div className="iwd-hc-title">MAIL US</div>
        <div className="iwd-hc-value">priority@fisdom.com</div>
        <div className="iwd-hc-subtext">Mon to Sat (Except for 2nd & 4th Sat)</div>
      </div>
      <div className="iwd-help-contact">
        <div className="iwd-hc-title">CALL US</div>
        <div className="iwd-hc-value">+91 96425-96425</div>
        <div className="iwd-hc-subtext">Mon to Sat (Except for 2nd & 4th Sat)</div>
      </div>
      <img
        src={banner}
        alt=""
        className="iwd-help-banner"
      />
      <div id="iwd-help-footer">
        <img src={fisdomLogo} alt="fisdom" id="iwd-help-fisdom-logo" />
        <div>
          <picture>
            <img src={IcSebi} alt="SEBI" />
            <small>SEBI registered investment advisor INA200005323</small>
          </picture>
          <picture>
            <img src={IcAMFI} alt="AMFI" />
            <small>Association of Mutual Funds of India registered mutual fund distributor ARN:103168</small>
          </picture>
          <picture>
            <img src={IcBSE} alt="BSE" />
            <small>
            BSE registered mutual fund distributor mutual fund code no:10140
            </small>
          </picture>
        </div>
      </div>
    </div>
  );
};

export default memo(HelpPage);
