import vectorMail from 'assets/ic_mail.svg';
import vectorPhone from 'assets/ic_mobile.svg';
// ----------------- ASSETS ------------------
import "./MyAccount.scss";
import React, { useMemo } from "react";
import PageHeader from "../../mini-components/PageHeader";
import SnapScrollContainer from "../../mini-components/SnapScrollContainer";
import LinkedAccount from "./LinkedAccount";
import PersonalInfoTab from "./PersonalInfoTab";
import { useUserContext } from "../../common/UserContext";
import { getConfig } from "../../../utils/functions";
import ScrollTopBtn from "../../mini-components/ScrollTopBtn";
import { formatMobileNumber, userNameFormatter } from "../../common/commonFunctions";
import { LINKED_ACCOUNT_TYPES } from "./constants";
import CTabs from '../../mini-components/IwdTabComponents/CTabs';
import CTab from '../../mini-components/IwdTabComponents/CTab';
import CTabPanel from '../../mini-components/IwdTabComponents/CTabPanel';
import BankDetailsTab from './BankDetailsTab';
import NomineeDetailsTab from './NomineeDetailsTab';
import WealthManagerDetailsTab from './WealthManagerDetailsTab';

const isMobileView = getConfig().isMobileDevice;

function MyAccount(){
    const [selectedTab, setSelectedTab] = React.useState(0);
    const { isWmLogin } = useUserContext();

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
    };    

    return(
        <div className='iwd-page' id='iwd-my-account-page'>
            <PageHeader hideLogout={isWmLogin}>
                <div className='iwd-profile-header-title'>My Account</div>
            </PageHeader>
            <SnapScrollContainer
                pages={3}
                hideFooter={isMobileView}
            >
                <SnapScrollContainer.Page pageNumber={1}>
                    <UserDetail />
                    <div className="iwd-map-acc-detail-page">
                        <CTabs
                            value={selectedTab}
                            handleChange={handleChange}
                            scrollable
                        >
                            <CTab label="Personal info" />
                            <CTab label="Bank details" />
                            <CTab label="Nominee details" />
                            <CTab label="Wealth manager's detail" /> 
                        </CTabs>
                        <CTabPanel value={selectedTab} index={0}>
                           <PersonalInfoTab />
                        </CTabPanel>
                        <CTabPanel value={selectedTab} index={1}>
                            <BankDetailsTab />
                        </CTabPanel>
                        <CTabPanel value={selectedTab} index={2}>
                            <NomineeDetailsTab />
                        </CTabPanel>
                        <CTabPanel value={selectedTab} index={3}>
                            <WealthManagerDetailsTab />
                        </CTabPanel>
                    </div>
                </SnapScrollContainer.Page>
                <SnapScrollContainer.Page pageNumber={2}>   
                    <LinkedAccount
                        pageType={LINKED_ACCOUNT_TYPES.child.key}
                    />
                </SnapScrollContainer.Page>
                <SnapScrollContainer.Page pageNumber={3}>
                    <LinkedAccount
                        pageType={LINKED_ACCOUNT_TYPES.parent.key}
                    />
                </SnapScrollContainer.Page>
                {isMobileView && <ScrollTopBtn />}
            </SnapScrollContainer>
        </div>
    );
}

const UserDetail = () => {
    const { userDetails } = useUserContext();
    const initials = useMemo(() => {
        if (userDetails?.name) {
            return userDetails.name.split(" ").slice(0, 2).map(val => val[0]).join("")
        }
        return "";
    }, [userDetails]);

    return (
        <div id="iwd-pp-user-detail">
            <div id="iwd-ppud-icon">
                <span>{initials}</span>
            </div>
            <div id="iwd-ppud-content">
                <div id="iwd-ppudc-name">{userNameFormatter(userDetails?.name)}</div>
                <div id="iwd-ppudc-pan"><span>PAN: </span>{userDetails?.pan}</div>
                <div className="iwd-ppudc-contact">
                    <div>
                        <img src={vectorPhone} alt="phone icon" />
                        {formatMobileNumber(userDetails?.mobile) || 'N/A'}
                    </div>
                    <div title={userDetails?.email}>
                        <img src={vectorMail} alt="mail icon" style={{ width: '16px' }} />
                        {userDetails?.email || "N/A"}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyAccount;