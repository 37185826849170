import './IwdCarousel.scss';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { colorConfig } from '../../theme/colorConfig';

const indicatorStyles = {
  background: colorConfig.primary.gold[600],
  width: `28px`,
  height: `3px`,
  borderRadius: `1.5px`,
  opacity: 0.3,
  display: 'inline-block',
  margin: '0 8px',
  cursor: 'pointer'
};

const selectedIndicatorStyles = {
  background: colorConfig.primary.gold[600],
  width: `83px`,
  height: `3px`,
  borderRadius: `1.5px`,
  opacity: 1,
  cursor: 'pointer'
}

export default function IwdCarousel({ children, classes = {}, ...carouselProps }) {
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, ...selectedIndicatorStyles }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            className="list"
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
      className={`iwd-carousel ${classes.container}`}
      {...carouselProps}
    >
      {children}
    </Carousel>
  );
}

export const IwdCarouselCardViewContainer = ({ children, className, ...props }) => (
  <div className={`iwd-carousel-card-view-container ${className}`} {...props}>
    {children}
  </div>
);