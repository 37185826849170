import './ActionConfirmationDialog.scss';
import React from 'react';
import IwdDialog from '../../mini-components/IwdDialog/IwdDialog';
import isFunction from 'lodash/isFunction';

export default function ActionConfirmationDialog ({
  dialogContent,
  contentFunctionParam,
  children,
  ...props
}) {
  return (
    <IwdDialog
      disableBackdropClick
      disableEscapeKeyDown
      {...props}      
    >
      <div className="iwd-action-dialog-img">
        {dialogContent.image &&
          <img src={dialogContent.image} alt="dialog" width="100" height="100" />
        }
      </div>
      <div className="iwd-action-dialog-content">
        {isFunction(dialogContent.content) ? dialogContent.content(contentFunctionParam) : dialogContent.content}
      </div>
      {children}
    </IwdDialog>
  );
};