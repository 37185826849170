import React from "react";
import "./collapsibleSection.scss";
import Collapse from "@material-ui/core/Collapse";
import iv_down_arrow from "assets/iv_down_arrow.svg";
import { bool, func, node, object, string } from "prop-types";
import { noop } from "lodash";

const CollapsibleSection = ({
  title,
  children,
  className,
  openIconName,
  closeIconName,
  isOpen,
  onClick,
}) => {
  const icon = isOpen ? openIconName : closeIconName;

  return (
    <div className={`collapsible-container ${className}`}>
      <div className="c-header" onClick={onClick}>
        <div className="c-title">{title}</div>
        <img
          src={icon}
          alt="down"
          className={`${isOpen ? "c-icon-open" : "c-icon-close"}`}
        />
      </div>
      <Collapse in={isOpen}>
        <div className="c-content">{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsibleSection;

CollapsibleSection.defaultProps = {
  title: "",
  children: null,
  className: "",
  openIconName: iv_down_arrow,
  closeIconName: iv_down_arrow,
  isOpen: false,
  onClick: noop,
};

CollapsibleSection.propTypes = {
  title: string,
  children: object,
  className: string,
  openIconName: node,
  closeIconName: node,
  isOpen: bool,
  onClick: func,
};
