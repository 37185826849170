import React, { useState } from "react";
import { Imgc } from "../common/ui/Imgc";
import "./style.scss";
import { getConfig } from "utils/functions";
import Checkbox from "../common/ui/Checkbox";
import Button from "material-ui/Button";
import Api from "../utils/api";
import DotDotLoader from "../common/ui/DotDotLoaderNew";
import toast from "../common/ui/Toast";
import { disableBodyTouch, getUrlParams } from "../utils/validators";

const productName = getConfig().productName;
const API_URL = "api/communication/unsubscription/by/link";

const checkboxOptions = [
  {
    title: "Newsletter",
    subtitle:
      "You'll miss out on a suite of invaluable research & where to invest ideas that can set you up for investment success",
    key: "research_newsletter",
  },
  {
    title: "Product",
    subtitle:
      "You'll miss out on updates on what new & exciting products that we are building for you.",
    key: "clevertap",
  },
  {
    title: "Marketing",
    subtitle:
      "You'll miss out on your exclusive access to special offers & deals, new NFO & IPO launches & much more",
    key: "marketing",
  },
];

const initialCheckbox = {
  research_newsletter: false,
  marketing: false,
  clevertap: false,
};


const UnsubscribePage = (props) => {
  const urlParams = getUrlParams();

  const initalizeCheckBox = () =>{
    if (urlParams?.group_name) {
      initialCheckbox[urlParams?.group_name] = true;
    }
    return initialCheckbox;
  }

  const [checkboxValue, setCheckboxValue] = useState(() => initalizeCheckBox());
  const [showLoader, setShowLoader] = useState({
    unsubscribe: false,
    subscribe: false,
  });

  const getUnsubscribeData = async (type, body) =>{
    try {
      const res = await Api.post(API_URL, body);
      const resultData = res.pfwresponse.result;
      if (res.pfwresponse.status_code === 200) {
        props.history.push({
          pathname: "unsubscribe/status",
          search: getConfig().searchParams,
          params: {
            type,
          },
        });
      } else {
        toast(resultData?.error || "Something went wrong. Please try again.");
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setShowLoader((prev) => ({
        ...prev,
        [type]: false,
      }));
      disableBodyTouch(true);
    }
  }
  const handleClick = async (type) => {
    setShowLoader((prev) => ({
      ...prev,
      [type]: true,
    }));

    const body = {
      communication_type: "email",
      subscription_type: type,
      requested_by: "user",
      encrypted_payload: urlParams?.payload || "",
      group_name_json: checkboxValue,
    };
    getUnsubscribeData(type, body);
    
  };

  const handleCheckBox = (value) => {
    setCheckboxValue((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  return (
    <div className="unsubscribe-container">
      <div className="content">
        <Imgc
          className="fisdom-logo"
          src={require(`assets/${productName}/unsubscribe_product_logo.svg`)}
          alt=""
        />
        <div className="content-box">
          <Imgc
            className="unsubscribe-img"
            src={require(`assets/${productName}/us_mail.svg`)}
            alt=""
          />

          <p className="title">Unsubscribing?</p>
          <p className="subtitle">
            What about staying connected for some exclusive offers & top
            news & insights from the investment world?
          </p>
          <p className="note">
            If you unsubscribe, you'll miss out on all this & more
          </p>
          <div className="checkboxes">
            <div>
              {checkboxOptions.map((option, index) => (
                <div className="checkbox-row" key={index}>
                  <Checkbox
                    defaultChecked
                    checked={checkboxValue[option.key]}
                    color="default"
                    name="checked"
                    handleChange={() => handleCheckBox(option.key)}
                  />
                  <div
                    className="checkbox-info"
                    onClick={() => handleCheckBox(option.key)}
                  >
                    <p className="checkbox-text" style={{ fontWeight: 600 }}>
                      {option.title}
                    </p>
                    <p className="checkbox-text" style={{ fontWeight: 400 }}>
                      {option.subtitle}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="footer">
            <Button
              onClick={() => handleClick("unsubscribe")}
              fullWidth={false}
              variant="raised"
              className="borderButton"
              disabled={props.disable}
            >
              {showLoader["unsubscribe"] ? (
                <DotDotLoader styleBounce={{ backgroundColor: "white" }} />
              ) : (
                "YES, UNSUBSCRIBE"
              )}
            </Button>
            <Button
              onClick={() => handleClick("subscribe")}
              fullWidth={false}
              variant="raised"
              color="secondary"
              className="filledButton"
              disabled={props.disable}
            >
              {showLoader["subscribe"] ? (
                <DotDotLoader styleBounce={{ backgroundColor: "white" }} />
              ) : (
                "STAY SUBSCRIBED"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribePage;
