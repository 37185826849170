import React, { useEffect, useState } from "react";
import CollapsibleSection from "common/components/CollapsibleSection";
import FINITY_UPGRADE from "../../../constants";
import { map } from "lodash";
import "./styles.scss";

const STRINGS = FINITY_UPGRADE.WHY_MAKE_MOVE;
const KEY_MAPPER = FINITY_UPGRADE.ACCORDIAN_KEY_MAPPER;

const WhyMakeMove = ({ currentAccordian, setCurrentAccrodian }) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClick = () => {
    setCurrentAccrodian(KEY_MAPPER.WHY_MAKE_MOVE);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentAccordian !== KEY_MAPPER.WHY_MAKE_MOVE && isOpen) {
      setIsOpen(false);
    }
  }, [currentAccordian]);

  return (
    <CollapsibleSection title={STRINGS.title} isOpen={isOpen} onClick={onClick}>
      <div className="finity-upgrade-collapsible-content">
        {map(STRINGS.content, (item, index) => (
          <div key={index} className="fuc-list-item">
            <div className="fuc-dot" />
            <div className="fuc-content">{item}</div>
          </div>
        ))}
      </div>
    </CollapsibleSection>
  );
};

export default WhyMakeMove;
