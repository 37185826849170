// ---------------- Assets ----------------------
import IcSecFinanceIcon from '../../assets/fisdom/ic_sec_finance.svg';
import IcSecConsCyclicalIcon from '../../assets/fisdom/ic_sec_consumer_cyclical.svg';
import IcSecCommunicationIcon from '../../assets/fisdom/ic_sec_communication.svg';
import IcSecConstructionIcon from '../../assets/fisdom/ic_sec_real_estate.svg';
import IcSecEnergyIcon from '../../assets/fisdom/ic_sec_energy.svg';
import IcSecHealthCare from '../../assets/fisdom/ic_sec_healthcare.svg';
import IcSecTechnologyIcon from '../../assets/fisdom/ic_sec_technology.svg';
import IcSecIndustrialsIcon from '../../assets/fisdom/ic_sec_industrials.svg';
import IcSecConsDefensiveIcon from '../../assets/fisdom/ic_sec_consumer_defensive.svg';
import IcSecBasicMaterialsIcon from '../../assets/fisdom/ic_sec_basic_materials.svg';
import IcSecUtilitiesIcon from '../../assets/fisdom/ic_sec_utility.svg';
import IcSecOthersIcon from '../../assets/fisdom/ic_sec_others.svg';
import ic_dashboard from '../../assets/ic_dashboard.svg';
import ic_analysis from '../../assets/ic_analysis.svg';
import ic_statement from 'assets/ic_statement.svg';
import ic_holdings from 'assets/ic_holding.svg';
import ic_family_dashboard from 'assets/ic_family_dashboard.svg';
import ic_profile from 'assets/ic_profile.svg';
import ic_family_dashbaord_gold from '../../assets/ic_family_dashboard_gold.svg';
import ic_dashboard_gold from '../../assets/ic_dashboard_gold.svg';
import ic_analysis_gold from 'assets/ic_analysis_gold.svg';
import ic_holdings_gold from 'assets/ic_holding_gold.svg';
import ic_statement_gold from 'assets/ic_statement_gold.svg';
import ic_profile_gold from 'assets/ic_profile_gold.svg';
// ----------------------------------------------
import React from 'react';
import { colorConfig } from '../theme/colorConfig';

export const STORAGE_CONSTANTS = {
  userDetails: 'iwd-user-details',
  selectedChildUser: 'iwd-selected-child-user',
  linkedChildUsers: 'iwd-linked-users',
  wmDetails: 'iwd-wm-details',
  selectedWmClientId: 'iwd-wm-selected-client-id',
  dashboardLandedTime: 'iwd-dashboard-landed-time',
  wmDashboardLandedTime: 'iwd-wm-dashboard-landed-time',
  userKycDetails: 'iwd-user-kyc-details',
}

export const INTERNAL_STORAGE_CONSTANTS = {
  wmClientList: 'iwd-is-wm-client-list',
  wmDetails: 'iwd-is-wm-details'
}

export const genericErrMsg = 'Something went wrong!';

export const TABS_LIST_PATH_MAP = {
  familyDashboard: 'family-dashboard',
  dashboard: 'dashboard',
  analysis: 'analysis',
  holdings: 'holdings',
  statements: 'statements',
  myAccount: 'my-account',
}

export const TABS_LIST = [{
  label: 'family dashboard ',
  path: TABS_LIST_PATH_MAP.familyDashboard,
  icon: ic_family_dashboard,
  selectedIcon: ic_family_dashbaord_gold,
  desc: 'Accumulated space for all linked accounts',
}, {
  label: 'dashboard',
  path: TABS_LIST_PATH_MAP.dashboard,
  icon: ic_dashboard,
  selectedIcon: ic_dashboard_gold,
  desc: 'Easy view for all your investments',
}, {
  label: 'analysis',
  path: TABS_LIST_PATH_MAP.analysis,
  icon: ic_analysis,
  selectedIcon: ic_analysis_gold,
  desc: 'Anatomy of your equity and debt investments',
}, {
  label: 'holdings',
  path: TABS_LIST_PATH_MAP.holdings,
  icon: ic_holdings,
  selectedIcon: ic_holdings_gold,
  desc: 'Fund summary and transactions',
}, {
  label: 'statements',
  path: TABS_LIST_PATH_MAP.statements,
  icon: ic_statement,
  selectedIcon: ic_statement_gold,
  desc: 'Download transactions, capital gain and ELSS report',
},{
  label: 'My Account',
  path: TABS_LIST_PATH_MAP.myAccount,
  icon: ic_profile,
  selectedIcon: ic_profile_gold,
  desc: 'Check your details',
}];

export const GRAPH_COLOR_PALETTE = [
  colorConfig.secondary.moonstone[300],
  colorConfig.secondary.salmon[300],
  colorConfig.secondary.orange[300],
  colorConfig.secondary.silverLake[300],
  colorConfig.secondary.red,
  colorConfig.secondary.green,
  colorConfig.secondary.slateGrey[300],
  colorConfig.secondary.rose[200],
  colorConfig.secondary.moonstone[200],
  colorConfig.secondary.salmon[200],
  colorConfig.secondary.orange[200],
  colorConfig.secondary.silverLake[200],
  colorConfig.secondary.slateGrey[200],
];

// import helper functions
export const topStocksIconMappings = {
  'Financial Services': IcSecFinanceIcon,
  Energy: IcSecEnergyIcon,
  Technology: IcSecTechnologyIcon,
  Industrials: IcSecIndustrialsIcon,
  'Consumer Defensive': IcSecConsDefensiveIcon,
  'Basic Materials': IcSecBasicMaterialsIcon,
  'Real Estate': IcSecConstructionIcon,
  Utilities: IcSecUtilitiesIcon,
  'Consumer Cyclical': IcSecConsCyclicalIcon,
  Healthcare: IcSecHealthCare,
  'Communication Services': IcSecCommunicationIcon,
  Others: IcSecOthersIcon,
};

export const GraphDateRanges = [{
  label: '1m',
  value: '1 month',
}, {
  label: '3m',
  value: '3 months',
}, {
  label: '6m',
  value: '6 months',
}, {
  label: '1y',
  value: '1 year',
}, {
  label: '3y',
  value: '3 years',
}, {
  label: '5y',
  value: '5 years',
}, {
  label: 'YTD',
  value: 'ytd',
}];


const TRANSACTION_TYPES = {
  mf: {
    Investment: {
      val: ['purchase', 'divreinvest', 'transferin'],
      img: <img src={require('assets/incr_arrow.svg')} alt={'invest'} />,
    },
    Withdrawal: {
      val: ['redemption', 'divpayout', 'transferout'],
      img: <img src={require('assets/decr_arrow.svg')} alt={'invest'} />,
    },
    'Switch in': {
      val: ['switchin'],
      img: <img src={require('assets/fisdom/arrow-right.svg')} alt={'invest'} />,
    },
    'Switch out': {
      val: ['switchout'],
      img: <img src={require('assets/fisdom/arrow-left.svg')} alt={'invest'} />,
    },
  },
  pms: {
    Inflow: {
      val: ['cashin'],
      img: <img src={require('assets/incr_arrow.svg')} alt={'invest'} />,
    },
    Outflow: {
      val: ['cashout'],
      img: <img src={require('assets/decr_arrow.svg')} alt={'invest'} />,
    }
  },
  aif: null
};

export const getTransactionTypeElement = (productType, type) => {
  const productTrxTypes = TRANSACTION_TYPES[productType];
  const ttype = Object.keys(productTrxTypes).find(key => productTrxTypes[key].val.includes(type));
  
  if (ttype) {
    return (
      <div className='iwd-table-type-container'>
        {productTrxTypes[ttype].img}
        <div className='iwd-table-type'>{ttype}</div>
      </div>
    );
  }
};

export const DEFAULT_FONT_FAMILY = "'Montserrat', 'Roboto', sans-serif";
