// -------------------- Assets ------------------
import close from 'assets/ic_close_white.svg';
import fisdom_hni_logo from 'assets/fisdom/fisdom_hni_logo_gold.svg';
import fisdom_hni_advisory_logo from 'assets/fisdom/fisdom_hni_advisory_logo_white.svg';
import "./Style.scss"

// ----------------------------------------------
import React, { useState } from 'react';
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import { Button } from 'material-ui';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../common/ApiCalls';
import { formatMobileNumber, navigate as navigateFunc, userNameFormatter } from '../common/commonFunctions';
import toast from '../../common/ui/Toast';
import { nativeCallback } from '../../utils/native_callback';
import { TABS_LIST_PATH_MAP } from '../common/constants';
import { useMemo } from 'react';
import IwdSwitchAccount from './IwdSwitchAccount/IwdSwitchAccount';
import { useUserContext } from '../common/UserContext';
import useFilteredTabs from '../pages/MyAccount/useFilteredTabs';
import isEmpty from 'lodash/isEmpty';
import { OutlinedButton } from '../common/Button';

const NavBarMobile = ({
  hideProfile,
  hideNavigation,
  hideMenu,
  hideLogout,
  profileData,
  disableLogoClick,
  ...props
}) => {
  const navigate = navigateFunc.bind(props);
  const { params } = useRouteMatch();
  const { tab: currentTab = TABS_LIST_PATH_MAP.dashboard } = params;

  const {
    userDetails,
    isChildUserSelected,
    hasLinkedChildUsers,
    clearContext,
  } = useUserContext();
  const [tabsToShow] = useFilteredTabs();

  const [expanded, setExpanded] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const fisdomHNILogo = useMemo(() => {
    const accountType = userDetails.iwd_account_type;
    if (accountType === 'direct') {
      return fisdom_hni_advisory_logo;
    }
    return fisdom_hni_logo;
  }, []);

  const userDetailsToShow = useMemo(() => {
    if (!isEmpty(profileData)) {
      return profileData;
    }

    return userDetails;
  }, [profileData, userDetails]);


  const sendEvents = (user_action, props) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: 'landing page',
        "user_action": user_action,
        ...props,
      }
    };
    nativeCallback({ events: eventObj });
  };

  const logoutUser = async () => {
    try {
      setLoggingOut(true);
      await logout();
      clearContext();
      // sendEvents('logout');
      navigate('login');
    } catch (err) {
      console.log(err);
      toast(err);
    }
    setLoggingOut(false);
  };

  const onTabClick = (tabName) => () => {
    sendEvents('tab clicked', {
      tab: tabName
    });
    setExpanded(false);
  }


  return (
    <div
      id="iwd-nav-mob"
      style={{
        height: expanded ? "100vh" : "",
        borderRadius: expanded ? "0" : "",
      }}
    >
      {!expanded ? (
        <div id="iwd-nm-header">
          {disableLogoClick ? (
            <img
              style={{ marginRight: "auto" }}
              src={fisdomHNILogo}
              id="iwd-nmh-fisdom-logo"
              alt="fisdom"
            />
          ) : (
            <Link
              to={`dashboard${props.location.search}`}
              onClick={() => setExpanded(false)}
              style={{ marginRight: "auto" }}
            >
              <img src={fisdomHNILogo} id="iwd-nmh-fisdom-logo" alt="fisdom" />
            </Link>
          )}
          {!hideMenu && (
            <Button className="iwd-nmh-menu" onClick={() => setExpanded(true)}>
              <MenuIcon fontSize="inherit" />
              <span id="iwd-nmhm-text">Menu</span>
            </Button>
          )}
          <div id="iwd-nmh-avatar" onClick={() => setExpanded(true)}>
            <span>{(userDetailsToShow.name || "").charAt(0)}</span>
          </div>
        </div>
      ) : (
        <div id="iwd-nm-body">
          <div id="iwd-nmb-header">
            <Link
              to={`dashboard${props.location.search}`}
              onClick={() => setExpanded(false)}
            >
              <img src={fisdomHNILogo} height="34" alt="fisdom" />
            </Link>
            <Button
              variant="flat"
              onClick={() => setExpanded(false)}
              classes={{
                root: "iwd-nav-close",
              }}
            >
              <img src={close} alt="close" />
            </Button>
          </div>
          {!hideNavigation &&
            tabsToShow.map(({ label, path, desc }, idx) => (
              <Link
                to={`${path}${props.location.search}`}
                onClick={onTabClick(label)}
                key={idx}
              >
                <div
                  className={`
                  iwd-nmb-link
                  ${currentTab === path ? "selected" : ""}
                `}
                >
                  <div className="iwd-nmbl-label">{label}</div>
                  <div className="iwd-nmbl-desc">{desc}</div>
                </div>
              </Link>
            ))}
          {hasLinkedChildUsers && <SwitchAccountSection />}
          {!isChildUserSelected && (
            <UserProfileSection
              data={userDetailsToShow}
              hideLogout={hideLogout}
              onLogoutClick={logoutUser}
              isButtonLoading={loggingOut}
              hideDivider={hideNavigation}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(NavBarMobile);

const SwitchAccountSection = () => (
  <>
    <div id="iwd-nmb-divider" />
    <div className="iwd-nmb-switch-acc-container">
        <div className="iwd-nsac-heading">Switch Account</div>
        <IwdSwitchAccount isMobileView={true} />
    </div>
  </>
);

const UserProfileSection = ({ data: userDetails, onLogoutClick, isButtonLoading, hideDivider, hideLogout }) => (
  <>
    {!hideDivider &&
      <div id="iwd-nmb-divider" />
    }
    <div id="iwd-nmb-profile">
      <div id="iwd-nmp-user-name">{userNameFormatter(userDetails.name)}</div>
      <div id="iwd-nmp-user-icon">{(userDetails.name || '').charAt(0)}</div>
      <div className="iwd-nmp-user-detail">
        <b>PAN: </b>
        {userDetails.pan || "--"}
      </div>
      <div className="iwd-nmp-user-detail">
        <b>Email: </b>
        {userDetails.email}
      </div>
      <div className="iwd-nmp-user-detail">
        <b>Mob.:</b>
        {'  '}{formatMobileNumber(userDetails.mobile)}
      </div>
    </div>
    {!hideLogout &&
      <OutlinedButton
        fullWidth
        uppercaseText
        onClick={onLogoutClick}
        style={{ marginTop: '30px'}}
        isLoading={isButtonLoading}
      >
        Logout
      </OutlinedButton>
    }
  </>
);