import React from 'react';
import { ResponsiveBubble } from '@nivo/circle-packing';

const IwdBubbleChart = ({ data = [] }) => {
  return (
    <ResponsiveBubble
      root={{
        name: 'main',
        children: data,
      }}
      // margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      identity='name'
      value='value'
      leavesOnly={true}
      colors={(item) => item.color}
      enableLabel={false}
      colorBy='name'
      padding={6}
      animate={false}
      isInteractive={false}
      motionStiffness={90}
      motionDamping={12}
    />
  );
};

export default IwdBubbleChart;
