import { TextField } from '@material-ui/core';
import React, { useMemo } from 'react';

export default function FilterDateItem({
  title = "Date Range",
  startDate,
  disableStartDate,
  onChangeStartDate,
  endDate,
  disableEndDate,
  onChangeEndDate
}) {
  const startDateLimits = useMemo(() => {
    let maxDate;
    try {
      maxDate = (new Date(endDate)).toISOString().substring(0, 10);
    } catch (err) {
      maxDate = (new Date()).toISOString().substring(0, 10);
    }

    return { max: maxDate }
  }, [endDate]);

  const endDateLimits = useMemo(() => {
    let minDate;
    try {
      minDate = new Date(startDate).toISOString().substring(0, 10);
    } catch (err) {
      minDate = ""
    }
    return {
      min: minDate,
      max: (new Date()).toISOString().substring(0, 10)
    }
  }, [startDate]);

  return (
    <div className="iwd-filter-date-item">
      <div className='iwd-filter-item-label'>{title}</div>
      <div>
        From: 
        <div className='iwd-fdi-datepicker'>
          <TextField
            id='date'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...startDateLimits
            }}
            disabled={disableStartDate}
            value={startDate || ""}
            onChange={onChangeStartDate}
          />
        </div>
      </div>
      <div style={{ marginTop: '5px' }}>
        To:
        <div className='iwd-fdi-datepicker'>
          <TextField
            id='date'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...endDateLimits
            }}
            disabled={disableEndDate}
            value={endDate || ""}
            onChange={onChangeEndDate}
          />
        </div>
      </div>
    </div>
  );
}