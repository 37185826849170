import React from 'react';
import download_icon from 'assets/download_icon.svg';
import ic_info from 'assets/fisdom/ic-info.svg';
import SVG from "react-inlinesvg";
import { formatAmountInr, formattedDate } from '../../../utils/validators';
import { getTransactionTypeElement } from '../../common/constants';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';
import { colorConfig } from '../../theme/colorConfig';
import { TextButton } from '../../common/Button';

const MF_FILTERS = [
  {
    id: 'date',
    type: 'date',
    category: 'Date Range',
  },
  {
    id: 'ttype',
    type: 'radio',
    category: 'Transactions Type',
    filters: [
      {
        label: 'Investment',
        value: 'invest',
      },
      {
        label: 'Withdrawal',
        value: 'withdraw',
      },
      {
        label: 'Switch',
        value: 'switch',
      },
    ],
  },
];

const PMS_FILTERS = [
  {
    id: 'date',
    type: 'date',
    category: 'Date Range',
  },
  {
    id: 'ttype',
    type: 'radio',
    category: 'Transactions Type',
    filters: [
      {
        label: 'Inflow',
        value: 'cashin',
      },
      {
        label: 'Outflow',
        value: 'cashout',
      }
    ],
  },
];

export const PRODUCT_FILTER_OPTIONS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_FILTERS,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_FILTERS,
  [PRODUCT_TYPE_KEY_MAP.aif]: null,
}

const MF_TRX_HEADERS_MAP = [
  {
    label: 'Date',
    accessor: 'tdate',
  },
  {
    label: 'Fund Name',
    accessor: 'mf_name',
  },
  {
    label: 'Type',
    accessor: 'ttype',
    formatter: (type) => {
      return getTransactionTypeElement(PRODUCT_TYPE_KEY_MAP.mf, type);
    },
  },
  {
    label: 'Unit',
    accessor: 'units',
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Unit Price',
    accessor: 'nav',
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Amount',
    accessor: 'amount',
    formatter: (type) => {
      return formatAmountInr(type);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
];

const PMS_TRX_HEADERS_MAP = [
  {
    label: 'Date',
    accessor: 'transaction_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y') :
        '--'
    ),
  },
  {
    label: 'Fund Name',
    accessor: 'scheme_name',
  },
  {
    label: 'Type',
    accessor: 'transaction_type',
    formatter: (type) => {
      return getTransactionTypeElement(PRODUCT_TYPE_KEY_MAP.pms, type);
    },
  },
  {
    label: 'Amount',
    accessor: 'amount',
    formatter: (type) => {
      return formatAmountInr(type);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
];

const AIF_TRX_HEADERS_MAP = [
  {
    label: 'Statement Date',
    accessor: 'statement_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y') :
        '--'
    ),
  },
  {
    label: 'Folio Number',
    accessor: 'folio_number',
  },
  {
    label: 'Fund Name',
    accessor: 'scheme_name',
  },
  {
    label: 'AMC report',
    accessor: 'report_url',
    formatter: (url) => {
        return (
          <a
            href={url}
            style={{ textDecoration: 'none' }}
            target="_blank"
            title={!url && "Please contact wealth manager"}
          >
            <TextButton disabled={!url} classes={{ label: 'iwd-aif-report-download-button' }}>
              <div>
                {url ? "Download" : "Not Available"}
              </div>
              <SVG
                preProcessor={(code) =>
                  code.replace(/fill=".*?"/g, `fill=${url ? colorConfig.primary.gold[600] : colorConfig.content.tertiary}`)
                }
                src={url ? download_icon : ic_info}
              />
            </TextButton>
          </a>
        );
    },
  },
];

export const TRANSACTIONS_TABLE_HEADERS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_TRX_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_TRX_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: AIF_TRX_HEADERS_MAP,
};

export const VIEW_FOR_FILTER_OPTIONS = [
  {
    id: 'viewFor',
    category: 'View For',
    filters: [
      {
        label: 'Past 7 days',
        value: 'past_seven_days',
      },
      {
        label: 'Past 2 weeks',
        value: 'past_two_weeks',
      },
      {
        label: 'Past months',
        value: 'past_month',
      },
      {
        label: 'Month to date',
        value: 'month_to_date',
      },
      {
        label: 'Year to date',
        value: 'year_to_date',
      },
      {
        label: 'Custom dates',
        value: 'custom_dates',
      },
    ],
  },
];

export const PRODUCT_TYPE_PAGE_PROPS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: {
    showPdf: true,
    showCsv: true,
  },
  [PRODUCT_TYPE_KEY_MAP.pms]: {
    showPdf: true,
    showCsv: true,
  },
  [PRODUCT_TYPE_KEY_MAP.aif]: {
    hideReportsDownload: true
  },
}