export const yesOrNoOptions = [
    {
        'name': 'Yes',
        'value': true
    },
    {
        'name': 'No',
        'value': false
    },
];

export const kidsOptions = ['1', '2', '3', '4', '5+'];

export const investmentOptions = [
    { name: 'Bank FD', rank: '1', type: 'bank-fds' },
    { name: 'Insurance policies', rank: '2', type: 'insurance-policies' },
    { name: 'Public provident fund', rank: '3', type: 'ppf' },
    { name: 'Real Estate', rank: '4', type: 'real-estate' },
    { name: 'Gold', rank: '5', type: 'gold' },
    { name: 'Shares', rank: '6', type: 'shares' },
    { name: 'Mutual funds', rank: '7', type: 'mutual-funds' },
    { name: 'Other investments', rank: '8', type: 'other-investment-rank' },
];