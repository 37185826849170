import React, { useEffect, useState } from "react";
import FINITY_UPGRADE from "../../../constants";
import CollapsibleSection from "common/components/CollapsibleSection";
import { map } from "lodash";

const STRINGS = FINITY_UPGRADE.ENHANCED_FEATURES;
const KEY_MAPPER = FINITY_UPGRADE.ACCORDIAN_KEY_MAPPER;

const EnhancedFeatures = ({ currentAccordian, setCurrentAccrodian }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setCurrentAccrodian(KEY_MAPPER.ENHANCED_FEATURES);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentAccordian !== KEY_MAPPER.ENHANCED_FEATURES && isOpen) {
      setIsOpen(false);
    }
  }, [currentAccordian]);

  return (
    <CollapsibleSection title={STRINGS.title} isOpen={isOpen} onClick={onClick}>
      <div className="finity-upgrade-collapsible-content">
        <div className="fuc-subtext">{STRINGS.content.title}</div>
        {map(STRINGS.content.subtexts, (subtitle, index) => {
          const [subtext1, subtext2] = subtitle.split(":");
          return (
            <div className="fuc-numbered-list" key={index}>
              <div className="fuc-subtext fuc-subtext-bold">{`${
                index + 1
              }.`}</div>
              <div className="fuc-subtext fuc-subtext-bold">
                {`${subtext1}${subtext2 ? ":" : ""} `}
                <span className="fuc-subtext">{subtext2}</span>
              </div>
            </div>
          );
        })}
      </div>
    </CollapsibleSection>
  );
};

export default EnhancedFeatures;
