import './IwdLineChart.scss';
import React from 'react';

const IwdLineChart = ({
  data,
  classes = {},
  labelKey = 'name',
  valueKey = 'share'
}) => {
  const {
    container: containerClass,
    child: childClass,

  } = classes;
  // const onElemHover = (item, idx) => {
  //   console.log('hovered', idx);
  //   let liBars = document.getElementsByClassName('li-wr');
  //   // console.log(liBars);
  //   // for (const liBar of liBars) {
  //   //   liBar.classList.add("li-wr-hovered")
  //   // }
  //   // liBars.map(liBar => );
  //   liBars[idx].style.flexBasis = `${item.share + 15}%`;
  //   liBars[idx].style.transform = `scaleY(1.6)`;
  // };

  // const onElemLeave = () => {
  //   let liBars = document.getElementsByClassName('li-wr');
  //   for (let i = 0; i < data.length; i++) {
  //     liBars[i].style.flexBasis = `${data[i].share}%`;
  //     liBars[i].style.transform = `scaleY(1)`;
  //   }
  // };

  return (
    <div>
      <ul id="iwd-bars" className={containerClass}>
        {data.map((dataObj, idx) => (
          <li
            key={idx}
            className={`iwd-bars-li ${childClass}`}
            style={{
              flexBasis: (idx === data.length - 1)
                ? `${Math.max(dataObj[valueKey], 1)}%` // Required in order to render last element when value is less than 1 (flex-basis with value < 1 renders an element that isn't visible)
                : `${dataObj[valueKey]}%`,
              backgroundColor: dataObj.color
            }}
            // onMouseEnter={() => onElemHover(item, idx)}
            // onMouseLeave={() => onElemLeave()}
          >
            <div>{dataObj[labelKey]}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IwdLineChart;