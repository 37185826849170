// ----------------- Assets ----------------------
import download_icon from 'assets/download_icon_gold.svg';
// -----------------------------------------------
import DownloadTypeDialog from "./DownloadTypeDialog";
import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';

export default function IwdReportDownloadMobile({
  dialogProps = {},
  buttonProps = {}
}) {
  const [openDownloadTypeModal, setOpenDownloadTypeModal] = useState(false);

  const toggleDownloadModal = () => {
    setOpenDownloadTypeModal(!openDownloadTypeModal);
  }

  return (
    <>
      <IconButton
        classes={{ root: 'iwd-report-download-mobile' }}
        onClick={toggleDownloadModal}
        {...buttonProps}
      >
        <img src={download_icon} alt='download' />
      </IconButton>
      <DownloadTypeDialog
        isOpen={openDownloadTypeModal}
        onClose={toggleDownloadModal}
        {...dialogProps}
      />
    </>
  );
}