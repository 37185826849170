import React, { createContext, useEffect, useState } from 'react';
import { getAllRelationshipRequests, hitNextPage } from '../../common/ApiCalls.js';
import usePagination from '../../hooks/usePagination.js';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer.js';
import { CHILD_REQUESTS_TABLE_HEADERS_MAP, LINKED_ACCOUNT_TYPES, PARENT_REQUESTS_TABLE_HEADERS_MAP } from './constants.js';
import isEmpty from 'lodash/isEmpty';
import IwdTable from '../../mini-components/IwdTable/IwdTable.js';

const RelationshipRequestsContext = createContext();
const { Provider: RelationshipRequestsProvider } = RelationshipRequestsContext;
export { RelationshipRequestsContext };

const CHILD_KEY = LINKED_ACCOUNT_TYPES.child.key;
const PAGE_SIZE = 3;

export default function LinkedAccountsTable({ accountType }) {
  const [isApiRunning, setIsApiRunning] = useState(true);
  const [requestsList, setRequestsList] = useState([]);
  const { activePage, setActivePage, pageMap, pushToPageMap } = usePagination();

  const fetchRelationshipRequests = async () => {
    try {
      setIsApiRunning(true);
      let response;
      if (activePage === 1) {
        response = await getAllRelationshipRequests({
          mode: accountType,
          page_size: PAGE_SIZE
        });
      } else {
        const urlToHit = pageMap[activePage];
        response = await hitNextPage(urlToHit);
      }
      pushToPageMap(response.next_page);
      setRequestsList(response[`${accountType}_accounts`]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsApiRunning(false);
    }
  }

  useEffect(() => {
    fetchRelationshipRequests();
  }, [accountType, activePage]);

  const refreshRequestsList = () => {
    if (activePage !== 1) {
      setActivePage(1);
    } else {
      fetchRelationshipRequests();
    }
  }

  const providerValue = {
    requestsList,
    refreshRequestsList
  }

  return (
    <RelationshipRequestsProvider value={providerValue}>
      <IwdTableContainer.TableContent
        isLoading={isApiRunning}
        loadingText={'Fetching requests...'}
        noData={isEmpty(requestsList)}
        noDataMessage={
          accountType === CHILD_KEY
          ? "No linked child accounts for you to view"
          : "No linked parent accounts for you to view"
        }
      >
        <IwdTable
          showHeaders={false}
          headersMap={
            accountType === CHILD_KEY
              ? CHILD_REQUESTS_TABLE_HEADERS_MAP
              : PARENT_REQUESTS_TABLE_HEADERS_MAP
          }
          data={requestsList}
        />
      </IwdTableContainer.TableContent>
      {!isApiRunning && requestsList?.length > 0 && (
        <IwdTableContainer.Pagination
          pages={pageMap.length - 1}
          activePage={activePage}
          onSelect={setActivePage}
        />
      )}
    </RelationshipRequestsProvider>
  );
}