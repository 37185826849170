import React, { useEffect, useState } from "react";
import CollapsibleSection from "common/components/CollapsibleSection";
import FINITY_UPGRADE from "../../../constants";
import "./styles.scss";
import FaqList from "./Faqlist";

const STRINGS = FINITY_UPGRADE.FAQS;
const KEY_MAPPER = FINITY_UPGRADE.ACCORDIAN_KEY_MAPPER;

const FAQs = ({ currentAccordian, setCurrentAccrodian }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setCurrentAccrodian(KEY_MAPPER.FAQ);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentAccordian !== KEY_MAPPER.FAQ && isOpen) {
      setIsOpen(false);
    }
  }, [currentAccordian]);

  return (
    <CollapsibleSection
      title={STRINGS.title}
      className="fuc-faq-container"
      isOpen={isOpen}
      onClick={onClick}
    >
      <FaqList />
    </CollapsibleSection>
  );
};

export default FAQs;
