import React from "react";
import OtpInput from 'react-otp-input';
import isFunction from "lodash/isFunction";

export default function IwdOtpInput({
  onEnterPressed,
  onKeyDown,
  ...props
}) {

  const handleKeyDown = (event) => {
    var code = event.keyCode;
    if (code === 13 && isFunction(onEnterPressed)) {
      onEnterPressed();
    } else if (isFunction(onKeyDown)) {
      onKeyDown(event);
    }
  };

  return (
    <OtpInput
      numInputs={4}
      shouldAutoFocus
      containerStyle="iwd-otp-container"
      inputStyle="iwd-otp-input"
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
}