import './IwdViewTypeSwitch.scss';
import React from 'react';
import { Switch } from 'material-ui';

export default function IwdViewTypeSwitch ({ value, onChange, disabled }) {
  return (
    <div className="iwd-holding-view-switch">
      <span className={`iwd-hvs-opt-text ${value || disabled ? '' : 'selected'}`}>List</span>
      <Switch
        checked={value}
        onChange={onChange}
        color="primary"
        disabled={disabled}
      />
      <span className={`iwd-hvs-opt-text ${value && !disabled ? 'selected' : ''}`}>Card</span>
    </div>
  );
}
