import React from 'react';
import { formatNumVal, returnsFormatter } from "../../common/commonFunctions";
import { nonRoundingToFixed } from '../../../utils/validators';
import { colorConfig } from '../../theme/colorConfig';

const ERROR_RED = colorConfig.secondary.red;
const SUCCESS_GREEN = colorConfig.secondary.green;

export const MEMBERS_INVESTMENT_DETAILS_HEADERS_MAP = [{
  label: 'Name',
  formatter: (value) => {
    return `${value.name}${value.isSelf ? ' (Self)' : ''}`
  }
}, {
  label: 'PAN',
  accessor: 'pan',
}, {
  label: 'Current value',
  accessor: 'current_value',
  formatter: (value) => {
    return formatNumVal(value);
  },
  tdProps: {
    style: {
      textAlign: 'right'
    }
  }
}, {
  label: 'Invested value',
  accessor: 'invested_value',
  formatter: (value) => {
    return formatNumVal(value);
  },
  tdProps: {
    style: {
      textAlign: 'right'
    }
  }
}, {
  label: 'total realised gains',
  accessor: 'realised_gains',
  formatter: (value) => {
    let newValue = value;
    if (newValue) newValue = Math.abs(newValue);
    const formattedVal = formatNumVal(newValue);

    return (
      <>
        {value ?
          <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>
            {value < 0 ? '-' : '+'}&nbsp;
          </span> :
          ''
        }
        {formattedVal}
      </>
    );
  },
  tdProps: {
    style: {
      textAlign: 'right'
    }
  }
}, {
  label: 'total unrealised gains',
  accessor: 'unrealised_gains',
  formatter: (value) => {
    let newValue = value;
    if (newValue) newValue = Math.abs(newValue);
    const formattedVal = formatNumVal(newValue);

    return (
      <>
        {value ?
          <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>
            {value < 0 ? '-' : '+'}&nbsp;
          </span> :
          ''
        }
        {formattedVal}
      </>
    );
  },
  tdProps: {
    style: {
      textAlign: 'right'
    }
  }
}, {
    label: 'Dividend',
    accessor: 'dividend',
    formatter: (value) => {
      return formatNumVal(value);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
}, {
    label: 'Annualised Return',
    accessor: 'annualized_return',
    formatter: (value) => {
      const formattedVal = `${value < 0 ? '' : '+'}` + returnsFormatter(value);

      return formattedVal ?
        <span style={{
          color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>{formattedVal}</span> :
        '--'
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
}];

export const INVESTMENT_SUMMARY_LIST_HEADERS_MAP = [
  {
    label: 'Product',
    accessor: 'product',
    tdProps: {
      style: {
        textAlign: 'left',
      }
    }
  },
  {
    label: 'Invested',
    accessor: 'total_invested_value',
    formatter: (value) => {
      return formatNumVal(value);
    },
    tdProps: {
      style: {
        textAlign: 'right',
        width: '130px',
      }
    }
  },
  {
    label: 'Current',
    accessor: 'total_current_value',
    formatter: (value) => {
      return formatNumVal(value);
    },
    tdProps: {
      style: {
        textAlign: 'right',
        width: '130px',
      }
    }
  },
  {
    label: 'Absolute returns',
    accessor: 'absolute_return',
    formatter: (value) => {
      const formattedVal = returnsFormatter(value);

      return formattedVal ?
        <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>{formattedVal}</span> :
        '--'
    },
    tdProps: {
      style: {
        textAlign: 'right',
      }
    }
  },
  {
    label: 'Portfolio contribution',
    accessor: 'contribution',
    formatter: (value) => {
      return value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    },
    tdProps: {
      style: {
        textAlign: 'right',
      }
    }
  },
];