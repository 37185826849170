import { getConfig } from "../../utils/functions";
import { nativeCallback } from "utils/native_callback";

const useBackNavigation = () => {
  const historyGoBack = () => {
    nativeCallback({ action: "exit_web" });
  };

  if (getConfig().generic_callback || this.state.project === "help") {
    window.callbackWeb.add_listener({
      type: "back_pressed",
      go_back: function () {
        historyGoBack();
      },
    });
  } else {
    window.PlutusSdk.add_listener({
      type: "back_pressed",
      go_back: function () {
        historyGoBack();
      },
    });
  }

  return {
    historyGoBack,
  };
};

export default useBackNavigation;
