import React from 'react';
import Tooltip from 'react-tooltip-lite';
import './IwdTooltip.scss';

function TooltipContent({title, content}){
    return(
        <div>
            {title && <div className="iwd-tt-title">{title}</div>}
            <div className="iwd-tt-content">{content}</div>
        </div>
    )
}

function IwdTooltip({
    title, 
    content,
    children, 
    ...props
}) {
    return(
        <>
            <Tooltip
                content={
                    <TooltipContent
                        title={title}
                        content={content}
                    />
                }
                background="white"
                arrow = {false}
                className="iwd-tooltip"
                {...props}
            >
                {children}
            </Tooltip>
        </>
    )
}

export default React.memo(IwdTooltip);

