import React from "react";

function SingleItemMobile({ user, isSelected, onClick }){
    const onItemClick = () => onClick(user);

    return(
        <div className={`iwd-ul-container-mobile ${isSelected ? 'selected' : ''}`} onClick={onItemClick}>
            <div className="iwd-ul-icon-left-mobile">
                <div className="iwd-ul-icon-content-mobile">
                    {user.name?.[0]}</div>
                </div>
            <div className="iwd-ul-details-right-mobile">
                <div className="iwd-uldr-name-mobile">{user.name}</div>
                <div className="iwd-uldr-pan-mobile">{user.pan}</div>
                <div className="iwd-uldr-relation-mobile">{user.relation}</div>
            </div>
        </div>
    )
}

export default React.memo(SingleItemMobile);