import React, { useState } from 'react';
import { nativeCallback } from '../../../utils/native_callback';
import { TextButton } from '../../common/Button';
import HoldingDetail from './HoldingDetail';
import './Style.scss';

const HoldingListActionColumn = ({ isin, fundData, productType }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const sendEvents = () => {
    const eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        user_action: 'more details',
        screen_name: 'holdings',
        product_selected: productType,
      }
    };

    nativeCallback({ events: eventObj });
  };

  const onMoreDetailsClicked = () => {
    setOpenDetail(true);
    sendEvents();
  }

  return (
    <>
      <TextButton
        classes={{
          root: 'iwd-hc-more'
        }}
        onClick={onMoreDetailsClicked}
      >
        <span>More details</span>
      </TextButton>
      {openDetail &&
        <HoldingDetail
          isin={isin}
          investmentDetail={fundData}
          onCloseClick={() => setOpenDetail(false)}
        />
      }
    </>
  );
}

export default React.memo(HoldingListActionColumn);