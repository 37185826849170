exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}.iwd-table-container{margin-top:30px;position:relative;margin-bottom:20px;overflow:hidden}.iwd-table-container .iwd-tc-data{height:100%;overflow:auto}.iwd-tc-header{display:flex;justify-content:space-between;align-items:center;padding:30px 50px 30px 50px;background-color:#fbfaf9}.iwd-tch-title{font-weight:700;font-size:17px;line-height:27px;color:#101828;flex:1 1 auto}.iwd-tch-title+.iwd-table-container{margin-top:10px}.iwd-tc-pagination{display:flex;justify-content:center;align-items:center;padding:20px}.iwd-tc-pagination .iwd-rs-pagination>li>a{font-size:13px;line-height:24px;color:#70747e}.iwd-tc-pagination .iwd-rs-pagination>li>a:hover{color:#c1a866}.iwd-tc-pagination .iwd-rs-pagination>li.rs-pagination-btn-active>a{font-weight:bold;text-decoration:underline;color:#101828}.iwd-tc-pagination .iwd-rs-pagination>li.rs-pagination-btn-active>a:hover{color:#c1a866}@media only screen and (max-width: 450px){.iwd-tc-pagination{display:flex;justify-content:center !important}}", ""]);

// exports
