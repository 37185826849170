import React from "react";
import { useHistory } from "react-router-dom";
import { nativeCallback } from "../../../utils/native_callback";
import { storageService } from "../../../utils/validators";
import { TextButton } from "../../common/Button";
import { navigate as navigateFunc } from "../../common/commonFunctions";
import { STORAGE_CONSTANTS } from "../../common/constants";
import { useUserContext } from "../../common/UserContext";

const ViewClientDetails = ({ value }) => {
  const history = useHistory();
  const navigate = navigateFunc.bind({ history });
  const { wmDetails } = useUserContext();

  const sendEvents = (props) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        "user_action": 'switch account',
        screen_name: 'client directory',
        ...props
      }
    };
    nativeCallback({ events: eventObj });
  };


  const switchAccountClicked = () => {
    storageService().setObject(STORAGE_CONSTANTS.selectedWmClientId, value.user_id);
    sendEvents({
      client_pan: value.pan,
      client_name: value.client_name,
      rm_user_id: wmDetails.user_id,
      rm_name: wmDetails.name,
      rm_email: wmDetails.email,
      rm_mobile: wmDetails.mobile,
    })
    navigate("main/dashboard");
  };

  return (
    <>
      <TextButton onClick={switchAccountClicked}>
        Switch Account
      </TextButton>
    </>
  );
};

export default ViewClientDetails;
