// ----------------- Assets ----------------------
import './IwdFilter.scss';
// -----------------------------------------------
import React, { useCallback, useEffect, useState } from 'react';
import FilterRadioItem from './FilterRadioItem';
import compact from 'lodash/compact';
import { PrimaryButton } from '../../common/Button';
import FilterDateItem from './FilterDateItem';
import { dateFormater } from '../../common/commonFunctions';

const IwdFilterDesktop = ({
  filterOptions,
  filterValue,
  onFilterChange,
  disabled
}) => {
  const [filterState, setFilterState] = useState();
  const [canClear, setCanClear] = useState(false);
  const [canApply, setCanApply] = useState(false);

  useEffect(() => setFilterState(filterValue), [filterValue]);

  useEffect(() => {
    const filterValues = compact(Object.values(filterState || {}));
    const isFilterDirty = !!filterValues.length;
    setCanClear(isFilterDirty);
  }, [filterState]);

  const handleFilterSelect = useCallback((id, value) => {
    setFilterState(oldState => {
      if (!oldState) {
        return { [id]: value };
      } else {
        return { ...oldState, [id]: value };
      }
    });
    setCanApply(true);
  }, []);

  const handleDateChange = (dateType, e) => {
    const { value } = e.target;
    const formattedValue = dateFormater(value);

    if (filterState?.[dateType] !== formattedValue) {
      setFilterState({
        ...filterState,
        [dateType]: formattedValue,
        viewFor: 'custom_dates'
      });
      setCanApply(true);
    }
  };

  const applyFilters = () => {
    if (canClear) {
      // If canClear is false, no filters are selected, can skip this call
      onFilterChange(filterState);
      setCanApply(false);
    }
  }

  const clearFilters = () => {
    if (!canClear) return;

    if (filterState) {
      onFilterChange(null);
    }
    setFilterState(null);
    setCanClear(false);
    setCanApply(false);
  };

  const renderFilters = () => (
    <div className="iwd-filter-body">
      {filterOptions?.map((filterItem) => {
        if (filterItem.type === 'radio') {
          return (
            <FilterRadioItem
              key={filterItem.id}
              title={filterItem.category}
              value={filterState?.[filterItem.id] || ''}
              options={filterItem.filters}
              onChange={handleFilterSelect.bind(null, filterItem.id)}
              disabled={disabled}
            />
          );
        } else if (filterItem.type === 'date') {
          return (
            <FilterDateItem
              startDate={filterState?.from_tdate}
              endDate={filterState?.to_tdate}
              onChangeStartDate={handleDateChange.bind(null, 'from_tdate')}
              onChangeEndDate={handleDateChange.bind(null, 'to_tdate')}
            />
          );
        }
        return "";
      })}
      
    </div>
  );

  return (
    <div id="iwd-filter" className={disabled && "disabled"}>
      <section className='iwd-filter-header-container'>
        <div className='iwd-filter-header'>Filters</div>
      </section>
      {renderFilters()}
      <section className='iwd-filter-footer-container'>
        <div
          className={`iwd-filter-clear ${!canClear && 'iwd-disable-clear'}`}
          onClick={clearFilters}
        >
          Clear All
        </div>
        <PrimaryButton
          className='iwd-filter-apply-btn'
          color='primary'
          variant='contained'
          onClick={applyFilters}
          disabled={!canApply}
        >
          Apply
        </PrimaryButton>
      </section>
    </div>
  );
};

export default IwdFilterDesktop;
