import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import { colorConfig } from '../../theme/colorConfig';

const styles = {
  root: {
    borderBottom: `1.5px solid ${colorConfig.primary.gold[400] }`,
  },
  indicator: {
    backgroundColor: colorConfig.action.brand,
  },
  flexContainer: {
    justifyContent: 'flex-start',
    columnGap: '10%',
    '@media (max-width: 450px)': {
      columnGap: '24px'
    }
  },
  scroller: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    marginBottom: '0 !important'
  },
  scrollButtons: {
    '&:nth-child(1)': {
      display: 'none'
    }
  }
};

const CTabs = ({
  value,
  children,
  handleChange,
  classes={},
  ...props
}) => {
  return (
    <Tabs 
      value={value} 
      onChange={handleChange} 
      classes={{ root: `iwd-new-tabs ${classes.root}`, ...classes }}
      {...props}
    >
      {children}
    </Tabs>
  );
}
export default withStyles(styles)(CTabs);

  