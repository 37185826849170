import './LinkedAccounts.scss';
import React, { useCallback, useState } from 'react';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import AddMembers from './AddMembers';
import LinkedAccountsTable from './LinkedAccountsTable';
import { OutlinedButton } from '../../common/Button';
import { nativeCallback } from '../../../utils/native_callback';
import { LINKED_ACCOUNT_TYPES } from './constants';
import { useUserContext } from '../../common/UserContext';

const CHILD_KEY = LINKED_ACCOUNT_TYPES.child.key;
const PARENT_KEY = LINKED_ACCOUNT_TYPES.parent.key;

function LinkedAccount({ pageType }){
    const { isWmLogin } = useUserContext();
    const [openAddMemberPage, setOpenAddMemberPage] = useState(false);
    const [refreshListKey, setRefreshListKey] = useState(0);

    const onAddMemberPageClose = (shouldRefresh) => {
        setOpenAddMemberPage(false);
        if (shouldRefresh )setRefreshListKey(refreshListKey + 1);
    }

    const sendEvents = (user_action, props) => {
        const eventObj = {
            "event_name": 'internal dashboard hni',
            "properties": {
                user_action,
                ...props
            }
        };
        nativeCallback({ events: eventObj });
    };

    const onAddMemberClick = () => {
        sendEvents('add_member', {
            screen_name: 'child account table'
        });
        setOpenAddMemberPage(!openAddMemberPage);
    }

    const linkedChildTable = useCallback(() => (
        <LinkedAccountsTable accountType={CHILD_KEY} key={refreshListKey} />
    ), [refreshListKey]);

    const linkedParentTable = useCallback(() => (
        <LinkedAccountsTable accountType={PARENT_KEY} />
    ), []);

    return(
        <>
            <div className='iwd-linked-account'>
                <IwdTableContainer>
                    <IwdTableContainer.Header>
                        <div className="iwd-la-header">
                            <div className="iwd-lah-top">
                                <IwdTableContainer.HeaderTitle>
                                    {LINKED_ACCOUNT_TYPES[pageType].title}
                                </IwdTableContainer.HeaderTitle>
                                {pageType === CHILD_KEY &&
                                    <AddMemberButton
                                        onClick={onAddMemberClick}
                                        disabled={isWmLogin || pageType === PARENT_KEY}
                                    />
                                }
                            </div>

                        </div>
                    </IwdTableContainer.Header>
                    {pageType === CHILD_KEY ? linkedChildTable() : linkedParentTable()}
                </IwdTableContainer>
                
            </div>
            <AddMembers onClose={onAddMemberPageClose} open={openAddMemberPage} key={openAddMemberPage} /> 
            {/*
                setting 'key' for AddMembers allows for the component to be remounted 
                everytime it is opened or closed, ensuring internal state of the component
                to be reset on open/close actions thereby preventing state leakage from
                previous open session
             */}
        </>
    );
}

export default LinkedAccount;

const AddMemberButton = (props) => (
    <OutlinedButton {...props} classes={{ raised: 'iwd-add-member-btn' }}>
        +Add a new member
    </OutlinedButton>
);