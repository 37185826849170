import * as React from 'react'
import { storageService } from '../../utils/validators';
import { STORAGE_CONSTANTS } from './constants';
import isEmpty from 'lodash/isEmpty';

const UserContext = React.createContext();

export function UserProvider({ children }) {
  const storedUserDetails = storageService().getObject(STORAGE_CONSTANTS.userDetails) || {};
  const storedUserKycDetails = storageService().getObject(STORAGE_CONSTANTS.userKycDetails) || {};
  const storedLinkedUsers = storageService().getObject(STORAGE_CONSTANTS.linkedChildUsers) || {};
  const storedChildUserDetails = storageService().getObject(STORAGE_CONSTANTS.selectedChildUser) || {};
  const storedWmDetails = storageService().getObject(STORAGE_CONSTANTS.wmDetails) || {};

  const [userDetails, setUserDetails] = React.useState(storedUserDetails);
  const [userKycDetails, setUserKycDetails] = React.useState(storedUserKycDetails);
  const [linkedChildUsers, setLinkedChildUsers] = React.useState(storedLinkedUsers);
  const [selectedChildUser, setSelectedChildUser] = React.useState(storedChildUserDetails);
  const [wmDetails, setWmDetails] = React.useState(storedWmDetails);

  const updateUserDetails = (value) => {
    storageService().setObject(STORAGE_CONSTANTS.userDetails, value);
    setUserDetails(value);
  };

  const updateUserKycDetails = (value) => {
    storageService().setObject(STORAGE_CONSTANTS.userKycDetails, value);
    setUserKycDetails(value);
  };

  const updateSelectedChildUser = (value) => {
    if (!value) value = {};
    storageService().setObject(STORAGE_CONSTANTS.selectedChildUser, value);
    setSelectedChildUser(value);
  };

  const updateLinkedChildUsers = (value) => {
    storageService().setObject(STORAGE_CONSTANTS.linkedChildUsers, value);
    setLinkedChildUsers(value);
  };

  const removeLinkedChildUser = (pan) => {
    const newList = linkedChildUsers.filter(user => user.pan !== pan);
    setLinkedChildUsers(newList);
  };

  const updateWmDetails = (value) => {
    storageService().setObject(STORAGE_CONSTANTS.wmDetails, value);
    setWmDetails(value);
  };
  
  const clearContext = () => {
    updateUserDetails({});
    updateUserKycDetails({});
    updateSelectedChildUser({});
    updateLinkedChildUsers([]);
    updateWmDetails({});
  };

  const value = {
    userDetails,
    updateUserDetails,
    userKycDetails,
    updateUserKycDetails,
    linkedChildUsers,
    updateLinkedChildUsers,
    removeLinkedChildUser,
    hasLinkedChildUsers: !!linkedChildUsers?.length,
    selectedChildUser,
    updateSelectedChildUser,
    isChildUserSelected: !isEmpty(selectedChildUser),
    wmDetails,
    updateWmDetails,
    isWmLogin: !isEmpty(wmDetails),
    clearContext,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context;
}