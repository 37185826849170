import { useState } from "react";
import { storageService } from "../../utils/validators";
import { PRODUCT_TYPE_KEY_MAP } from "../mini-components/IwdProductTypeSelector/constants";

export default function useProductType(storageKey) {
  const storedProductType = storageService().getObject(storageKey) || PRODUCT_TYPE_KEY_MAP.mf;
  const [selectedProductType, setSelectedProductType] = useState(storedProductType);

  const updateSelectedProductType = (value) => {
    setSelectedProductType(value);
    storageService().setObject(storageKey, value);
  }

  return {
    selectedProductType,
    updateSelectedProductType
  }
}