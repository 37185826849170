/* eslint-disable no-throw-literal */
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import omitBy from 'lodash/omitBy';
import Api from '../../utils/api';
import { buildUrlWithQueryParams } from '../../utils/functions';
import { storageService } from '../../utils/validators';
import InternalStorage from '../InternalStorage';
import { PRODUCT_TYPE_KEY_MAP } from '../mini-components/IwdProductTypeSelector/constants';
import { genericErrMsg, STORAGE_CONSTANTS } from './constants';

const platform = 'fisdom';

export const getUserIdForRequest = () => {
  const wmUser = storageService().getObject(STORAGE_CONSTANTS.wmDetails);
  const user = storageService().getObject(STORAGE_CONSTANTS.userDetails);
  const childUser = storageService().getObject(STORAGE_CONSTANTS.selectedChildUser);

  if (!isEmpty(wmUser)) {
    if (!isEmpty(childUser)) return childUser.user_id;
    else return user?.user_id || "";
  }

  if (!childUser || isEmpty(childUser)) {
    return '';
  }

  return childUser.user_id;
};

const handleApiResponse = (res, onSuccess, onFailure) => {
  if (res.pfwstatus_code !== 200 || !res.pfwresponse || isEmpty(res.pfwresponse)) {
    throw genericErrMsg;
  }

  const { result = {}, status_code: status } = res.pfwresponse;

  if (status === 200) {
    if (isFunction(onSuccess)) {
      return onSuccess(result);
    }
    return result;
  } else if (isFunction(onFailure)) {
    return onFailure(result);
  } else {
    throw result.message || result.error || genericErrMsg;
  }
}

export const login = async (params) => {
  const { mobileNo, countryCode, ...rest } = params;
  try {
    const res = await Api.get('api/iam/userauthstatus', {
      auth_type: 'mobile',
      auth_value: `${params.countryCode}|${params.mobileNo}`,
      ...rest,
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const emailLogin = async (params) => {
  try {
    const res = await Api.post('api/user/login', params);

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const emailRegister = async (params) => {
  try {
    const res = await Api.post(
      `api/user/register?email=${params.email}&password=${params.password}`
    );

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const resendVerification = async (params) => {
  try {
    const res = await Api.get('/api/resendverfication', params);

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const forgotPassword = async (params) => {
  try {
    const res = await Api.get('/api/forgotpassword', params);

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const logout = async () => {
  try {
    const res = await Api.get('api/logout');

    const onSuccess = (result) => {
      storageService().clear();
      InternalStorage.clearStore();
      return result;
    }

    return handleApiResponse(
      res,
      onSuccess
    );
  } catch (e) {
    throw e;
  }
};

export const resendOtp = async () => {
  try {
    const res = await Api.get('api/resendotp', {});

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getUserDetails = async (queryParams = {}, reqParams = {}) => {
  try {
    const res = await Api.post(
      buildUrlWithQueryParams('api/user/account/summary', queryParams),
      {
        kyc: ["kyc"],
        user: ["user"],
        ...reqParams
      }
    );

    const onSuccess = (result) => result.data;

    return handleApiResponse(res, onSuccess);
  } catch (e) {
    throw e;
  }
};

export const getOverview = async (params) => {
  try {
    const res = await Api.get('/api/privatewealth/report/overall', {
      ...params,
      user_id: getUserIdForRequest(),
      platform,
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getGrowthData = async (productType, params) => {
  const user_id = getUserIdForRequest();
  try {
    let res;
    switch (productType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        res = await Api.get('/api/invest/report/get/performance-graph', {
          ...params,
          user_id,
          platform,
        });
        break;
      case PRODUCT_TYPE_KEY_MAP.pms:
        res = await Api.get(`/api/privatewealth/graph/get/performance-graph?product_type=pms`, {
          ...params,
          user_id,
        });
        break;
      default:
        throw 'No productType prop provided';
    }

    const onSuccess = (result) => {
      return result.data;
    }

    return handleApiResponse(res, onSuccess);
  } catch (e) {
    throw e;
  }
};

export const getGrowthXirr = async (productType, params) => {
  try {
    let res;
    switch (productType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        res = await Api.get('/api/reports/xirr', {
          ...params,
          user_id: getUserIdForRequest(),
          platform,
        });
        break;
      // case 'pms':
      //   res = await Api.get(`/api/privatewealth/report/transactions/pms?pan=${PAN}`, params);
      //   break;
      // case 'aif':
      //   res = await Api.get(`/api/privatewealth/report/transactions/aif?pan=${PAN}`, params);
      //   break;
      default:
        throw 'No productType prop provided';
    }

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const hitNextPage = async (next_page, params) => {
  try {
    const res = await Api.get(next_page, {
      ...params,
      user_id: getUserIdForRequest(),
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getPortfolioRisk = async (params = {}) => {
  try {
    const res = await Api.get('api/reports/portfolio-risk', {
      ...params,
      user_id: getUserIdForRequest(),
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getNewsletter = async (params = {}) => {
  try {
    const res = await Api.get('api/cms/article/category/104?sort=desc', {
      ...params,
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getHoldings = async (holdingsType, params = {}) => {
  const user_id = getUserIdForRequest();
  try {
    let res;
    switch (holdingsType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        res = await Api.get('api/privatewealth/holding/report/mf', {
          exclude_zero_units: true,
          user_id,
          ...params,
        });
        break;
      case PRODUCT_TYPE_KEY_MAP.pms:
        res = await Api.get(`/api/privatewealth/report/holdings/pms`, {
          user_id
        });
        break;
      case PRODUCT_TYPE_KEY_MAP.aif:
        res = await Api.get(`/api/privatewealth/report/holdings/aif`, {
          user_id
        });
        break;
      case PRODUCT_TYPE_KEY_MAP.bond:
        res = await Api.get(`/api/privatewealth/report/holdings/bond`, {
          user_id
        });
        break;
      case PRODUCT_TYPE_KEY_MAP.unlistedShares:
        res = await Api.get(`/api/privatewealth/report/holdings/unlisted-share`, {
          user_id
        });
        break;
      default:
        throw 'No productType prop provided';
    }

    const onSuccess = (result) => {
      return result.holdings || {};
    }

    return handleApiResponse(res, onSuccess);

  } catch (e) {
    throw e;
  }
};

export const getFundDetail = async (params = {}) => {
  try {
    const res = await Api.get('api/reports/fund-details', {
      ...params,
      user_id: getUserIdForRequest()
    });

    const onSuccess = (result) => {
      return result.fund_data || {};
    }

    return handleApiResponse(res, onSuccess);
  } catch (e) {
    throw e;
  }
};

export const getPortfolioAnalysis = async (params = {}) => {
  try {
    const res = await Api.post(
      buildUrlWithQueryParams(
        'api/reports/portfolio-analysis',
        { user_id: getUserIdForRequest() }
      ),
      {
      ...params,
      }
    );

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getTransactions = async (productType, params = {}) => {
  params.user_id = getUserIdForRequest();
  params = omitBy(params, val => !val);

  try {
    let res;
    switch (productType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        res = await Api.get('api/invest/transactionv4', params);
        break;
      case PRODUCT_TYPE_KEY_MAP.pms:
        res = await Api.get(`/api/privatewealth/report/transactions/pms`, params);
        break;
      case PRODUCT_TYPE_KEY_MAP.aif:
        res = await Api.get(`/api/privatewealth/report/transactions/aif`, params);
        break;
      default:
        throw 'No productType prop provided';
    }

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getPortfolioFundNames = async (productType) => {
  const user_id = getUserIdForRequest();

  try {
    let res;

    switch (productType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        res = await Api.get(`api/reports/portfolio-fund-names?user_id=${user_id}`);
        break;
      case PRODUCT_TYPE_KEY_MAP.pms:
        res = await Api.get(`/api/privatewealth/fund/details/pms?user_id=${user_id}`);
        break;
      case PRODUCT_TYPE_KEY_MAP.aif:
        res = await Api.get(`/api/privatewealth/fund/details/aif?user_id=${user_id}`);
        break;
      default:
        throw 'No productType prop provided';
    }

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getGainsElssYears = async () => {
  try {
    const res = await Api.get(`api/iam/myaccount?user_id=${getUserIdForRequest()}`);

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
};

export const getLinkedUsers = async (params) => {
  try {
    const res = await Api.get('/api/family/get-linked-children', params);

    const onSuccess = (result) => result.children_pan_list;

    return handleApiResponse(res, onSuccess);
  } catch (e) {
    throw e;
  }
}

export const getFamilyOverviewData = async () => {
  try {
    const res = await Api.post(
      buildUrlWithQueryParams(
        '/api/privatewealth/report/family-view',
        {
          user_id: getUserIdForRequest()
        }
      )
    );

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const getAllRelationshipRequests = async (params = {}) => {
  try {
    const res = await Api.get(`/api/family/relationship-requests/get`, {
      ...params,
      user_id: getUserIdForRequest()
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const delinkAccount = async (id) => {
  try {
    const res = await Api.post(`/api/family/relationship-request/delink`, {
      relationship_id: id
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const sendLinkingReminderToChild = async (id) => {
  try {
    const res = await Api.post(`api/family/relationship-request/reminder`, {
      relationship_id: id
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const acceptOrRejectParentRequest = async (id, accept) => {
  try {
    const res = await Api.post(`api/family/relationship-request/update`, {
      relationship_id: id,
      status: accept ? "linked" : "rejected"
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const searchUserByPan = async (pan) => {
  try {
    const res = await Api.get(`/api/family/search-user-by-pan`, {
      pan_number: pan
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const sendRelationshipRequest = async (params) => {
  try {
    const res = await Api.post(`/api/family/relationship-request/create`, params);

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const getUserKyc = async () => {
  try {
    const res = await Api.get(`/api/kyc/v2/mine`, {
      user_id: getUserIdForRequest()
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const getWMDetails = async () => {
  try {
    const res = await Api.get(
      buildUrlWithQueryParams(
        '/api/rm/leads/get/rmdetail_from_rmusermap',
        { user_id: getUserIdForRequest() }
      )
    );
    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const getWMClientList = async (pageNo = '') => {
  try {
    let url = 'api/rm/leads/get/clients?page_size=10';
    if(pageNo) {
      url += `&page_no=${pageNo}`;
    }
    const res = await Api.get(url);
    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}

export const getNomineeDetails = async () => {
  try {
    const res = await Api.get(`/api/kyc/nomination/detail`, {
      user_id: getUserIdForRequest()
    });

    return handleApiResponse(res);
  } catch (e) {
    throw e;
  }
}