import './AllocationGraphs.scss';
import React from 'react';
import IwdPieChart from '../../mini-components/IwdPieChart/IwdPieChart';
import isEmpty from 'lodash/isEmpty';
import IwdCard from '../../mini-components/IwdCard';
import IwdLegend from '../../mini-components/IwdLegend/IwdLegend';
import { useState } from 'react';
import { useEffect } from 'react';
import { GRAPH_COLOR_PALETTE } from '../../common/constants';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import { INVESTMENT_SUMMARY_LIST_HEADERS_MAP } from './constants';
import IwdTable from '../../mini-components/IwdTable/IwdTable';

const AllocationGraphs = ({
  productSummary,
  assetAllocation,
  isLoading
}) => {
  const [processedAssetAlloc, setProcessedAssetAlloc] = useState([]);

  useEffect(() => {
    const assetAlloc = processGraphData(assetAllocation);
    setProcessedAssetAlloc(assetAlloc);
  }, [assetAllocation]);

  return (
    <>
      <IwdCard
        className='iwd-d-allocation'
        headerText='Asset allocation'
        error={
          isEmpty(assetAllocation)
        }
        errorText='Something went wrong! Please retry after some time or contact your wealth manager'
        isLoading={isLoading}
      >
        <div id='iwd-da-asset-graph'>
          <div className='iwd-chart'>
            <IwdPieChart
              data={processedAssetAlloc}
            />
          </div>
          <IwdLegend
            data={processedAssetAlloc}
          />
        </div>
      </IwdCard>
      <IwdCard
        headerText='Investment summary'
        className='iwd-d-allocation'
        noData={isEmpty(productSummary)}
        noDataText='Oops! There is no data to show here currently.'
        isLoading={isLoading}
      >
        <IwdTableContainer className='iwd-da-table-container'>
          <IwdTableContainer.TableContent>
            <IwdTable
              headersMap={INVESTMENT_SUMMARY_LIST_HEADERS_MAP}
              data={productSummary}
              errorMsg=''
              className='iwd-da-table'
            />
          </IwdTableContainer.TableContent>
        </IwdTableContainer>
      </IwdCard>
    </>
  );
}

export default React.memo(AllocationGraphs);

function processGraphData(data) {
  return Object
    .entries(data)
    .sort(([, a] ,[, b]) => b - a)
    .map(([key, value], idx) => ({
      id: key,
      label: key,
      value: Number(value || 0),
      color: GRAPH_COLOR_PALETTE[idx]
    }))
}