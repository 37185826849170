// -------------- ASSET IMPORTS -----------------
import download_icon from 'assets/download_icon.svg';
import pdf_download_icon from 'assets/pdf_download_icon.svg';
import csv_download_icon from 'assets/csv_download_icon.svg';
import { IconButton } from '@material-ui/core';
// ----------------------------------------------
import "./IwdReportDownload.scss";
import React from 'react';

export default function IwdReportDownloadDesktop({
  className,
  showPdf,
  onPdfDownloadClick,
  showCsv,
  onCsvDownloadClick,
  ...props
}) {
  return (
    <div className={`iwd-report-download-desktop ${className}`} {...props}>
      <img
        alt='download'
        src={download_icon}
      />
      <div className='iwd-rd-title'>
        Download Report
      </div>
      {showPdf &&
        <IconButton
          classes={{ root: "iwd-rd-icon-button" }}
          onClick={onPdfDownloadClick}
        >
          <img src={pdf_download_icon} alt="download pdf" />
        </IconButton>
      }
      {showCsv &&
        <IconButton
          classes={{ root: "iwd-rd-icon-button" }}
          onClick={onCsvDownloadClick}
        >
          <img src={csv_download_icon} alt="download csv" />
        </IconButton>
      }
    </div>
  );
}