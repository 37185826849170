// ------------------ Assets -----------------------
import positive from 'assets/ic_positive.svg';
import negative from 'assets/ic_negative.svg';
// -------------------------------------------------
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { genericErrMsg, GraphDateRanges } from '../../common/constants';
import { formatGrowthData, returnsFormatter } from '../../common/commonFunctions';
import IwdGrowthGraph from '../../mini-components/IwdGrowthGraph';
import { isEmpty } from '../../../utils/validators';
import IwdCard from '../../mini-components/IwdCard';
import { getGrowthData, getGrowthXirr } from '../../common/ApiCalls';
import { getConfig } from 'utils/functions';
import DotDotLoader from '../../../common/ui/DotDotLoader';
import { CSSTransition } from 'react-transition-group';
import IwdErrorScreen from '../../mini-components/IwdErrorScreen';
import IwdTabs from '../../mini-components/IwdTabs/IwdTabs';
import { useMemo } from 'react';
import { useUserContext } from '../../common/UserContext';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';
import { colorConfig } from '../../theme/colorConfig';

const dateFormatMap = {
  '1 month': "d m",
  '3 months': "d m",
  '6 months': "d m y",
  '1 year': "d m y",
  '3 years': "d m y",
  '5 years': "d m y",
  'ytd': "d m y",
};
const isMobileView = getConfig().isMobileDevice;
const TABS_LIST = [PRODUCT_TYPE_KEY_MAP.mf, PRODUCT_TYPE_KEY_MAP.pms];

const PortfolioGrowth = () => {
  const [growthData, setGrowthData] = useState({});
  const [isLoadingGrowth, setIsLoadingGrowth] = useState(true);
  const [growthError, setGrowthError] = useState(false);
  const [xirr, setXirr] = useState(null);
  const [isLoadingXirr, setIsLoadingXirr] = useState(true);
  const [selectedRange, setSelectedRange] = useState('');
  const [productType, setProductType] = useState(TABS_LIST[0]);
  const { userDetails } = useUserContext();

  useEffect(() => {
    // TODO: Check below value when switching to child account
    const firstInvestment = userDetails.dt_first_investment;
    const isFirstInvestmentRecent = () => moment().year() - moment(firstInvestment).year() <= 1;
    
    if (
      !firstInvestment ||
      isEmpty(firstInvestment) ||
      isFirstInvestmentRecent()
    ) {
      setSelectedRange('1 month');
    } else {
      setSelectedRange('1 year');
    }
  }, []);

  useEffect(() => {
    if (productType !== PRODUCT_TYPE_KEY_MAP.pms && selectedRange) {
      fetchGrowthGraphXirr();
    }
    fetchGrowthGraph();
  }, [selectedRange, productType]);

  const fetchGrowthGraphXirr = async () => {
    try {
      setIsLoadingXirr(true);
      const res = await getGrowthXirr(productType, {
        date_range: selectedRange,
      });

      setXirr(res.xirr);
    } catch (e) {
      console.error(e);
      setXirr(null);
    } finally {
      setIsLoadingXirr(false);
    }
  };

  const fetchGrowthGraph = async () => {
    if (!selectedRange) return;
    try {
      setGrowthError(false);
      setIsLoadingGrowth(true);
      if (productType === PRODUCT_TYPE_KEY_MAP.pms) {
        setIsLoadingXirr(true);  
      }
      const {
        current: current_amount_data,
        invested: invested_amount_data,
        date_ticks,
        date_ticks_mobile,
        annualised_return
      } = await getGrowthData(
        productType,
        { date_range: selectedRange }
      );

      if (
        isEmpty(current_amount_data) ||
        isEmpty(invested_amount_data) ||
        isEmpty(date_ticks)
      ) {
        throw new Error(genericErrMsg);
      }

      setGrowthData({
        ...formatGrowthData(current_amount_data, invested_amount_data),
        date_ticks: filterDateTicks(date_ticks, date_ticks_mobile),
      });

      if (productType === PRODUCT_TYPE_KEY_MAP.pms) {
        setXirr(annualised_return);
        setIsLoadingXirr(false);
      }
    } catch (e) {
      setGrowthError(true);
      setXirr(null);
      console.error(e);
      // toast(e);
    } finally {
      setIsLoadingGrowth(false);
      setIsLoadingXirr(false);
    }
  };

  const filterDateTicks = (ticks = [], mobileTicks = []) => {
    if (['5 years', '3 years'].includes(selectedRange) && !isMobileView) return ticks;
    if (isEmpty(mobileTicks)) {
      return [ticks[0], ticks[2], ticks[4], ticks[ticks.length - 2]];
    }
    return mobileTicks;
  };

  const GraphRangePicker = () => {
    let dateRanges = [...GraphDateRanges].reverse();
    if (moment().month() === 0) {
      dateRanges = dateRanges.slice(1);
    }
    return (
      <div
        id="iwd-range-picker"
        style={{ cursor: isLoadingGrowth ? "not-allowed" : "pointer" }}>
        {dateRanges.map((rangeObj, idx) => (
          <span
            key={idx}
            onClick={() => isLoadingGrowth ? '' : setSelectedRange(rangeObj.value)}
            className={`
              ${selectedRange === rangeObj.value ? 'selected' : ''}
              iwd-rp-item
            `}>
            {rangeObj.label}
          </span>
        ))}
      </div>
    );
  }

  const handleTabClick = (tab) => {
    setProductType(tab);
  };

  const RenderReturns = useMemo(() => {
    const formattedXirr = returnsFormatter(xirr);

    return (
      <>
        {formattedXirr ?
          <img
            src={xirr > 0 ? positive : negative}
            alt=''
          /> :
          ''
        }
        {formattedXirr || '--'}
      </>
    )
  }, [xirr]);


  return (
    <IwdCard id="iwd-d-growth-graph">
      <div id="iwd-dgg-header">
        <IwdCard.Header>Portfolio growth</IwdCard.Header>
        <IwdTabs
          classes={{ root: 'iwd-dggh-tabs' }}
          tabsList={TABS_LIST}
          currentTab={productType}
          onTabClick={handleTabClick}
        />
      </div>
      <div id="iwd-dgg-filter">
        <GraphRangePicker />
        <div id="iwd-dggf-return" style={{ color: isLoadingXirr ? '#d3dbe4' : colorConfig.content.tertiary }}>
          <CSSTransition
            in={isLoadingXirr}
            classNames="iwd-fade-animate"
            timeout={500}
          >
            <span id="value">
              {isLoadingXirr ?
                <DotDotLoader className="iwd-dot-loader" /> :
                RenderReturns
              }
            </span>
          </CSSTransition>
          <span id="label">{productType === PRODUCT_TYPE_KEY_MAP.pms ? 'Annualised return' : 'XIRR'}</span>
        </div>
      </div>
      {growthError ?
        <IwdErrorScreen
          hasError={true}
          templateErrText='Uh oh! Not enough data to show for this selected time period. Please try changing the time selection or retry later'
        /> :
        <div className="iwd-dgg-graph-container">
          <IwdGrowthGraph
            isLoading={isLoadingGrowth}
            data={growthData.data}
            width="auto"
            height="100%"
            params={{
              date_ticks: growthData.date_ticks,
              min: growthData.min,
              max: growthData.max,
              dateFormat: dateFormatMap[selectedRange],
            }}
          />
        </div>
      }
    </IwdCard>
  );
};

export default PortfolioGrowth;

