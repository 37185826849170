import React, { useMemo, useState } from 'react';
import PageHeader from '../../mini-components/PageHeader';
import { getConfig } from 'utils/functions';
import HeaderNavBar from '../../common/HeaderNavBar';
import CapitalGainTax from './CapitalGainTax';
import Elss from './Elss';
import Transactions from './Transactions';
import PortfolioSummary from './PortfolioSummary';
import { useUserContext } from '../../common/UserContext';

const PAGE_TYPE_MAPPER = {
  'portfolio summary': {
    key: "portfolio summary",
    component: <PortfolioSummary />,
    isVisibleInChildView: true,
  },
  'transactions': {
    key: "transactions",
    component: <Transactions />,
    isVisibleInChildView: true,
  },
  'capital gain tax': {
    key: "capital gain tax",
    component: <CapitalGainTax />,
    isVisibleInChildView: false,
  },
  'elss report': {
    key: "elss report",
    component: <Elss />,
    isVisibleInChildView: false,
  },
};
const isMobileView = getConfig().isMobileDevice;

const Statements = () => {
  const [pageType, setPageType] = useState('portfolio summary');
  const { isWmLogin, isChildUserSelected } = useUserContext();
  const tabsToShow = useMemo(() => {
    if (isChildUserSelected) {
      return Object.keys(PAGE_TYPE_MAPPER).filter((key) => PAGE_TYPE_MAPPER[key].isVisibleInChildView);
    }
    
    const tabsList = Object.keys(PAGE_TYPE_MAPPER).map((key) => key);
    return tabsList; 
  }, [isChildUserSelected])

  const handlePageType = (name) => {
    setPageType(name);
  };

  return (
    <div className='iwd-page iwd-statements'>
      <PageHeader height='9vh' hideProfile={isMobileView} hideLogout={isWmLogin}>
        <HeaderNavBar
          title='Statements'
          tabs={tabsToShow}
          handlePageType={handlePageType}
          currentTab={pageType}
        />
      </PageHeader>
      <section className='iwd-statements-container'>{PAGE_TYPE_MAPPER[pageType].component}</section>
    </div>
  );
};

export default Statements;
