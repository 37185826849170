// -------------- Assets ---------------
import fisdom_hni_logo from 'assets/fisdom/fisdom_hni_logo_gold.svg';
import bgWaves from 'assets/bg_waves_fund.svg';
import HelpPage from '../../mini-components/Help';
// -------------------------------------
import React, { memo, useState } from 'react';
import LoginFields from './LoginFields';
import { navigate as navigateFunc } from '../../common/commonFunctions';
import { PrimaryButton } from '../../common/Button';
import IwdPhoneInput from '../../common/IwdPhoneInput';
import IwdOtpInput from '../../common/IwdOtpInput';
import { Button } from 'material-ui';
import { CSSTransition } from 'react-transition-group';
import { nativeCallback } from 'utils/native_callback';
import ForgotPinDialog from '../../mini-components/IwdForgotPinDialog/ForgotPinDialog';
import { useUserContext } from '../../common/UserContext';
import { pick } from 'lodash';

const Login = (props) => {
  const [openHelpPage, toggleHelpPage] = useState(false);
  const [openForgotPwd, toggleForgotPwd] = useState(false);
  const navigate = navigateFunc.bind(props);

  const {
    updateWmDetails,
  } = useUserContext();

  const onLoginSuccess = async (loginWith, user) => {
    const isWealthManager = user.email?.includes('@fisdom.com') || false;

    sendEvents('login', {
      status: 'success',
      user_id: user.user_id,
      login_with: loginWith,
      login_by: isWealthManager ? 'RM' : 'user'
    });

    if (loginWith === 'email' && isWealthManager) {
      updateWmDetails(pick(user, ["email", "pan", "name", "mobile", "user_id"]));
      navigate("wm-dashboard");
    } else {
      navigate("main/dashboard");
    }
  };

  const onLoginFail = async (loginWith) => {
    sendEvents('login', {
      status: 'failed',
      user_id: '',
      login_with: loginWith
    });
  };

  const onMoreDetailsClicked = () => {
    toggleHelpPage(true);
    sendEvents('open help');
  }

  const sendEvents = (user_action, props) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        "user_action": user_action,
        screen_name: 'login',
        ...props,
      }
    };
    nativeCallback({ events: eventObj });
  };

  const handleInvalidRoute = () => {
    navigate('not-found');
  }

  return (
    <>
      {/* {openForgotPwd && <ForgotPasswordPage onClose={() => toggleForgotPwd(false)} />} */}
      <CSSTransition
        in={openForgotPwd}
        unmountOnExit
        classNames="circularExpand"
        timeout={1000}
      >
        <ForgotPinDialog onClose={() => toggleForgotPwd(false)} />
      </CSSTransition>
      <CSSTransition
        in={openHelpPage}
        unmountOnExit
        classNames="circularExpand"
        timeout={1000}
      >
        <HelpPage className={`circularExpand`} onClose={() => toggleHelpPage(false)}/>
      </CSSTransition>
      <div
        id="iwd-login"
        className={(openHelpPage || openForgotPwd) ? 'iwd-bg-fixed' : ''}
      >
        <div id="iwd-login-left">
          <img src={fisdom_hni_logo} alt="fisdom" height="58" />
          <div id="iwd-ll-hero-text">
            Welcome to your <br /><span>investment dashboard</span>
          </div>
          <div id="iwd-ll-divider"></div>
          <div id="iwd-ll-subtext">
            Keep an eye on your money and help it grow
          </div>
          <Button
            variant="raised"
            classes={{
              root: 'iwd-help-btn iwd-ll-help',
              label: 'iwd-help-btn-label',
            }}
            onClick={onMoreDetailsClicked}
          >
            Help
          </Button>
        </div>
        <div id="iwd-login-right">
          <div id="iwd-ll-login-container">
            <LoginFields
              phoneComponent={<IwdPhoneInput containerClass="iwd-fade" dropdownClass="iwd-phone-input-dropdown" />}
              otpComponent={<IwdOtpInput />}
              buttonComponent={<PrimaryButton classes={{ raised: 'iwd-fade' }} />}
              navigateFunction={navigate}
              emailFieldClasses={{
                root: 'iwd-text-field',
                input: 'iwd-text-field-input',
              }}
              onLoginSuccess={onLoginSuccess}
              onLoginFail={onLoginFail}
              onForgotPasswordClicked={() => toggleForgotPwd(true)}
              parentProps={props}
              handleInvalidRoute={handleInvalidRoute}
            />
          </div>
          <Button
            variant="raised"
            classes={{
              root: 'iwd-help-btn iwd-lr-help',
              label: 'iwd-help-btn-label',
            }}
            onClick={() => toggleHelpPage(true)}
          >
            Help
          </Button>
          <img src={bgWaves} alt="waves_bg" />
        </div>
      </div>
    </>
  );
}

export default memo(Login);