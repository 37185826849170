import './MemberInvestmentDetails.scss';
import React, { useEffect, useState } from 'react';
import IwdCard from '../../mini-components/IwdCard';
import IwdViewTypeSwitch from '../../mini-components/IwdViewTypeSwitch/IwdViewTypeSwitch';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import { MEMBERS_INVESTMENT_DETAILS_HEADERS_MAP } from './constants';
import IwdReturns from '../../mini-components/IwdReturns/IwdReturns';
import { formatNumVal } from '../../common/commonFunctions';
import { chunk } from 'lodash';
import { getConfig } from 'utils/functions';
import IwdTable from '../../mini-components/IwdTable/IwdTable';
import IwdCarousel, { IwdCarouselCardViewContainer } from '../../mini-components/IwdCarousel/IwdCarousel';
import { nativeCallback } from '../../../utils/native_callback';

const isMobileView = getConfig().isMobileDevice;

export default function MembersInvestmentDetails({
  data = [],
}) {
  const [showCardView, setShowCardView] = useState(true);

  const sendEvents = (user_action) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        "user_action": user_action,
        screen_name: 'family dashboard'
      }
    };
    nativeCallback({ events: eventObj });
  };

  useEffect(() => {
    sendEvents(showCardView ? 'card' : 'list');
  }, [showCardView]);

  const onViewTypeChange = (event) => {
    setShowCardView(event.target.checked);
  }

  return (
    <div id="iwd-fd-member-investments" className={showCardView ? 'iwd-page-with-card' : 'iwd-page-with-table'}>
      <div className="iwd-mi-page-controls iwd-fade">
        <IwdViewTypeSwitch
          value={showCardView}
          onChange={onViewTypeChange}
          disabled={!data?.length}
        />
      </div>
      {showCardView ?
        <CardView
          data={data}          
        /> :
        <ListView
          data={data}
        />
      }
    </div>
  );
};

const CardView = React.memo(({
  data = []
}) => {
  const chunkedUsers = chunk(data, 2);

  const ContainerComponent = isMobileView ? React.Fragment : IwdCarousel;

  return (
    <ContainerComponent>
      {chunkedUsers.map(([member1, member2], idx) => (
        <IwdCarouselCardViewContainer style={{ marginTop: '40px' }}>
          {member1 && <MemberCard data={member1} idx={(idx * 2 + 1)} />}
          {member2 && <MemberCard data={member2} idx={(idx * 2 + 2)} />}
        </IwdCarouselCardViewContainer>
      ))}
    </ContainerComponent>
  );
});

const MemberCard = ({ data = {}, idx }) => (
  <IwdCard
    headerText={`Member ${idx}`}
    className="iwd-mi-card"
  >
    {data.isSelf &&
      <div className='iwd-mic-self-tag'>Self</div>
    }
    <div className="iwd-mic-row1">
      <div className="iwd-micr-box">
        <div className='iwd-micrb-label'>Name</div>
        <div className="iwd-micrb-value">{data.name}</div>
      </div>
      <div className="iwd-micr-box">
        <div className='iwd-micrb-label'>PAN</div>
        <div className="iwd-micrb-value">{data.pan}</div>
      </div>
    </div>
    <div className='iwd-mic-row-divider'></div>
    <div className='iwd-mic-row2'>
      <div className={`iwd-micr-box`}>
        <div className='iwd-micrb-value'>{formatNumVal(data.current_value)}</div>
        <div className='iwd-micrb-label'>Current value</div>
      </div>
      <div className='iwd-micr-divider'></div>
      <div className={`iwd-micr-box`}>
        <div className='iwd-micrb-value'>{formatNumVal(data.invested_value)}</div>
        <div className='iwd-micrb-label'>Invested value</div>
      </div>
      <div className='iwd-micr-divider'></div>
      <div className={`iwd-micr-box`}>
        <IwdReturns
          value={data.annualized_return}
          label="Annualised return"
          classes={{
            value: 'iwd-micrb-value',
            label: 'iwd-micrb-label',
            img: 'iwd-micrb-img'
          }}
        />
      </div>
    </div>
  </IwdCard>
);

const ListView = ({
  data
}) => {
  return (
    <IwdTableContainer>
      <IwdTableContainer.Header title="Investment details of individual members" />
      <IwdTableContainer.TableContent>
        <IwdTable
          serializeData
          headersMap={MEMBERS_INVESTMENT_DETAILS_HEADERS_MAP}
          data={data}
        />
      </IwdTableContainer.TableContent>
    </IwdTableContainer>
  );
}