import SBI_icon2 from '../assets/banks/SBI/2.png';
import SBI_icon3 from '../assets/banks/SBI/3.png';
import SBI_icon4 from '../assets/banks/SBI/4.png';
import SBI_icon5 from '../assets/banks/SBI/5.png';
import SBI_icon6 from '../assets/banks/SBI/6.png';

import ICI_icon2 from '../assets/banks/ICI/2.png';
import ICI_icon3 from '../assets/banks/ICI/3.png';
import ICI_icon4 from '../assets/banks/ICI/4.png';
import ICI_icon5 from '../assets/banks/ICI/5.png';

import HDF_icon2 from '../assets/banks/HDF/2.png';
import HDF_icon3 from '../assets/banks/HDF/3.png';
import HDF_icon4 from '../assets/banks/HDF/4.png';
import HDF_icon5 from '../assets/banks/HDF/5.png';

import UTI_icon2 from '../assets/banks/UTI/2.png';
import UTI_icon3 from '../assets/banks/UTI/3.png';
import UTI_icon4 from '../assets/banks/UTI/4.png';
import UTI_icon5 from '../assets/banks/UTI/5.png';

import KTB_icon1 from '../assets/banks/KTB/1.png';
import KTB_icon2 from '../assets/banks/KTB/2.png';
import KTB_icon3 from '../assets/banks/KTB/3.png';
import KTB_icon4 from '../assets/banks/KTB/4.png';
import KTB_icon5 from '../assets/banks/KTB/5.png';
import KTB_icon6 from '../assets/banks/KTB/6.png';

export const banks_details = {
    'HDF': {
      'name': 'HDFC',
      'head_title': 'How To Add iSIP Biller In HDFC Bank',
      'head_small_title': 'Steps to activate your SIP through HDFC Netbanking:',
      'footer_title': 'Follow these above steps and add your iSIP Biller successfully.',
      'netbanking_link': 'https://netbanking.hdfcbank.com/netbanking/',
      'steps': [
        {
          'title': 'Step 1: Go to HDFC net banking page and login with your credentials',
          'image': ''
        },
        {
          'title': 'Step 2: Click on the Bill Pay & Recharge and then Continue',
          'image': HDF_icon2
        },
        {
          'title': 'Step 3: Click on “Click here to add” under “Register New Biller”',
          'image': HDF_icon3
        },
        {
          'title': 'Step 4: Select Mutual Fund, then BSE Limited',
          'image': HDF_icon4
        },
        {
          'title': 'Step 5: Enter/Paste the URN and select the options as shown below',
          'image': HDF_icon5
        }
      ]
    },
    'ICI': {
      'name': 'ICICI',
      'head_title': 'How To Add iSIP Biller In ICICI',
      'head_small_title': 'Steps to activate your SIP through ICICI Netbanking:',
      'footer_title': 'Follow these above steps and add your iSIP Biller successfully.',
      'netbanking_link': 'https://www.icicibank.com/safe-online-banking/safe-online-banking.page?',
      'steps': [
        {
          'title': 'Step 1: Go to ICICI netbanking page and login with your credentials',
          'image': ''
        },
        {
          'title': 'Step 2: Click on the Bill payments under “Payments & Transfer”',
          'image': ICI_icon2
        },
        {
          'title': 'Step 3: Click on Register in “Electricity, Telecom and Other Utility Bills”',
          'image': ICI_icon3
        },
        {
          'title': 'Step 4: Select Mutual Fund, then BSE ISIP and click on Register',
          'image': ICI_icon4
        },
        {
          'title': 'Step 5: Enter/Paste the URN and select the options as shown below',
          'image': ICI_icon5
        }
      ]
    },
    'SBI': {
      'name': 'SBI',
      'head_title': 'How To Add iSIP Biller In SBI',
      'head_small_title': 'Steps to activate your SIP through SBI Netbanking:',
      'footer_title': 'Follow these above steps and add your iSIP Biller successfully.',
      'netbanking_link': 'https://retail.onlinesbi.com/retail/login.htm',
      'steps': [
        {
          'title': 'Step 1: Go to SBI netbanking page and login with your credentials',
          'image': ''
        },
        {
          'title': 'Step 2: Click on the Bill payments Tab',
          'image': SBI_icon2
        },
        {
          'title': 'Step 3: Click on Manage Biller',
          'image': SBI_icon3
        },
        {
          'title': 'Step 4: Click On “Add” under “Manage Biller”',
          'image': SBI_icon4
        },
        {
          'title': 'Step 5: Select Biller as BSE Limited',
          'image': SBI_icon5
        },
        {
          'title': 'Step 5: Enter/Paste the URN and select the options as shown below',
          'image': SBI_icon6
        }
      ]
    },
    'UTI': {
      'name': 'Axis Bank',
      'head_title': 'How To Add iSIP Biller In Axis Bank',
      'head_small_title': 'Steps to activate your SIP through Axis Bank Netbanking:',
      'footer_title': 'Follow these above steps and add your iSIP Biller successfully.',
      'netbanking_link': 'https://www.axisbank.com/bank-smart/internet-banking/getting-started',
      'steps': [
        {
          'title': 'Step 1: Go to Axis bank netbanking page and login with your credentials',
          'image': ''
        },
        {
          'title': 'Step 2: Click on the Pay Bills under “Payments”',
          'image': UTI_icon2
        },
        {
          'title': 'Step 3: Click on Add iSIP Biller',
          'image': UTI_icon3
        },
        {
          'title': 'Step 4: Select Mutual Fund, then BSE Limited',
          'image': UTI_icon4
        },
        {
          'title': 'Step 5: Enter/Paste the URN and select the options as shown below',
          'image': UTI_icon5
        }
      ]
    },
    'KBL': {
      'name': 'Karnataka Bank',
      'head_title': 'How To Add iSIP Biller In Karnataka Bank',
      'head_small_title': 'Process of adding a Biller in KBL Mobile Plus',
      'footer_title': 'Follow these above steps and add your iSIP Biller successfully.',
      'netbanking_link': 'https://karnatakabank.com/personal/mobile-banking/mobile-plus-app',
      'steps': [
        {
          'title': 'Step 1: Tap ‘More’ on the home screen.',
          'image': KTB_icon1
        },
        {
          'title': 'Step 2: Tap on ‘Manage Biller’',
          'image': KTB_icon2
        },
        {
          'title': 'Step 3: Select ‘Mutual Fund’ as a category',
          'image': KTB_icon3
        },
        {
          'title': 'Step 4: Add ‘Biller’',
          'image': KTB_icon4
        },
        {
          'title': 'Step 5: Select ‘Bse limited’ as Biller',
          'image': KTB_icon5
        },
        {
          'title': 'Step 6: Add Unique Registration Number (URN) and click on Proceed.',
          'image': KTB_icon6
        }
      ]
    }
  };