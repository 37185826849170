import './FamilyDashboard.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { getConfig } from '../../../utils/functions';
import PageHeader from '../../mini-components/PageHeader';
import SnapScrollContainer from '../../mini-components/SnapScrollContainer';
import toast from '../../../common/ui/Toast';
import { isEmpty, capitalize } from 'lodash';
import { getFamilyOverviewData } from '../../common/ApiCalls';
import { genericErrMsg } from '../../common/constants';
import OverviewCard from './OverviewCard';
import FamilyAllocationGraphs from './FamilyAllocationGraphs';
import MembersInvestmentDetails from './MembersInvestmentDetails';
import { useUserContext } from '../../common/UserContext';
import ScrollTopBtn from '../../mini-components/ScrollTopBtn';
import IwdDisclaimerNote from '../../mini-components/IwdDisclaimerNote/IwdDisclaimerNote';
import { userNameFormatter } from '../../common/commonFunctions';
import { colorConfig } from '../../theme/colorConfig';

const isMobileView = getConfig().isMobileDevice;

export default function FamilyDashboard() {
  const { userDetails, isWmLogin } = useUserContext();
  const username = useMemo(() => capitalize(userDetails.name));
  const [overviewData, setOverviewData] = useState({});
  const [memberContributions, setMemberContributions] = useState([]);
  const [productAlloc, setProductAlloc] = useState({});
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);
  const [overviewError, setOverviewError] = useState(false);

  const hasAllEmptyProps = (obj) => {
    return isEmpty(Object.values(obj));
  };
  
  const getIndividualContributions = (contributions) => {
    let newData = Object.entries(contributions).map(([key, value]) => ({
      pan: key,
      contribution: value.contribution,
      name: value.name,
      isSelf: key === userDetails.pan,
      ...value.data
    }));
    // ----- Code to move currently logged in user to the top of the list ----
    const indexOfSelf = newData.findIndex(data => data.isSelf);
    newData.unshift(newData.splice(indexOfSelf, 1)[0]); // https://stackoverflow.com/a/48456512/8428072
    // -----------------------------------------------------------------------
    return newData;
  }

  const fetchFamilyOverview = async () => {
    try {
      setIsLoadingOverview(true);
      setOverviewError(false);
      const data = await getFamilyOverviewData();
      const overviewObj = data.aggregated_values;
      if (hasAllEmptyProps(overviewObj)) {
        throw (genericErrMsg);
      }
      setOverviewData(overviewObj);
      const individualContributions = getIndividualContributions(data.individual_data_and_contribution);
      setMemberContributions(individualContributions);
      setProductAlloc(data.product_allocation);
    } catch (e) {
      setOverviewError(true);
      console.error(e);
      toast(e);
    } finally {
      setIsLoadingOverview(false);
    }
  };
  
  useEffect(() => {
    fetchFamilyOverview();
  }, []);
  
  const pageChanged = (pageNum) => {
    const page = document.getElementById('iwd-family-dashboard');
    const header = document.getElementById('iwd-ph-left');

    if (page && !isEmpty(page)) {
      if (pageNum !== 1) {
        page.style.background = colorConfig.supporting.white;
        if (header) header.classList.add('iwd-family-dashboard-pg1');
      } else {
        page.style.background = '';
        if (header) header.classList.remove('iwd-family-dashboard-pg1');
      }
    }
  };

  return (
    <div className='iwd-page' id='iwd-family-dashboard'>
      <PageHeader hideLogout={isWmLogin}>
        <div className='iwd-header-title brand-font'>{userNameFormatter(username)}'s Family Dashboard </div>
      </PageHeader>
      <SnapScrollContainer
        pages={3}
        hideFooter={isMobileView}
        onPageChange={pageChanged}
        isLoading={isLoadingOverview}
        loadingText='Please wait...getting your investment data'
        error={overviewError}
        onErrorBtnClick={fetchFamilyOverview}
      >
        <SnapScrollContainer.Page pageNumber={1}>
          <OverviewCard
            headerText="Investment overview of your family"
            overviewData={overviewData}
            returnsLabel="Absolute return"
            error={isEmpty(overviewData) || overviewError}
            errorText='Something went wrong! Please retry after some time or contact your wealth manager'
            isLoading={isLoadingOverview}
            style={{
              background: isLoadingOverview || isEmpty(overviewData) ? 'white' : '',
            }}
          />
          <IwdDisclaimerNote
            className="iwd-animatedFade"
            showNote
            style={{ color: colorConfig.content.secondary }}
          >
            <div>
              This dashboard contains consolidated data of all linked accounts.
              <div style={{ marginTop: '10px' }}>Contact your wealth manager for the valuation report of bonds, unlisted shares &amp; insurance</div>
            </div>
          </IwdDisclaimerNote>
        </SnapScrollContainer.Page>
        <SnapScrollContainer.Page pageNumber={2}>
          <FamilyAllocationGraphs
            individualContributions={memberContributions}
            productAllocation={productAlloc}            
          />
        </SnapScrollContainer.Page>
        <SnapScrollContainer.Page pageNumber={3}>
          <MembersInvestmentDetails data={memberContributions} />
        </SnapScrollContainer.Page>
        {isMobileView && <ScrollTopBtn />}
      </SnapScrollContainer>
    </div>
  );
}