import './OverviewCard.scss';
import React, { useMemo } from 'react';
import { getConfig } from '../../../utils/functions';
import { formatNumVal } from '../../common/commonFunctions';
import IwdCard from '../../mini-components/IwdCard';
import IwdReturns from '../../mini-components/IwdReturns/IwdReturns';
import IwdInfoTooltip from '../../mini-components/IwdInfoTooltip/IwdInfoTooltip';

const isMobileView = getConfig().isMobileDevice;

export default function OverviewCard(props) {
  const {
    overviewData,
    returnsLabel, //TODO: improve this implementation
    showInfoIcon,
    ...cardProps
  } = props;

  const renderReturnsLabel = useMemo(() => {
    return (
      <>
        {returnsLabel || "Absolute annualised return"}
        {
          showInfoIcon && <IwdInfoTooltip
            title="Absolute Annualised Return "
            content="This is calculated taking only Mutual Funds, PMS and AIF into consideration"
            style={{ height: '14px' }}
            iconStyle={{
              position: "relative",
              bottom: "-1px",
            }}
          />
        }
      </>
    );
  }, [returnsLabel]);

  return (
    <IwdCard id='iwd-dashboard-overview' {...cardProps}>
      <div id='iwd-do-data'>
        <div className={`iwd-do-box ${!isMobileView ? 'border-bottom border-right' : ''}`}>
          <div className='iwd-dob-value'>{formatNumVal(overviewData.total_current_value)}</div>
          <div className='iwd-dob-label'>Current value</div>
        </div>
        <div className={`iwd-do-box ${!isMobileView ? 'border-bottom border-right' : ''}`}>
          <div className='iwd-dob-value'>{formatNumVal(overviewData.total_invested_value)}</div>
          <div className='iwd-dob-label'>Invested value</div>
        </div>
        <div className={`iwd-do-box ${!isMobileView ? 'border-bottom' : ''}`}>
          <div className="iwd-tt-container">
            
            <IwdReturns
              value={overviewData.annualized_return}
              label={renderReturnsLabel}
              classes={{
                value: 'iwd-dob-value',
                label: 'iwd-dob-label iwd-dob-label-returns',
                img: 'iwd-dob-img'
              }}
              />
            </div>
        </div>
        <div className={`iwd-do-box ${!isMobileView ? 'border-right' : ''}`}>
          <div className='iwd-dob-value'>{formatNumVal(overviewData.total_realized_gain)}</div>
          <div className='iwd-dob-label'>Total Realised Gains</div>
        </div>
        <div className={`iwd-do-box ${!isMobileView ? 'border-right' : ''}`}>
          <div className='iwd-dob-value'>{formatNumVal(overviewData.total_unrealized_gain)}</div>
          <div className='iwd-dob-label'>Total Unrealised Gains</div>
        </div>
        <div className='iwd-do-box'>
          <div className='iwd-dob-value'>{formatNumVal(overviewData.total_dividend)}</div>
          <div className='iwd-dob-label'>Dividend/Interest</div>
        </div>
      </div>
    </IwdCard>
  );
}