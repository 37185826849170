// ----------------------- Assets -----------------------
import IlsError from 'assets/fisdom/ils_error.svg';
import IlsNoData from 'assets/fisdom/ils_no_data.svg';
// ------------------------------------------------------
import React from 'react';
import ErrorScreen from '../../common/responsive-components/ErrorScreen';

const IwdErrorScreen = ({
  hasError = false, // flag to show 'error' image
  hasNoData = false, // flag to show 'no data' image
  ...props
}) => {
  const renderImage = () => {
    if (hasError) {
      return IlsError;
    } else if (hasNoData) {
      return IlsNoData;
    }
    return props.templateImage;
  };

  return (
    <ErrorScreen
      useTemplate
      templateImage={renderImage()}
      classes={{
        container: `iwd-fade iwd-error-screen ${(props.classes || {}).container}`,
        button: 'iwd-es-button'
      }}
      {...props}
    />
  );
};

export default IwdErrorScreen;