import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { formattedDate, formatAmountInr, numDifferentiationInr } from '../../utils/validators';
import { getConfig } from 'utils/functions';
import IwdCardLoader from './IwdCardLoader';
import { DEFAULT_FONT_FAMILY } from '../common/constants';
import { colorConfig } from '../theme/colorConfig';
import { getHexColorWithOpacity } from '../common/commonFunctions';

const isMobileView = getConfig().isMobileDevice;

const IwdGrowthGraph = ({
  params = {},
  data = [],
  width = 0,
  height = 0,
  isLoading = false
}) => {
  return (
    <div style={{ width, height, margin: `0 ${isMobileView ? '-15px 0 -10px' : '-20px 0 -30px'}` }}>
      {isLoading ?
        <IwdCardLoader />
        : 
        (<ResponsiveLine
          data = { data }
          animate = { true }
          margin = {{ top: 20, right: 40, bottom: 40, left: isMobileView ? 60 : 80 }}
          yScale={{
            type: 'linear',
            min: params.min || 'auto',
            max: params.max || 'auto',
            stacked: false,
            reverse: false
          }}
          axisBottom={{
            format: value => formattedDate(value, params.dateFormat, true),
            tickValues: params.date_ticks,
            tickPadding: 15,
            tickSize: 4,
          }}
          axisLeft={{
            orient: 'left',
            tickValues: 5,
            format: value => value === 0 ? 0 : numDifferentiationInr(value, 2, false, true), //converts 40000 to 40K
            tickPadding: 10,
            tickSize: 0,
          }}
          curve="linear"
          enableGridX={false}
          colors={[
            colorConfig.secondary.green,
            colorConfig.secondary.silverLake[200]
          ]}
          enablePoints={false}
          enableSlices="x"
          enableCrosshair={true}
          crosshairType="x"
          useMesh={true}
          theme={{
            axis: {
              ticks: {
                text: Object.assign({
                  fontFamily: DEFAULT_FONT_FAMILY,
                  fontWeight: '600',
                  letterSpacing: '1px',
                  textTransform: 'uppercase',
                }, !isMobileView ? {
                  fill: colorConfig.content.tertiary,
                  fontSize: '11px',
                  lineHeight: '18px',
                } : {
                    fontSize: '9px',
                    lineHeight: '14px',
                    fill: colorConfig.content.tertiary,
                })
                }
              },
            grid: {
              line: {
                stroke: getHexColorWithOpacity(colorConfig.primary.gold.brand, 0.05),
                strokeWidth: 0.5,
              }
            },
            crosshair: {
              line: {
                stroke: colorConfig.primary.gold.brand,
                strokeWidth: 0.9,
                strokeOpacity: 0.4,
              },
            }
          }}
          sliceTooltip={IwdLineTooltip}
          enableArea={true}
          areaBaselineValue={params.min || 0}
          defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
      />)}
    </div>
  );
};

export default IwdGrowthGraph;

const IwdLineTooltip = ({ slice }) => {
  const [date] = slice.points.map(point => point.data.x);
  return (
    <div className="iwd-growth-graph-legend">
      <div className="iwd-ggl-header">{formattedDate(date, 'd m, y')}</div>
      <div className="iwd-ggl-body">
        {slice.points.map((point, idx) => {
          const { data: {yFormatted: value}, serieId: label } = point;
          return (
            <div className="iwd-ggl-item" key={idx}>
              <div
                className="iwd-ggli-color-bubble"
                style={{ background: point.color }}
              >
              </div>
              <span
                className="iwd-ggli-label"
                style={{ color: point.color }}
              >
                {label.split('_')[0]}:
              </span>
              &nbsp;&nbsp;
              <span className="iwd-ggli-value">
                {value ? formatAmountInr(value) : '₹0'}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}