import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { UnSelectedRadio, SelectedRadio } from '../../common/RadioBox';
import isUndefined from 'lodash/isUndefined';
import { colorConfig } from '../../theme/colorConfig';

const STYLES = {
  root: {
    width: 'fit-content',
    padding: '0px 0px 5px 0px',
    margin: '0px',
    '&:last-child': {
      padding: '0px',
    },
  },
  label: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '21px',
    color: colorConfig.content.tertiary,
    marginLeft: '20px',
  },
};

const FilterRadioItem = ({
  title,
  value,
  options,
  classes,
  onChange,
  disabled
}) => {

  const handleChange = (event) => {
    const newVal = event.target.value;

    if (isUndefined(newVal)) return;

    if (newVal !== value) {
      onChange(newVal);
    }
  };

  return (
    <div className='iwd-filter-radio-item'>
      <FormControl component='fieldset'>
        {title &&
          <div className='iwd-filter-item-label'>{title}</div>
        }
        <RadioGroup
          aria-label={title}
          name={title}
          value={value}
          onClick={handleChange}
        >
          {options?.map((opt) => {
            return (
              <FormControlLabel
                classes={{
                  root: classes.root,
                  label: classes.label
                }}
                key={opt.value}
                value={opt.value}
                control={
                  <Radio icon={<UnSelectedRadio />} checkedIcon={<SelectedRadio />} />
                }
                label={opt.label}
                disabled={disabled || opt.disabled}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default withStyles(STYLES)(FilterRadioItem);
