// ---------- Image Imports ------------
import bg_waves_full from 'assets/bg_waves_full_screen.svg';
import bg_waves_small from 'assets/bg_waves_fund.svg';
// -------------------------------------
import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from 'material-ui';
import PageCloseBtn from '../../mini-components/PageCloseBtn';
import { formattedDate } from 'utils/validators.js';
import toast from '../../../common/ui/Toast';
import { getFundDetail, getTransactions, hitNextPage } from '../../common/ApiCalls';
import IwdScreenLoader from '../../mini-components/IwdScreenLoader';
import ScrollTopBtn from '../../mini-components/ScrollTopBtn';
import { getConfig } from 'utils/functions';
import RatingStar from '../../../fund_details/common/RatingStar';
import { HOLDING_DETAIL_INFO_MAP, HOLDING_TRANSACTION_HEADERS_MAP } from './constants';
import isFunction from 'lodash/isFunction';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import IwdTable from '../../mini-components/IwdTable/IwdTable';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';

const isMobileView = getConfig().isMobileDevice;

const HoldingDetail = ({
  investmentDetail = {},
  onCloseClick = () => {},
}) => {
  const [fundDetail, setFundDetail] = useState(investmentDetail);
  const [isLoadingFundDetail, setIsLoadingFundDetail] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [pageMap, setPageMap] = useState([null, null]);
  const [activePage, setActivePage] = useState(1);
  const productType = useMemo(() => investmentDetail.productType, [investmentDetail]);
  const {
    detailHeadersMap,
    transactionHeadersMap
  } = useMemo(() => ({
    detailHeadersMap: HOLDING_DETAIL_INFO_MAP[productType] || {},
    transactionHeadersMap: HOLDING_TRANSACTION_HEADERS_MAP[productType] || []
  }), [productType]);

  const pushToPageMap = (url) => {
    if (!url || !!pageMap[activePage + 1]) return;
    setPageMap([...pageMap, url]);
  };

  const onPageSelect = (page) => {
    setActivePage(page);
  };
  
  const fetchHoldingDetail = async () => {
    try {
      setIsLoadingFundDetail(true);
      
      let result;
      if (productType === PRODUCT_TYPE_KEY_MAP.mf) {
        ({ fund_attributes: result } = await getFundDetail({ isin: investmentDetail.isin }));
      }

      setFundDetail(
        Object.assign(
          {},
          fundDetail,
          result || {}
        )
      );
    } catch (e) {
      console.log(e);
      toast(e);
    }
    setIsLoadingFundDetail(false);
  };

  const fetchTransactions = async () => {
    try {
      setIsLoadingTransactions(true);
      let reqParams;

      if (productType === PRODUCT_TYPE_KEY_MAP.mf) {
        reqParams = {
          amfi: investmentDetail.isin,
        }
      } else {
        reqParams = {
          scheme_id: investmentDetail.scheme_id,
          client_id: investmentDetail.client_id
        }
      }

      let response;
      if (activePage === 1) {
        response = await getTransactions(
          productType,
          {
            ...reqParams,
            page_size: 10,
          }
        );
      } else {
        const urlToHit = pageMap[activePage];
        response = await hitNextPage(urlToHit);
      }

      pushToPageMap(response.next_page);
      setTransactions(response.transactions);
    } catch (e) {
      console.log(e);
      toast(e);
    }
    setIsLoadingTransactions(false);
  };

  useEffect(() => {
    fetchHoldingDetail();
  }, []);
  
  useEffect(() => {
    fetchTransactions();
  }, [activePage]);

  return (
    <Dialog fullScreen={true} open={true} classes={{ paper: 'iwd-holding-detail'}}>
      {isLoadingFundDetail ?
        <IwdScreenLoader
          loadingText='Please wait...getting your fund data'
        /> :
        <>
          <div className="iwd-hd-content">
            <PageCloseBtn onClick={onCloseClick} />
            <div className="iwd-hdc-header">
              <img src={fundDetail.logo} alt="" height="60" />
              <div>
                <div className="iwd-hdc-type">
                  {fundDetail.invested_since &&
                    <span className="iwd-hdct-invested">
                      Since {formattedDate(fundDetail.invested_since, 'm y')}
                    </span>
                  }
                  {fundDetail.scheme_type &&
                    <>
                      <span className="iwd-hdct-divider scheme-type">|</span>
                      <span className="iwd-hdct-scheme-type">
                        {fundDetail.scheme_type}
                      </span>
                    </>
                  }
                  {fundDetail.fisdom_rating &&
                    <>
                      <span className="iwd-hdct-divider rating">|</span>
                      <RatingStar className="iwd-hdct-rating" value={fundDetail.fisdom_rating} />
                    </>
                  }
                </div>
                <div className="iwd-hdch-fund-name">
                  {fundDetail.name}
                </div>
              </div>
            </div>
            <div className="iwd-hdc-info-row">
              {detailHeadersMap?.left &&
                <div className="iwd-hdc-numbers">
                  {detailHeadersMap.left?.map(({ title, accessor, formatter }, idx) => (
                    <>
                      {idx !== 0 &&
                        <div className="iwd-hdcn-item-divider"></div>
                      }
                      {
                        <div className="iwd-hdcn-item">
                          <div className="iwd-hdcni-value">
                            {isFunction(formatter) ?
                              formatter(accessor ? fundDetail[accessor] : fundDetail)
                              :
                              fundDetail[accessor]
                            }
                          </div>
                          {title && <div className="iwd-hdcni-label">{title}</div>}
                        </div>
                      }
                    </>
                  ))}
                </div>
              }
              {detailHeadersMap?.right &&
                <div className="iwd-hdc-stats">
                  {detailHeadersMap.right?.map(({ title, accessor, formatter }) => (
                    <div className="iwd-hdcs-item">
                      <div className="iwd-hdcsi-value">
                        {isFunction(formatter) ?
                          formatter(accessor ? fundDetail[accessor] : fundDetail)
                          :
                          fundDetail[accessor]
                        }
                      </div>
                      <div className="iwd-hdcsi-label">{title}</div>
                    </div>
                  ))}
                </div>
              }
            </div>
            <div className="iwd-hdc-transactions">
              <IwdTableContainer.HeaderTitle>Transactions</IwdTableContainer.HeaderTitle>
              <IwdTableContainer
                isLoading={isLoadingTransactions}
                loadingText="Fetching transactions..."
              >
                <IwdTableContainer.TableContent>
                  <IwdTable
                    serializeData
                    headersMap={transactionHeadersMap}
                    serialOffset={(activePage - 1) * 10}
                    data={transactions}
                  />
                </IwdTableContainer.TableContent>
                {(!isLoadingTransactions && transactions?.length > 0) &&
                  <IwdTableContainer.Pagination
                    pages={pageMap.length - 1}
                    activePage={activePage}
                    onSelect={onPageSelect}
                  />
                }
              </IwdTableContainer>
            </div>
            {isMobileView && <ScrollTopBtn containerIdentifier="iwd-holding-detail" />}
          </div>
          <picture>
            <source srcSet={`${bg_waves_small} 1x`} media="(max-width: 640px)" />
            <img
              className="iwd-hd-banner-img"
              srcSet={`${bg_waves_full} 2x`}
              alt="" />
          </picture>
        </>
      }
    </Dialog>
  );
};

export default HoldingDetail;