import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import UnsubscribePage from "./Unsubscribe";
import UnsubscribeStatus from "./UnsubscribeStatus";

const Unsubscribe = (props) => {
  const { url } = props.match;

  return (
    <Fragment>
      <Switch>
        <Route exact path={`${url}`} component={UnsubscribePage} />
        <Route path={`${url}/status`} component={UnsubscribeStatus} />
      </Switch>
    </Fragment>
  );
};

export default Unsubscribe;
