import vectorMail from '../../assets/ic_mail.svg';
import vectorPhone from '../../assets/ic_mobile.svg';

import { ClickAwayListener } from 'material-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { formatMobileNumber, navigate as navigateFunc, userNameFormatter } from '../common/commonFunctions';
import { logout } from '../common/ApiCalls';
import toast from '../../common/ui/Toast';
import { nativeCallback } from '../../utils/native_callback';
import { CSSTransition } from 'react-transition-group';
import IwdSwitchAccount from './IwdSwitchAccount/IwdSwitchAccount';
import { useUserContext } from '../common/UserContext';
import isEmpty from 'lodash/isEmpty';
import { OutlinedButton } from '../common/Button';

const IwdProfile = ({profileData, hideLogout, ...props}) => {
  const navigate = navigateFunc.bind(props);
  const [expanded, setExpanded] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState({});
  const { userDetails } = useUserContext();
  const userMobile = useMemo(() => formatMobileNumber(detailsToShow.mobile), [detailsToShow]);
  const { hasLinkedChildUsers, isChildUserSelected, selectedChildUser, clearContext } = useUserContext();

  useEffect(() => {
    if (!isEmpty(profileData)) {
      setDetailsToShow(profileData);
    } else if (isChildUserSelected) {
      setDetailsToShow(selectedChildUser);
    } else {
      setDetailsToShow(userDetails);
    }
  }, [profileData, userDetails, isChildUserSelected, selectedChildUser]);

  const sendEvents = (user_action, props) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: 'landing page',
        "user_action": user_action,
        ...props,
      }
    };
    nativeCallback({ events: eventObj });
  };

  const toggleExpanded = () => setExpanded(!expanded);

  const logoutUser = async () => {
    try {
      sendEvents('logout');
      setLoggingOut(true);
      await logout();
      clearContext();
      navigate('login');
    } catch (err) {
      console.log(err);
      toast(err);
    }
    setLoggingOut(false);
  };

  const profileIcon = (
    <div id='iwd-profile-icon' onClick={!expanded ? toggleExpanded : undefined}>
      {(detailsToShow.name || '').charAt(0)}
    </div>
  );
  
  return (
    <>
      <CSSTransition
        in={expanded}
        unmountOnExit
        timeout={800}
        classNames='boxExpand'
      >
        <ClickAwayListener onClickAway={toggleExpanded}>
          <div id='iwd-profile' className={isChildUserSelected ? 'touch-disabled' : ''}>
            <div className='iwd-profile-content'>
              <div className="iwd-pc-top">
              {/* {profileIcon} */}
                <div className="iwd-pct-expanded-profileicon">{(detailsToShow.name || '').charAt(0)}</div>
                <div className="iwd-pct-right-content">
                  <div className='iwd-profile-username'>{userNameFormatter(detailsToShow.name)}</div>
                  <div className='iwd-profile-detail' id='pan'>
                    <b>PAN: </b>
                    {detailsToShow.pan || '--'}
                  </div>
                </div>
              </div>
              <div className="iwd-pct-bottom">
                <div style={{display:"flex"}}>
                  <img src={vectorMail} alt="mail icon"/>
                  <div className='iwd-profile-detail-email'>
                    {detailsToShow.email}
                  </div>
                </div>
                <div style={{display:"flex"}}>
                  <img src={vectorPhone} alt="phone icon"/>
                  <div className='iwd-profile-detail-mobile'> 
                    {'  '}{userMobile}
                  </div>
                </div>
              </div>
              {!hideLogout && (
                <>
                  <OutlinedButton
                    fullWidth
                    uppercaseText
                    isLoading={loggingOut}
                    onClick={logoutUser}
                  >
                    Logout
                  </OutlinedButton>
                  <br></br>
                </>
              )}
              {hasLinkedChildUsers &&
                <>
                  <div id='iwd-pc-divider'></div>
                  <div id="iwd-pc-switch-acc-heading">Switch account</div>
                  <div id="iwd-pc-switch-acc-container">
                    <IwdSwitchAccount />                  
                  </div>
                </>
              }
            </div>
          </div>
        </ClickAwayListener>
      </CSSTransition>
      <div id='iwd-profile-short' className={isChildUserSelected ? 'touch-disabled' : ''}>
        <div
          style={{
            marginRight: '10px',
          }}
        >
          <div id='iwd-ps-name'>{userNameFormatter(detailsToShow.name) || '--'}</div>
          <div id='iwd-ps-contact'>
            {userMobile || detailsToShow.email || '--'}
          </div>
        </div>
        {profileIcon}
      </div>
    </>
  );
};

export default withRouter(IwdProfile);
