import xls_icon from 'assets/xls_icon.svg';
import pdf_icon from 'assets/pdf_icon.svg';
// ------------- ASSETS ---------------
import React from 'react';
import { nativeCallback } from '../../../utils/native_callback';
import { getConfig } from 'utils/functions';
import IwdCard from '../../mini-components/IwdCard';
import { getUserIdForRequest } from '../../common/ApiCalls';

const StatementCard = ({ sType, year, showPdf = true, showCsv }) => {
  const handleDownloadReportPdf = async () => {
    sendEvents("download report pdf");
    const requestUserId = getUserIdForRequest();

    let url;
    if (requestUserId) {
      url = `${getConfig().base_url}/printpage/invest/export/${requestUserId}/${sType}?year=${year}`
    } else {
      url = `${getConfig().base_url}/printpage/invest/export/mine/${sType}?year=${year}`;
    }
    window.open(url, '_blank');
  };

  const handleDownloadReportCsv = async () => {
    // sendEvents("download report csv");
    // window.open(`${getConfig().base_url}/printpage/invest/export/mine/${sType}?year=${year}`, '_blank');
  };

  const sendEvents = (user_action) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: 'statements',
        "user_action": user_action,
        sub_tab: sType === "capital_gains" ? "capital gain tax" : "elss report"
      }
    };
    nativeCallback({ events: eventObj });
  };

  return (
    <IwdCard
      className="iwd-statement-card"
    >
      <>
        <div className='iwd-statement-text-container'>
          <div className='iwd-statement-year-heading'>Financial year</div>
          <div className='iwd-statement-year-value'>{`${year}-${year + 1}`}</div>
        </div>
        <div className='iwd-statement-file-container'>
          {showPdf &&
            <img
              src={pdf_icon} 
              className='iwd-statement-icon'
              alt='pdf_icon'
              onClick={handleDownloadReportPdf}
            />
          }
          {showCsv &&
            <img
              src={xls_icon}
              className='iwd-statement-icon'
              alt='csv_icon'
              onClick={handleDownloadReportCsv}
            />
          }
        </div>
      </>
    </IwdCard>
  );
};

export default StatementCard;
