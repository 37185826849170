import './rsuite.scss';
import React, { useState, useEffect, useMemo } from 'react';
import IwdFilterDesktop from '../../mini-components/IwdFilter/IwdFilterDesktop';
import IwdFilterMobile from '../../mini-components/IwdFilter/IwdFilterMobile';
import {
  getTransactions,
  hitNextPage,
  getPortfolioFundNames,
  getUserIdForRequest,
} from '../../common/ApiCalls';
import isEmpty from 'lodash/isEmpty';
import AutoSuggestSearch from '../../mini-components/AutoSuggestSearch';
import IwdErrorScreen from '../../mini-components/IwdErrorScreen';
import toast from '../../../common/ui/Toast';
import { buildUrlWithQueryParams, getConfig } from '../../../utils/functions';
import { nativeCallback } from '../../../utils/native_callback';
import LeftPanel from '../../mini-components/LeftPanel';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import ProductTypeSelectorDesktop from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorDesktop';
import useProductType from '../../hooks/useProductType';
import { PRODUCT_FILTER_OPTIONS_MAP, PRODUCT_TYPE_PAGE_PROPS_MAP, TRANSACTIONS_TABLE_HEADERS_MAP, VIEW_FOR_FILTER_OPTIONS } from './constants';
import ProductTypeSelectorMobile from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorMobile';
import IwdReportDownloadDesktop from '../../mini-components/IwdReportDownload/IwdReportDownloadDesktop';
import IwdReportDownloadMobile from '../../mini-components/IwdReportDownload/IwdReportDownloadMobile';
import { open_browser_web } from '../../../utils/validators';
import IwdTable from '../../mini-components/IwdTable/IwdTable';
import { PRODUCT_TYPE_KEY_MAP, STATEMENT_PRODUCT_TYPE_OPTIONS } from '../../mini-components/IwdProductTypeSelector/constants';
import { scrollElementToPos } from '../../common/commonFunctions';

const PRODUCT_STORAGE_KEY = 'iwd-transaction-productType';

const Transactions = () => {
  const { selectedProductType, updateSelectedProductType } = useProductType(PRODUCT_STORAGE_KEY);

  const {
    filterOptions,
    mobileFilterOptions,
    transactionHeadersMap,
    fundSearchKey
  } = useMemo(() => {
    const options = PRODUCT_FILTER_OPTIONS_MAP[selectedProductType];
    return {
      filterOptions: options,
      mobileFilterOptions: [...(options || []), ...VIEW_FOR_FILTER_OPTIONS],
      transactionHeadersMap: TRANSACTIONS_TABLE_HEADERS_MAP[selectedProductType] || [],
      fundSearchKey: selectedProductType === PRODUCT_TYPE_KEY_MAP.mf ? 'amfi' : 'scheme_id'
    };
  }, [selectedProductType]);
  
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    scrollElementToPos('iwd-statement-transaction', 0, 0);
  }, [transactions]);

  const [selectedFundName, setSelectedFundName] = useState();
  const [activePage, setActivePage] = useState(1);
  const [fundNamesMap, setFundNamesMap] = useState(null);
  const [isApiRunning, setIsApiRunning] = useState(true);
  const [isFundNamesLoading, setIsFundNamesLoading] = useState(true);
  const [nextPageMap, setNextPageMap] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [filterVal, setFilterVal] = useState(null);

  const sendEvents = (user_action, props) => {
    let eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: 'statements',
        "user_action": user_action,
        product_selected: selectedProductType,
        sub_tab: 'transactions',
        ...props,
      }
    };

    if (["download report csv", "download report pdf"].includes(user_action)) {
      Object.assign(eventObj.properties, {
        filtered_transactions_type: filterVal?.ttype || "",
        date_range_selected: !!filterVal?.from_tdate ? "yes" : "no",
      });
    }

    nativeCallback({ events: eventObj });
  };

  const getUrlFromPageMap = (pageNo) => {
    /*
      nextPageMap contains a list of all nextPage urls.
      Index 0 contains url for page number 2
      Index 1 contains url for page number 3 and so on..

      Hence, when reading from nextPageMap, we offset by 2 positions
    */
    return nextPageMap[pageNo - 2];
  };

  const fetchTransactions = async (
    {
      pageNo, 
      productType,
      filters = {},
      fundName,
    },
    isFromPaginationSelect
  ) => {
    productType = productType || selectedProductType;

    try {
      setIsApiRunning(true);
      
      let response;
      if (pageNo === 1) {
        response = await getTransactions(productType, { 
          page_size: 10,
          ...filters,
          [fundSearchKey]: fundName
        });
        /* 
          When fetch is called due to pagination selection,
          pageMap must not be updated
        */
        if (!isFromPaginationSelect) {
          if (response.next_page) {
            setNextPageMap([response.next_page]);
          } else {
            // Reset page map
            setNextPageMap([]);
          }
        }
      } else {
        const currentPageUrl = getUrlFromPageMap(pageNo);
        const nextPageUrl = getUrlFromPageMap(pageNo + 1);
        response = await hitNextPage(currentPageUrl);
        if (response.next_page && !nextPageUrl) { // Don't push URL if next URL already exists
          setNextPageMap([...nextPageMap, response.next_page]);
        }
      }
      
      setTransactions(response.transactions);
    } catch (err) {
      setHasError(true);
    }
    setIsApiRunning(false);
  }

  const fetchFundNames = async () => {
    try {
      setIsFundNamesLoading(true);
      const { funds } = await getPortfolioFundNames(selectedProductType);
      setFundNamesMap(funds);
    } catch (err) {
      toast(err);
    } finally {
      setIsFundNamesLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions({
      pageNo: 1,
      filters: null,
      fundName: null,
    });
    fetchFundNames();
  }, [selectedProductType]);

  const onPageSelect = (page) => {
    setActivePage(page);
    fetchTransactions(
      {
        pageNo: page,
        filters: filterVal,
        fundName: selectedFundName
      },
      true
    );
  };

  const handleFilterChange = (val) => {
    setActivePage(1); // reset pagination everytime filter is changed

    let newFilters;
    if (isEmpty(val)) {
      newFilters = null;
    } else {
      newFilters = { ...filterVal, ...val };
    }

    setFilterVal(newFilters);
    fetchTransactions({
      pageNo: 1,
      filters: newFilters,
      fundName: selectedFundName
    });
  };

  const onFundNameChange = (value) => {
    setSelectedFundName(value);
    fetchTransactions({
      pageNo: 1,
      filters: filterVal,
      fundName: value
    });
  }

  const downloadPdfReport = (e) => {
    e.stopPropagation();
    sendEvents("download report pdf");
    downloadTransactions('pdf');
  }
  
  const downloadCsvReport = (e) => {
    e.stopPropagation();
    sendEvents("download report csv");
    downloadTransactions('csv');
  }

  const downloadTransactions = async (fileType) => {
    const userId = getUserIdForRequest();
    if (transactions?.length > 0) {
      try {
        const baseURL = getConfig().base_url;
        
        let urlToHit = '';
        switch (selectedProductType) {
          case PRODUCT_TYPE_KEY_MAP.mf:
            urlToHit = `${baseURL}/api/rta/download/account/summary/${fileType}?is_iwd=true`;
            break;
          case PRODUCT_TYPE_KEY_MAP.aif:
            urlToHit = `${baseURL}/api/privatewealth/report/download/aif/${fileType}`;
            break;
          case PRODUCT_TYPE_KEY_MAP.pms:
            urlToHit = `${baseURL}/api/privatewealth/report/download/pms/${fileType}`;
            break;
          default:
            return;
        }
        open_browser_web(
          buildUrlWithQueryParams(urlToHit, { ...filterVal, user_id: userId }),
          '_blank'
        );
      } catch (err) {
        console.log(err);
        toast(err);
      }
    } else {
      toast('No transaction to download');
    }
  };

  const retry = () => {
    setHasError(false);
    fetchTransactions({
      pageNo: activePage,
      filters: filterVal,
      fundName: selectedFundName
    });
    fetchFundNames();
  };

  const onProductTypeChange = (value) => {
    if (!value) return;

    if (value !== selectedProductType) {
      setHasError(false);
      setFilterVal(null);
      setActivePage(1);
      // setIsApiRunning(true); // Initiate table loader from here itself
      updateSelectedProductType(value);
      sendEvents('product change', {
        product_selected: value,
      });
    }
  };

  const renderLeftSection = () => {
    return (
      <LeftPanel>
        <ProductTypeSelectorDesktop
          value={selectedProductType}
          onChange={onProductTypeChange}
          disabled={isApiRunning}
          productOptions={STATEMENT_PRODUCT_TYPE_OPTIONS}
        />
        <div className="iwd-left-panel-divider"></div>
        {filterOptions &&
          <IwdFilterDesktop
            key={selectedProductType} // Reset component state when selectedProductType type changes
            filterOptions={filterOptions}
            filterValue={filterVal}
            onFilterChange={handleFilterChange}
            disabled={isApiRunning}
          />
        }
      </LeftPanel>
    );
  }

  const renderMobileFilter = () => {
    // Visibility in desktop handled through media queries
    if (!isEmpty(filterOptions)) {
      return (
        <IwdFilterMobile
          showDatePickers
          showFilterButton={!hasError}
          filterValue={filterVal}
          filterOptions={mobileFilterOptions}
          onFilterChange={handleFilterChange}
        />
      );
    }

    return "";
  }

  const renderPageError = () => {
    return (
      <div className='iwd-statement-trans-error'>
        <IwdErrorScreen
          hasError={true}
          templateErrTitle='Oops!'
          templateErrText='Something went wrong! Please retry after some time or contact your wealth manager'
          templateBtnText='Retry'
          clickHandler={retry}
        />
      </div>
    );
  }

  const renderPageContent = () => {
    return (
      <div className='iwd-page-with-table'>
        <section className='iwd-transaction-search-container iwd-animatedFade'>
          <ProductTypeSelectorMobile
            value={selectedProductType}
            onChange={onProductTypeChange}
            productOptions={STATEMENT_PRODUCT_TYPE_OPTIONS}
            isDisabled={isApiRunning}
          />
          <div className='iwd-transaction-search'>
            <AutoSuggestSearch
              placeholder='Which fund are you looking for?'
              fundNamesMap={fundNamesMap}
              value={selectedFundName}
              onChange={onFundNameChange}
              isDisabled={isFundNamesLoading || isApiRunning}
            />
          </div>
        </section>
        <IwdTableContainer
          isLoading={isApiRunning}
          loadingText="Fetching transactions..."
          noData={isEmpty(transactions)}
          noDataMessage={
            !isEmpty(filterVal) ?
              'Oops! We couldn’t find any data for the selected filter. Try removing or changing the filters.' :
              'No transactions to show'
          }
        >
          <IwdTableContainer.Header>
            <div className='iwd-transaction-title'>Transactions</div>
            {!PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType].hideReportsDownload &&
              <>
                <IwdReportDownloadDesktop
                  showPdf={PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType].showPdf}
                  onPdfDownloadClick={downloadPdfReport}
                  showCsv={PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType].showCsv}
                  onCsvDownloadClick={downloadCsvReport}
                />
                <IwdReportDownloadMobile
                  dialogProps={{
                    showPdf: PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType].showPdf,
                    onPdfDownloadClick: downloadPdfReport,
                    showCsv: PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType].showCsv,
                    onCsvDownloadClick: downloadCsvReport
                  }}
                />
              </>
            }
          </IwdTableContainer.Header>
          <IwdTableContainer.TableContent>
            <IwdTable
              serializeData
              serialOffset={(activePage - 1) * 10}
              headersMap={transactionHeadersMap}
              data={transactions}
            />
          </IwdTableContainer.TableContent>
          {!isApiRunning && transactions?.length > 0 && (
            <IwdTableContainer.Pagination
              pages={(nextPageMap.length || 0) + 1}
              activePage={activePage}
              onSelect={onPageSelect}
            />
          )}
        </IwdTableContainer>
      </div>
    );
  }

  return (
    <div className='iwd-statement-transaction'>
      {renderLeftSection()}
      {renderMobileFilter()}
      {hasError
        ? renderPageError()
        : renderPageContent()
      }
    </div>
  );
};

export default Transactions;
