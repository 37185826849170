import React from "react";
import iv_important from "assets/iv_important.svg";
import SVG from "react-inlinesvg";
import "./styles.scss";

const StatusIndicator = () => {
  return (
    <div className="finity-upgrade-status">
      <SVG src={iv_important} className="icon" />
      <div className="imp-text">Important update</div>
    </div>
  );
};

export default StatusIndicator;
