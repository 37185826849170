import FisdomLogo from "assets/fisdom/fisdom_icon_gold.svg";
import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import Toast from "../../../common/ui/Toast";

import IwdTable from "../../mini-components/IwdTable/IwdTable";
import IwdTableContainer from "../../mini-components/IwdTableContainer/IwdTableContainer";
import NavBarMobile from "../../mini-components/NavBarMobile";
import PageHeader from "../../mini-components/PageHeader";

import { getConfig } from "../../../utils/functions";
import { nativeCallback } from "../../../utils/native_callback";
import { storageService } from "../../../utils/validators";
import { getWMClientList } from "../../common/ApiCalls";
import { useUserContext } from "../../common/UserContext";
import InternalStorage from "../../InternalStorage";

import { INTERNAL_STORAGE_CONSTANTS, STORAGE_CONSTANTS } from "../../common/constants";
import { CLIENT_LIST_HEADERS_MAP } from "./constants";
import "./SalesView.scss";
import { navigate as navigateFunc, userNameFormatter } from "../../common/commonFunctions";
import { TextButton } from "../../common/Button";

const isMobileView = getConfig().isMobileDevice;

const sendEvents = (user_action, props) => {
  const eventObj = {
    "event_name": 'internal dashboard hni',
    "properties": {
      user_action,
      screen_name: 'client directory',
      login_by: 'RM',
      ...props
    }
  };

  nativeCallback({ events: eventObj });
};

const triggerLandedEvent = (eventProps) => {
  let prevLandedTime = storageService().get(STORAGE_CONSTANTS.wmDashboardLandedTime);
  prevLandedTime = prevLandedTime ? new Date(prevLandedTime) : null;
  const currentTime = new Date();

  // If user lands on this page again within 15 mins, will not log/trigger the event
  if (!prevLandedTime || (currentTime - prevLandedTime) / 60000 > 15) {
    storageService().set(STORAGE_CONSTANTS.wmDashboardLandedTime, currentTime);
    sendEvents('landed', eventProps);
  }
}

const SalesView = (props) => {
  const [clientsData, setClientsData] = useState([]);
  const [clientsLoading, setIsClientsLoading] = useState(false);
  const navigate = navigateFunc.bind(props);
  const [pageNo, setPageNo] = useState('');
  const [isFetchingMoreList, setIsFetchingMoreList] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);

  const { wmDetails, isWmLogin, userDetails } = useUserContext();

  const fetchClientDetails = async () => {
    try {
      setIsClientsLoading(true);
      
      let clientList;
      const storedClientList = InternalStorage.getData(INTERNAL_STORAGE_CONSTANTS.wmClientList);
      if(isEmpty(storedClientList)) {
        const result = await getWMClientList();
        clientList = result.client_directory;
        const pageNumber = result.page_no;
        if(pageNumber > 0) {
          setShowViewMore(true);
          setPageNo(pageNumber);
        }
        InternalStorage.setData(INTERNAL_STORAGE_CONSTANTS.wmClientList, clientList);
      } else {
        clientList = storedClientList
      }
      setClientsData(clientList);
      triggerLandedEvent({
        no_of_client_available: clientList.length
      });
    } catch (e) {
      console.log(e);
      Toast(e);
    } finally {
      setIsClientsLoading(false);
    }
  };

  useEffect(() => {
    if (isWmLogin) {
      fetchClientDetails();
    } else {
      if (isEmpty(userDetails)) {
        Toast("Please login to view Wealth Manager Dashboard");
        navigate("login");
      } else {
        navigate("main/dashboard");
      }
    }
  }, []);

  const onViewMoreClick = async () => {
    try {
      setIsFetchingMoreList(true);
      const result = await getWMClientList(pageNo);
      const pageNumber = result.page_no;
      const newClientList = [
        ...clientsData,
        ...result.client_directory,
      ];
      setPageNo(pageNumber);
      InternalStorage.setData(INTERNAL_STORAGE_CONSTANTS.wmClientList, newClientList);
      setClientsData(newClientList);
      triggerLandedEvent({
        no_of_client_available: newClientList.length
      });
    } catch (errorMessage) {
      setShowViewMore(false);
      Toast("No data to display");
    } finally {
      setIsFetchingMoreList(false);
    }
  }

  return (
    <>
      {isMobileView &&
        <NavBarMobile
          hideNavigation
          hideMenu
          profileData={wmDetails}  
          disableLogoClick={isWmLogin}
        />
      }
      <div className='iwd-page iwd-wm-dashboard'>
        <PageHeader profileData={wmDetails}>
          <div className="iwd-wd-header-container">
            <img src={FisdomLogo} alt="fisdom_logo" />
            <div>
              <div className="iwd-wd-header-title">Wealth Manager Dashboard</div>
              <div className="iwd-wd-header-subtitle">Welcome back, {userNameFormatter(wmDetails.name)}</div>
            </div>
          </div>
        </PageHeader>
        <div className="iwd-page-with-table">
          <IwdTableContainer
            isLoading={clientsLoading}
            loadingText="Fetching client details..."
            noData={isEmpty(clientsData)}
            noDataMessage="No Clients to show"
            className="iwd-svt-container"
          >
            <IwdTableContainer.Header title="Client Directory" />
            <IwdTableContainer.TableContent>
              <IwdTable
                serializeData
                headersMap={CLIENT_LIST_HEADERS_MAP}
                data={clientsData}
              />
            </IwdTableContainer.TableContent>
            {showViewMore && (
              <div className="iwd-wd-view-more">
                <TextButton
                  isLoading={isFetchingMoreList}
                  onClick={onViewMoreClick}
                >
                  <span>View More</span>
                </TextButton>
              </div>
            )}
          </IwdTableContainer>
        </div>
      </div>
    </>
  );
};

export default SalesView;
