import React from 'react';
import IwdTable from '../../mini-components/IwdTable/IwdTable';

export default function HoldingsTable({ holdings = [], headersMap }) {
  return (
    <IwdTable
      serializeData
      headersMap={headersMap}
      data={holdings}
    />
  );
}