import React, { useEffect } from 'react';
import IwdTabs from '../mini-components/IwdTabs/IwdTabs';

const HeaderNavBar = ({ title, tabs, handlePageType, currentTab }) => {

  useEffect(() => {
    handlePage(currentTab);
  }, [currentTab]);
  
  const handlePage = (tab) => {
    handlePageType(tab);
  };

  return (
    <div className='iwd-page-header-nav'>
      <div className='iwd-page-header-nav-title'>{title}</div>
      <IwdTabs
        classes={{
          root: 'iwd-page-header-path-list',
        }}
        tabsList={tabs}
        currentTab={currentTab}
        onTabClick={handlePage}
      />
    </div>
  );
};

export default HeaderNavBar;
