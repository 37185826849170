import React from 'react';
import Select, { components } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import { useCallback } from 'react';
import { colorConfig } from '../theme/colorConfig';
import { getHexColorWithOpacity } from '../common/commonFunctions';

const AutoSuggestSearch = ({
  fundNamesMap,
  value,
  onChange,
  ...props
}) => {
  const suggestions = Object.keys(fundNamesMap || {}).map((key) => ({
    label: key,
    value: fundNamesMap[key],
  }));

  const getStoredFundName = useCallback(() => {
    const data = suggestions.find((el) => el.value === value);
    return data || '';
  }, [value]);

  const handleChange = (data) => {
    onChange(data?.value || '');
  };

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <SearchIcon style={{ position: 'absolute', left: 16, color: colorConfig.primary.gold[600] }} />
          {children}
        </components.ValueContainer>
      )
    );
  };

  const DropdownIndicator = () => null;
  const Option = (props) => {
    return (
      components.Option && (
        <components.Option {...props}>
          {props.data.label}
          {props.isSelected && (
            <CheckIcon style={{ position: 'absolute', right: 10, color: colorConfig.primary.gold[600] }} />
          )}
        </components.Option>
      )
    );
  };
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 30,
      textAlign: 'left',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    control: (base, state) => ({
      ...base,
      minHeight: '45px',
      border: 'none',
      color: 'black',
      boxShadow: 'none',
      background: getHexColorWithOpacity(colorConfig.primary.gold[600], 0.05),
      opacity: state.isDisabled ? 0.4 : 1,
      cursor: 'pointer',
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: '1.5em',
      color: getHexColorWithOpacity(colorConfig.content.primary, 0.4),
      position: 'absolute',
    }),
    singleValue: (base) => ({
      ...base,
      marginLeft: '20px',
      color: colorConfig.content.primary,
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 47px)',
      top: '50%',
      transform: 'translateY(-50%)',
      boxSizing: 'border-box',
    }),
    option: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: getHexColorWithOpacity(colorConfig.primary.gold[600], 0.05),
        color: colorConfig.content.primary,
        cursor: 'pointer'
      },
      backgroundColor: state.isSelected ? colorConfig.primary.gold[300] : colorConfig.supporting.white,
      color: colorConfig.content.primary,
      alignItems: 'left',
      padding: '20px 15px',
    }),
    input: (base) => ({
      ...base,
      marginLeft: '1.5em',
    }),
    menu: (base) => ({
      ...base,
      marginLeft: '2px',
    }),
  };
  return (
    <div>
      <Select
        components={{ DropdownIndicator, ValueContainer, Option }}
        styles={customStyles}
        options={suggestions}
        isSearchable
        isClearable
        onChange={handleChange}
        value={getStoredFundName()}
        {...props}
      />
    </div>
  );
};

export default AutoSuggestSearch;
