import "./IwdTable.scss";
import React from "react";
import FSTable from "../../../common/responsive-components/FSTable";

export default function IwdTable({ className, ...props }) {
  return (
    <FSTable
      {...props}
      className={`iwd-table ${className}`}
    />
  );
}