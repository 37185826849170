import React, { Fragment, useState, useEffect, cloneElement, useCallback } from "react";
import {
  resendOtp,
  login,
  verifyOtp,
  verifyPin,
} from "./login_apis";
import toast from '../../../../common/ui/Toast';
import { FormControl, TextField } from "material-ui";
import { validateEmail } from "../../../../utils/validators";
import isEmpty from 'lodash/isEmpty';
import startCase from "lodash/startCase";

const VIEW_TYPE_MAP = {
  email: 'email',
  mobile: 'mobile',
  otp: 'otp',
  pin: 'pin',
};
const formatNumber = (number) => {
  if (number.length > 10 && number.slice(0, 2)) {
    return number.slice(2);
  }
  return number;
};

const LoginFields = (props) => {
  const { navigateFunction: navigate, parentProps, handleInvalidRoute } = props;
  const { params = {} } = parentProps.match || {};
  const [view, setView] = useState('');
  const [otp, setOtp] = useState('');
  const [countryCode, setCountryCode] = useState('91');
  const [number, setNumber] = useState('');
  const [opLoading, setOpLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [resendDisabled, disableResend] = useState(false);
  const [inputValueToShow, setInputValueToShow] = useState("");
  const [errObj, setErrObj] = useState({});
  const [otpUrls, setOtpUrls] = useState({});
  const [pin, setPin] = useState("");
  const [userRes, setUserRes] = useState("");
  const [loginBy, setLoginBy] = useState("");

  const setInitialView = () => {
    if (params.view) {
      setView(params.view);
      if (params.view === VIEW_TYPE_MAP.mobile) {
        setEmail('');
      } else if (params.view === VIEW_TYPE_MAP.email) {
        setNumber('');
      }
    } else {
      setView(VIEW_TYPE_MAP.mobile);
    }
  }

  const setErrorForView = (errMsg) => {
    setErrObj({ [view]: errMsg });
  }

  useEffect(() => {
    setInitialView();
    setErrObj({});
    if (params.view !== VIEW_TYPE_MAP.otp) {
      setOtpUrls({});
      setOtp("");
    }
    if (params.view !== VIEW_TYPE_MAP.pin) {
      setPin("");
    }
  }, [params.view]);

  useEffect(() => {
    if(!isEmpty(view)){
      const isValidViewType = Object.values(VIEW_TYPE_MAP).includes(view);
      if(!isValidViewType) {
        handleInvalidRoute();
      }
    }
  }, [view]);


  const goBack = () => {
    setEmail('');
    setNumber('');
    navigate(`login/${VIEW_TYPE_MAP.mobile}`);
  };

  const handleOtp = (val) => {
    setOtp(val);
  };

  useEffect(() => {
    if (otp.length === 4) verify();
  }, [otp]);

  const handlePin = (val) => {
    setPin(val);
  };

  useEffect(() => {
    if (pin.length === 4) pinVerify();
  }, [pin]);

  const handleNumberChange = (value, { dialCode }) => {
    setNumber(value);
    setCountryCode(dialCode);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    setErrObj({});
  }, [number, email, otp]);

  const triggerOtp = async (auth_type, auth_value) => {
    setLoginBy(auth_type);
    try {
      setOpLoading(true);
      const res = await login(auth_type, auth_value);
      setOtpUrls({
        verifyUrl: res.verify_url,
        resendUrl: res.resend_url
      })
      navigate(`login/${VIEW_TYPE_MAP.otp}`);
    } catch (err) {
      console.log(err);
      toast(err);
    }
    setOpLoading(false);
  };

  const triggerOtpResend = async () => {
    try {
      if (resendDisabled) return;
      setOtp('');
      disableResend(true);
      await resendOtp(otpUrls.resendUrl);
      setTimeout(() => disableResend(false), 30000);
      toast('OTP resent!');
    } catch (err) {
      console.log(err);
      if (err.toLowerCase().includes('correct otp')) {
        toast('Incorrect OTP! Please check and try again');
      } else {
        toast(err);
      }
    }
  };

  const verify = async () => {
    if (otp.length < 4) {
      return toast("Please input OTP");
    }
    try {
      setOpLoading(true);
      const res = await verifyOtp(
        otpUrls.verifyUrl,
        { otp }
      );
      const { user } = res;
      setUserRes(user);
      if (user.pin_status === "pin_setup_complete") {
        navigate(`login/${VIEW_TYPE_MAP.pin}`, null, true);
      } else {
        props.onLoginSuccess(loginBy, user);
      }
    } catch (err) {
      if (err.includes('wrong OTP')) {
        setErrorForView('Incorrect OTP! Please check and try again');
      } else {
        console.log(err);
        props.onLoginFail(loginBy);
        toast(err);
      }
    } finally {
      setOpLoading(false);
    }
  };

  const pinVerify = async () => {
    if (pin.length < 4) {
      return toast("Please input PIN");
    }
    try {
      setOpLoading(true);
      await verifyPin({ mpin: pin });
      props.onLoginSuccess(loginBy, userRes);
    } catch (err) {
      console.log(err);
      props.onLoginFail(loginBy);
      setErrorForView(err);
    } finally {
      setOpLoading(false);
    }
  };

  const loginWithPhone = async () => {
    const mobile = `${countryCode}-${formatNumber(number)}`
    const err = validatePhone();
    if (err) {
      return setErrorForView(err);
    }
    setInputValueToShow(`+${mobile}`);
    await triggerOtp("mobile", mobile);
  };

  const validatePhone = () => {
    let err = '';
    if (!countryCode) {
      err = "Please select a country code";
    } else if (!number || number.length !== 12) {
      err = "Please enter a valid number";
    }
    return err;
  };

  const loginWithEmail = async () => {
    try {
      if (!validateEmail(email)) {
        return setErrorForView("Please enter a valid email");
      }
      setOpLoading(true);
      setInputValueToShow(email)
      await triggerOtp("email", email);
    } catch (err) {
      console.log(err);
      if (err.includes('registered')) {
        setErrorForView("This email is not registered!");
      } else {
        toast(err);
      }
    }
    setOpLoading(false);
  };

  const clickContinue = () => {
    switch (view) {
      case VIEW_TYPE_MAP.mobile:
        return loginWithPhone();
      case VIEW_TYPE_MAP.otp:
        return verify();
      case VIEW_TYPE_MAP.email:
        return loginWithEmail();
      case VIEW_TYPE_MAP.pin:
        return pinVerify();
      default:
        return '';
    }
  };

  const handleKeyDown = (event) => {
    var code = event.keyCode;
    if (code === 13) {
      clickContinue();
    }
  }

  const renderNumberView = (
    <>
      <CustomPhoneInput
        inputComponent={props.phoneComponent}
        value={number}
        onChange={handleNumberChange}
        onEnterPressed={clickContinue}
      />
      <CustomButton
        inputComponent={props.buttonComponent}
        fullWidth
        onClick={clickContinue}
        isLoading={opLoading}
      >
        Login
      </CustomButton>
      <CommonLoginOpts navigate={navigate} view={view} />
    </>
  );

  const renderOTPView = (
    <>
      <div id="cli-otp-subheader">
        Sent to {inputValueToShow}
        <span onClick={() => parentProps.history.goBack()}>Change</span>
      </div>
      <div style={{ width: '100%' }}>
        <CustomOtpInput
          inputComponent={props.otpComponent}
          value={otp}
          hasErrored={!!errObj[view]}
          onChange={handleOtp}
          onEnterPressed={clickContinue}
        />
      </div>
      <CustomButton
        key={VIEW_TYPE_MAP.otp}
        inputComponent={props.buttonComponent}
        fullWidth
        onClick={clickContinue}
        isLoading={opLoading}
      >
        Continue
      </CustomButton>
      <div id="cli-otp-resend">
        Not received your code?
        &nbsp;
        <span
          onClick={triggerOtpResend}
          className={resendDisabled && "cor-resend-disable"}
        >
          Resend OTP
        </span>
      </div>
      <div id="cli-otp-back" onClick={goBack}>
        Back to login
      </div>
    </>
  );

  const renderPinView = (
    <>
      <div id="cli-otp-subheader">
        Enter Fisdom pin
      </div>
      <div style={{ width: '100%' }}>
        <CustomOtpInput
          key={VIEW_TYPE_MAP.pin}
          inputComponent={props.otpComponent}
          value={pin}
          hasErrored={!!errObj[view]}
          onChange={handlePin}
          onEnterPressed={clickContinue}
        />
      </div>
      <CustomButton
        inputComponent={props.buttonComponent}
        fullWidth
        onClick={clickContinue}
        isLoading={opLoading}
      >
        Continue
      </CustomButton>
      <div className="cli-forgot-pwd" onClick={props.onForgotPasswordClicked}>
        Forgot Pin?
       </div>
      <div id="cli-otp-back" onClick={goBack}>
        Back to login
      </div>
    </>
  );

  const renderEmailView = (
    <>
      <FormControl className="cli-email-form-1">
        <TextField
          variant="outlined"
          placeholder="Enter email"
          InputProps={{
            disableUnderline: true,
            classes: props.emailFieldClasses,
          }}
          value={email}
          type="email"
          autoFocus
          classes={props.emailFieldClasses || {}}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
        />
      </FormControl>
      <CustomButton
        inputComponent={props.buttonComponent}
        fullWidth
        onClick={clickContinue}
        isLoading={opLoading}
      >
        Login
      </CustomButton>
      <CommonLoginOpts navigate={navigate} view={view} />
    </>
  );

  const renderError = () => {
    let errText = errObj?.[view] || '';

    if (errText) {
      return (
        <div className="common-login-err">
          {errText}
        </div>
      );
    }

    return '';
  };

  const renderViewBody = () => {
    switch (view) {
      case VIEW_TYPE_MAP.mobile:
        return renderNumberView;
      case VIEW_TYPE_MAP.otp:
        return renderOTPView;
      case VIEW_TYPE_MAP.email:
        return renderEmailView;
      case VIEW_TYPE_MAP.pin:
        return renderPinView;
      default:
        return '';
    }
  };

  const renderViewHeader = useCallback(() => {
    switch (view) {
      case VIEW_TYPE_MAP.mobile:
        return 'Login with mobile number';
      case VIEW_TYPE_MAP.otp:
        return 'Verify with OTP';
      case VIEW_TYPE_MAP.email:
        return 'Login with email';
      case VIEW_TYPE_MAP.pin:
        return `Welcome back ${startCase(userRes?.name?.toLowerCase())}!`;
      default:
        return '';
    }
  }, [view]);

  return (
    <Fragment>
      {view !== 'loading' &&
        <div id="common-login">
          {renderError()}
          <div className="common-login-input">
            <div className="cli-header">
              {renderViewHeader()}
            </div>
            {renderViewBody()}
          </div>
        </div>
      }
    </Fragment>
  );
}

const CommonLoginOpts = ({ navigate, view }) => {
  const goTo = view === VIEW_TYPE_MAP.email ? VIEW_TYPE_MAP.mobile : VIEW_TYPE_MAP.email;
  return (
    <div className="common-login-opts">
      <span id="or-opt">or login with</span>
      <a onClick={() => navigate(`login/${goTo}`)}>{goTo}</a>
    </div>
  );
}

export default LoginFields;

const CustomPhoneInput = ({ inputComponent, ...props }) => {
  return cloneElement(inputComponent, props);
}

const CustomOtpInput = ({ inputComponent, ...props }) => {
  return cloneElement(inputComponent, props);
}

const CustomButton = ({ inputComponent, ...props }) => {
  return cloneElement(inputComponent, {
    style: { marginTop: '20px' },
    ...props
  });
}