exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}.iwd-dialog{font-family:\"Montserrat\",\"Roboto\",sans-serif}.iwd-dialog .MuiDialog-paper{border-radius:12px}.iwd-dialog .MuiDialogActions-root{text-align:unset !important;justify-content:unset !important;margin:0 !important;padding:20px !important;column-gap:10px;display:flex !important;justify-content:space-between !important;width:100% !important}.iwd-dialog .MuiDialogActions-action{margin:0 !important}.iwd-dialog .iwd-diag-button1{height:40px !important;flex:1 !important;border-radius:8px !important;margin-left:0 !important}.iwd-dialog .iwd-diag-button2{height:40px !important;flex:1.5 !important;border-radius:8px !important}@media only screen and (max-width: 450px){.iwd-dialog .MuiDialogActions-root{flex-direction:column}.iwd-dialog .iwd-diag-button1{width:100% !important}.iwd-dialog .iwd-diag-button2{width:100% !important;margin-top:10px !important}}", ""]);

// exports
