// ----------------- Assets ----------------------
import './IwdFilter.scss';
import filter_sign from 'assets/filter_sign.svg';
// -----------------------------------------------
import React, { useEffect, useState } from 'react';
import PageCloseBtn from '../PageCloseBtn';
import FilterRadioItem from './FilterRadioItem';
import { PrimaryButton as Button } from '../../common/Button';
import TextField from '@material-ui/core/TextField';
import { dateFormater, date_range_selector } from '../../common/commonFunctions';
import isFunction from 'lodash/isFunction';
import { Dialog } from 'material-ui';
import { CSSTransition } from 'react-transition-group';

const IwdFilterMobile = ({
  onClose,
  filterOptions,
  filterValue,
  onFilterChange,
  showDatePickers,
  showFilterButton
}) => {
  // TODO: (BUG) Setting filters and closing filter modal without 'applying' causes selected filters to retain even if they are not set
  const [startDate, setStartDate] = useState(filterValue?.['from_tdate'] || '');
  const [endDate, setEndDate] = useState(filterValue?.['to_tdate'] || '');
  const [filterState, setFilterState] = useState();
  const [canClear, setCanClear] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => setFilterState(filterValue), [filterValue]);

  useEffect(() => {
    if (filterState) {
      const isFilterSet = Object.keys(filterState).some(filterKey => filterState[filterKey]);
      setCanClear(isFilterSet);
    }
  }, [filterState]);

  const handleFilterSelect = id => value => {
    let start = startDate || '';
    let end = endDate || '';
    let newFilterObj = { [id]: value || '' };
    
    if (id === 'viewFor' && value !== '') {
      if (value !== 'custom_dates') {
        [start, end] = date_range_selector[value]();
        setStartDate(dateFormater(start));
        setEndDate(dateFormater(end));
      }
      newFilterObj.from_tdate = start ? dateFormater(start) : '';
      newFilterObj.to_tdate = end ? dateFormater(end) : '';
    }
    setFilterState({ ...filterState, ...newFilterObj });
  };

  const applyFilters = () => {
    if (canClear) {
      // If canClear is false, no filters are selected, can skip this call
      onFilterChange(filterState);
    }
    if (showFilterButton) {
      toggleOpenFilter();
    }
    if (isFunction(onClose)) {
      onClose();
    }
  };

  const clearFilters = () => {
    if (canClear) {
      setFilterState(null);
      setStartDate('');
      setEndDate('');
      onFilterChange(null);
      setCanClear(false);
    }
  };

  const handleDateChange = (e, dateType) => {
    if (filterState?.[dateType] !== dateFormater(e.target.value)) {
      if (dateType === 'from_tdate') {
        setStartDate(dateFormater(e.target.value));
      } else {
        setEndDate(dateFormater(e.target.value));
      }
      setFilterState({
        ...filterState,
        [dateType]: dateFormater(e.target.value),
        viewFor: 'custom_dates'
      });
    }
  };

  const renderFilters = () => (
    <div>
      {filterOptions?.map((filterItem) => {
        return (
          <FilterRadioItem
            key={filterItem.id}
            title={filterItem.category}
            value={filterState?.[filterItem.id] || ''}
            options={filterItem.filters}
            onChange={handleFilterSelect(filterItem.id)}
          />
        );
      })}
    </div>
  );

  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }

  const onCloseClicked = () => {
    setFilterState(filterValue); // Reset filter to original state 
    toggleOpenFilter();
  }

  return (
    <>
      {!openFilter && showFilterButton &&
        <FilterBtn onClick={toggleOpenFilter} />
      }
      <CSSTransition
        appear
        unmountOnExit
        in={openFilter}
        classNames="circularExpandFilter"
        timeout={2000}
      >
        <Dialog fullScreen open classes={{ paper: 'iwd-filter-mobile' }}>
          <div className='iwd-filter-mobile-container'>
            <PageCloseBtn onClick={onCloseClicked} />
            <div className='iwd-filter-mobile-section'>
              <section className='iwd-filter-header-container'>
                <div className='iwd-filter-header'>Filters</div>
              </section>
              {renderFilters()}
              {showDatePickers && (
                <>
                  <div className='iwd-transaction-date-filter'>
                    <div className='iwd-transaction-date'>
                      <div className='iwd-date-text'>From</div>
                      <div className='iwd-date-selector'>
                        <TextField
                          id='date'
                          type='date'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // disabled={filterState?.viewFor !== 'select_dates' || isEmpty(filterState)}
                          value={startDate}
                          onChange={(e) => handleDateChange(e, 'from_tdate')}
                        />
                      </div>
                    </div>
                    <div className='iwd-transaction-date'>
                      <div className='iwd-date-text'>To</div>
                      <div className='iwd-date-selector'>
                        <TextField
                          id='date'
                          type='date'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // disabled={filterState?.viewFor !== 'select_dates' || isEmpty(filterState)}
                          value={endDate}
                          onChange={(e) => handleDateChange(e, 'to_tdate')}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className='iwd-filter-footer'>
                <div
                  className={`iwd-filter-clear ${!canClear && 'iwd-disable-clear'}`}
                  onClick={clearFilters}
                >
                  Clear All
                </div>
                <Button onClick={applyFilters} disabled={!canClear} uppercaseText>Apply</Button>
              </div>
            </div>
          </div>
        </Dialog>
      </CSSTransition>            
    </>
  );
};

export default IwdFilterMobile;

export const FilterBtn = ({ onClick }) => (
  <div className='iwd-filter-button' onClick={onClick}>
    <img src={filter_sign} alt='filter' />
  </div>
)