import React from 'react';
import './IwdDisclaimerNote.scss';

const IwdDisclaimerNote = ({
  message,
  className,
  children,
  showNote,
  ...props
}) => {
  return (
    <div className={`iwd-disclaimer-note ${className}`} {...props}>
      {showNote &&
        <div className="iwd-dn-note">
          <b>Note: </b>
        </div>
      }
      <div className="iwd-dn-content">
        {message || children}
      </div>
    </div>
  );
}

export default React.memo(IwdDisclaimerNote);