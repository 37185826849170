import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getConfig } from "utils/functions";
import { colorConfig } from '../theme/colorConfig';
import { getHexColorWithOpacity } from './commonFunctions';
import { DEFAULT_FONT_FAMILY } from './constants';

const isMobileView = getConfig().isMobileDevice;

const NewButton = ({ disabled, isLoading, uppercaseText, ...props }) => {
  const { root, raised, label } = props.classes || {};

  return (
    <Button
      variant={props.variant || 'raised'}
      className={uppercaseText ? 'iwd-btn-uppercase-label' : ''}
      classes={{ root, raised, label }}
      style={props.style}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading
        ? <CircularProgress size={25} thickness={4} color="white" />
        : props.children
      }
    </Button>
  );
};

export const OutlinedButton = withStyles({
  raised: {
    boxShadow: 'none',
    borderRadius: '4px',
    padding: isMobileView ? '8px' : '13px 19px',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: getHexColorWithOpacity(colorConfig.primary.gold[300], 0.3),
    },
    border: `2px solid ${colorConfig.action.brand}`,
    height: '50px',
    minHeight: '40px',
  },
  disabled: {
    backgroundColor: 'white !important',
    opacity: '0.5 !important',
  },
  label: {
    fontFamily: DEFAULT_FONT_FAMILY,
    color: colorConfig.action.brand,
    textAlign: 'center',
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    fontWeight: 'bold',
  },
})(NewButton);

export const TextButton = withStyles({
  raised: {
    boxShadow: 'none',
    borderRadius: '4px',
    padding: isMobileView ? '8px' : '13px 19px',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: colorConfig.primary.gold[300],
    },
    height: '50px',
    minHeight: '40px',
  },
  disabled: {
    backgroundColor: 'white !important',
    opacity: '0.6 !important',
  },
  label: {
    fontFamily: DEFAULT_FONT_FAMILY,
    color: colorConfig.action[600],
    textAlign: 'center',
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    fontWeight: 'bold',
  },
})(NewButton);

export const PrimaryButton = withStyles({
  raised: {
    boxShadow: 'none',
    borderRadius: '4px',
    padding: isMobileView ? '8px' : '13px 19px',
    backgroundColor: colorConfig.primary.gold.brand,
    '&:hover': {
      backgroundColor: colorConfig.primary.gold.brand,
      opacity: 1,
    },
    height: '50px',
    minHeight: '40px',
  },
  disabled: {
    backgroundColor: `${colorConfig.primary.gold.brand} !important`,
    opacity: '0.5 !important',
  },
  label: {
    fontFamily: DEFAULT_FONT_FAMILY,
    color: colorConfig.supporting.white,
    textAlign: 'center',
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    fontWeight: 'bold',
  },
})(NewButton);