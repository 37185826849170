import './IwdLegend.scss';
import React, { useMemo } from 'react'
import { formatPercentVal } from '../../common/commonFunctions';

function IwdLegend({
  data = [],
  labelKey = 'label',
  valueKey = 'value',
  columns = 2,
  rows,
  classes = {}
}) {
  const {
    container: containerClass,
    child: childClass,
    label: labelClass,
    value: valueClass,
    icon: iconClass,
  } = classes;

  const noOfColumns = useMemo(() =>
    data.length > 1 ? columns : data.length
  , [data]);
  const noOfRows = useMemo(() =>
    rows || (data.length > 1 ? (data.length / noOfColumns) : data.length)
  , [data]);

  return (
    <div
      className={`iwd-legend-container ${containerClass}`}
      style={{
        gridTemplateColumns: `repeat(${noOfColumns}, 1fr)`,
        gridTemplateRows: `repeat(${noOfRows}, 1fr)`
      }}
    >
      {data.map(row => (
        <div
          key={row[labelKey]}
          className={`iwd-legend-child ${childClass}`}
        >
          <header className="iwd-lc-header">
            <div
              className={`iwd-lc-icon ${iconClass}`}
              style={{ backgroundColor: row.color }}
            >
            </div>
            <div className={`iwd-lc-title ${labelClass}`}>{row[labelKey]}</div>
          </header>
          <div className={`iwd-lc-value ${valueClass}`}>{formatPercentVal(row[valueKey])}</div>
        </div>
      ))}
    </div>
  );
}

export default IwdLegend;