import LeftArrow from 'assets/fisdom/ic_arrow_left.svg';
import './IwdSwitchAccountBar.scss';
import { ClickAwayListener } from 'material-ui';
import React, { useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import IwdSwitchAccount from '../IwdSwitchAccount/IwdSwitchAccount';
import { useUserContext } from '../../common/UserContext';
import { navigate as navigateFunc } from '../../common/commonFunctions';
import { useHistory } from 'react-router-dom';
import { nativeCallback } from '../../../utils/native_callback';
import isEmpty from 'lodash/isEmpty';
import SVG from "react-inlinesvg";
import { colorConfig } from '../../theme/colorConfig';

const getTextContent = (selectedChildUser, userDetails) => {
  if (!isEmpty(selectedChildUser)) {
    return {
      backCtrlText: "Back to Parent Account",
      infoText: <>You are currently in child account <b>"{selectedChildUser.name}"</b></>
    };
  }

  const userPan = userDetails.pan || "";
  return {
    backCtrlText: "Back to your dashboard",
    infoText: <>You are currently viewing account of <b>{userDetails.name} {userPan && `(${userPan})`}</b></>
  };
}

export default function IwdSwitchAccountBar(props) {
  let history = useHistory();
  const navigate = navigateFunc.bind({ history });
  const {
    selectedChildUser,
    updateSelectedChildUser,
    updateLinkedChildUsers,
    hasLinkedChildUsers,
    isChildUserSelected,
    userDetails,
    isWmLogin
  } = useUserContext();


  const {
    backCtrlText,
    infoText
  } = useMemo(() => (
    getTextContent(selectedChildUser, userDetails)
  ), [selectedChildUser, userDetails]);
  
  const sendEvents = () => {
    const eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: "family switch",
        "user_action": isChildUserSelected ? 'switch to parent' : 'back to your dashboard'
      }
    };

    nativeCallback({ events: eventObj });
  };

  const goBack = () => {
    sendEvents();
    if (isChildUserSelected) {
      updateSelectedChildUser(null);
      if (hasLinkedChildUsers) {
        navigate('main/family-dashboard');
      } else {
        navigate('main/dashboard');
      }
    } else if (isWmLogin) {
      navigate('wm-dashboard');
      updateLinkedChildUsers([]); // Clear child users list when exiting from any client in RM view
    }
  }

  return (
    <div id="iwd-switch-account-bar">
      <div id="iwd-swb-left" onClick={goBack}>
        <SVG
          preProcessor={(code) =>
            code.replace(/fill=".*?"/g, `fill=${colorConfig.primary.gold[600]}`)
          }
          src={LeftArrow}
          className={'iwd-swbl-back-icn'}
        />
        {/* <img src={LeftArrow} alt="back" /> */}
        {backCtrlText}
      </div>
      <div id="iwd-swb-right">
        <div id="iwd-swbr-text">{infoText}</div>
        {isChildUserSelected &&
          <div id="iwd-swbr-dropdown">
            <SwitchAccountDropdown />
          </div>
        }
      </div>
    </div>
  );
}

const SwitchAccountDropdown = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {/* TODO: Fix this transition */}
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
          <div id="iwd-switch-account-button" onClick={() => setExpanded(!expanded)}>
            Switch Account
            <SVG
              preProcessor={(code) =>
                code.replace(/fill=".*?"/g, `fill=${colorConfig.primary.gold[600]}`)
              }
              src={LeftArrow}
              className={'iwd-sab-left-icn'}
              alt='open'
            />
          </div>
          <CSSTransition
            in={expanded}
            unmountOnExit
            classNames='iwd-fadeFromLeft'
          >
            <div id="iwd-switch-account-dropdown">
              <IwdSwitchAccount showProfileIcon={false} />
            </div>
          </CSSTransition>
        </ClickAwayListener>
    </>
  );
}