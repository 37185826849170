import React from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { colorConfig } from '../../theme/colorConfig';
import { DEFAULT_FONT_FAMILY } from '../../common/constants';

const styles = {
  root: {
    width: 'auto',
    minWidth: 0,
    fontWeight: '400',
    '@media (max-width: 450px)': {
      color: colorConfig.primary.dark.brand
    }
  },
  wrapper: {
    width: 'auto',
    minWidth: 0
  },
  labelContainer: {
    padding: '5px'
  },
  selected: {
    fontWeight: '700',
  },
  label: {
    '@media (min-width: 450px)': {
      color: colorConfig.content.primary,
    },
    textTransform: 'none',
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'left',
  },
};
  
const CTab = ({
  ...props
}) => {
  return (
    <Tab {...props} />
  );
}

export default withStyles(styles)(CTab);
