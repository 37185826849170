import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isFunction from "lodash/isFunction";

const IwdPhoneInput = ({
  onEnterPressed,
  onKeyDown,
  ...props
}) => {

  const handleKeyDown = (event) => {
    var code = event.keyCode;
    if (code === 13 && isFunction(onEnterPressed)) {
      onEnterPressed();
    } else if (isFunction(onKeyDown)) {
      onKeyDown(event);
    }
  };

  return (
    <PhoneInput 
      country={'in'}
      autoFormat
      inputProps={{
        autoFocus: true
      }}
      placeholder="Mobile number"
      countryCodeEditable={false}
      enableSearch
      disableSearchIcon
      inputClass={`iwd-phone-input ${props.inputClass}`}
      buttonClass={`iwd-phone-btn ${props.buttonClass}`}
      searchClass={`iwd-pi-search ${props.buttonClass}`}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
}

export default IwdPhoneInput;