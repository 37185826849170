import React, { useContext, useState } from "react";
import Toast from "../../../common/ui/Toast";
import { nativeCallback } from "../../../utils/native_callback";
import { delinkAccount, sendLinkingReminderToChild } from "../../common/ApiCalls";
import { OutlinedButton } from "../../common/Button";
import { useUserContext } from "../../common/UserContext";
import ActionConfirmationDialog from "./ActionConfirmationDialog";
import { CHILD_REQUEST_DIALOG_CONTENT_MAP } from "./constants";
import { RelationshipRequestsContext } from './LinkedAccountsTable';

function ChildRequestActionButton({ data }) {
    const { status, relationship_request_id, relationship_user_name, pan_number } = data; 
    const { refreshRequestsList } = useContext(RelationshipRequestsContext);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [isApiRunning, setIsApiRunning] = useState(false);
    const [dialogContent, setDialogContent] = useState({});
    const { removeLinkedChildUser, isWmLogin } = useUserContext();

    const isLinked = status === 'linked';
    const isOnHold = status === 'onhold';

    const sendEvents = (user_action, props) => {
        let eventObj = {
          "event_name": 'internal dashboard hni',
          "properties": {
            "user_action": user_action,
            ...props
          }
        };
        nativeCallback({ events: eventObj });
      };

    const removeChildAccount = async () => {
        try {
            setIsApiRunning(true);
            await delinkAccount(relationship_request_id);
            await refreshRequestsList();
            removeLinkedChildUser(pan_number);
        } catch (err) {
            Toast(err);
            console.log(err);
        } finally {
            setIsApiRunning(false);
            toggleConfirmationDialog();
        }
    }
    
    const resendReminderToChildAccount = async () => {
        try {
            setIsApiRunning(true);
            await sendLinkingReminderToChild(relationship_request_id);
            Toast('Reminder sent successfully!');
        } catch (err) {
            Toast(err);
            console.log(err);
        } finally {
            toggleConfirmationDialog();
            setIsApiRunning(false);
        }
    }

    const triggerConfirmationDialog = () => {
        sendEvents(isLinked ? "delink" : "resend", {
            screen_name: "child account table"
        });
        if (isLinked) {
            setDialogContent(CHILD_REQUEST_DIALOG_CONTENT_MAP.removeAccount);
        } else {
            setDialogContent(CHILD_REQUEST_DIALOG_CONTENT_MAP.sendReminder);
        }
        toggleConfirmationDialog();
    }

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
    }

    const onPrimaryButtonClick = () => {
        if (isOnHold) {
            sendEvents("remind now", {
                screen_name: "send a reminder"
            });
            resendReminderToChildAccount();
        }
        else if (isLinked) {
            sendEvents("not now", {
                screen_name: "delink",
                landed_from: "child account table"
            });
            toggleConfirmationDialog();   
        }
    }
    
    const onSecondaryButtonClick = () => {
        if (isLinked) {
            sendEvents("remove", {
                screen_name: "delink",
                landed_from: "child account table"
            });
            removeChildAccount();
        }
        else if (isOnHold) {
            sendEvents("later", {
                screen_name: "send a reminder",
            });
            toggleConfirmationDialog();
        }
    }

    return(
        <>
            <div className='iwd-fv-button-container'>
                <OutlinedButton
                    onClick={triggerConfirmationDialog}
                    className='iwd-fv-button'
                    disabled={isWmLogin}
                >
                    {isLinked ? "Delink" : "Resend"}
                </OutlinedButton>
            </div>
            <ActionConfirmationDialog
                open={openConfirmationDialog}
                onClose={toggleConfirmationDialog}
                secondaryButtonProps={{
                    onClick: onSecondaryButtonClick,
                    title: dialogContent.secondaryButtonLabel,
                    isLoading: isLinked && isApiRunning
                }}
                primaryButtonProps={{
                    onClick: onPrimaryButtonClick,
                    title: dialogContent.primaryButtonLabel,
                    isLoading: isOnHold && isApiRunning
                }}
                dialogContent={dialogContent}
                contentFunctionParam={relationship_user_name}
            />
        </>
    );
}

export default ChildRequestActionButton;