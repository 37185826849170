import React, { useEffect, useState } from 'react';
import ProfileInfoCard from './ProfileInfoCard';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import TabContentContainer from './TabContentContainer.js';
import { useUserContext } from '../../common/UserContext';

const buildUserAddr = (addrObj) => {
  const props = ['addressline', 'district', 'city', 'pincode', 'state', 'country'];
  const address = pick(addrObj, props);
  const addrValues = compact(Object.values(address));
  if (!addrValues.length) return "";
  return addrValues.join(", ")?.toLowerCase();
}

export default function PersonalInfoTab() {
  const [data, setData] = useState({});
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [pageError, setPageError] = useState(false);

  const { userKycDetails: kyc = {} } = useUserContext();

  const initializeData = async () => {
    try {
      if (isEmpty(kyc)) {
        throw new Error("KYC not found");
      }
      setData({
        dob: get(kyc, 'pan.meta_data.dob', ''),
        tax_status: get(kyc, 'identification.meta_data.tax_status', ''),
        address: buildUserAddr(get(kyc, 'address.meta_data', '')),
        ucc: get(kyc, 'ucc', ''),
        boId: get(kyc, 'boid', ''),
        dpId: get(kyc, 'dpid', ''),
        dematActive: get(kyc, 'equity_investment_ready', ''),
        depositoryName: get(kyc, 'depository_name', ''),
        depositoryParticipant: get(kyc, 'depository_participant', '')
      });
      setPageError(false);
    } catch (err) {
      console.log(err);
      setPageError(true);
    } finally {
      setIsApiRunning(false);
    }
  }

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <TabContentContainer hasError={pageError} isLoading={isApiRunning}>
      <ProfileInfoCard
        headerText="Basic details"
        style={{ width: "50%" }}
        columnCount={2}
        rowCount={2}
        isLoading={isApiRunning}
      >
        <ProfileInfoCard.Item label="Date of birth">
          {data.dob}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Tax status">
          {TAX_STATUS_MAP[data.tax_status] || ""}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Address" style={{ gridColumn: "1 / 3", textTransform: "capitalize" }}>
          {data.address}
        </ProfileInfoCard.Item>
      </ProfileInfoCard>
      <ProfileInfoCard
        headerText="Demat account details"
        style={{ width: "50%" }}
        rowCount={2}
        columnCount={3}
        isLoading={isApiRunning}
        noData={!data.dematActive}
        noDataText="Oops! No data found. Please contact your wealth manager for more details."
      >
        <ProfileInfoCard.Item label="Unique Client Code">
          {data.ucc}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="BO ID">
          {data.boId}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="DP ID">
          {data.dpId}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Depository Name">
          {data.depositoryName}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Depository Participant" style={{ gridColumn: "2 / 4" }}>
          {data.depositoryParticipant}
        </ProfileInfoCard.Item>
      </ProfileInfoCard>
    </TabContentContainer>
  );
}

const TAX_STATUS_MAP = {
  "01": "Individual",
  "02": "On Behalf Of Minor",
  "03": "HUF",
  "04": "Company",
  "05": "AOP",
  "06": "Partnership Firm",
  "08": "Trust",
  "10": "Others",
  "12": "DFI",
  "21": "NRE",
  "23": "FII",
  "24": "NRO",
  "35": "Government Body",
  "40": "Local Authority",
  "47": "LLP",
};