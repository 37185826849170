/*
NOTE: Any changes to color config below must be replicated in colorConfig.scss as well
*/

export const colorConfig = {
  "primary": {
    "dark": {
      "brand": "#101828",
      "500": "#31384A",
      "400": "#4F566A"
    },
    "gold": {
      "brand": "#C1A866",
      "600": "#9A8652",
      "500": "#CDB985",
      "400": "#E0D4B3",
      "300": "#FAF2D8",
    }
  },
  "action": {
    "brand": "#C1A866",
    "600": "#9A8652",
  },
  "content": {
    "primary": "#101828",
    "secondary": "#404653",
    "tertiary": "#70747E",
  },
  "secondary": {
    "beige": {
      "300": "#D7D4CC",
      "200": "#E7E5E0",
      "100": "#EFEEEB",
    },
    "alto": {
      "300": "#CCC1BE",
      "200": "#DBD4D2",
    },
    "green": "#60B277",
    "rose": {
      "200": "#EA878F",
    },
    "red": "#D14E58",
    "slateGrey": {
      "300": "#6F7F9D",
      "200": "#9AA5BA",
    },
    "orange": {
      "300": "#FFA055",
      "200": "#FFBD88",
    },
    "salmon": {
      "300": "#FC8565",
      "200": "#FDAA93",
    },
    "moonstone": {
      "300": "#49A6BD",
      "200": "#80C1D1",
    },
    "silverLake": {
      "300": "#5E87D3",
      "200": "#8EABE0"
    }
  },
  "supporting": {
    "linkWater": "#C3C5C7",
    "solitude": "#E7E8EA",
    "seashell": "#FBFAF9",
    "white": "#FFFFFF",
    "brandStroke": "#EFEEEB"
  }
};