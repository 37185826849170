export const categories = {
    'Mutual Fund': {
        icon: "mutual_fund.svg",
        product: "Mutual Fund"
    },
    'Digital Gold': {
        icon: "gold.svg",
        product: "Digital Gold"
    },
    'Insurance': {
        icon: "term_insurance.svg",
        product: "Insurance"
    },
    'National Pension Scheme': {
        icon: "nps.svg",
        product: "National Pension Scheme"
    },
    'Lending': {
        icon: "loan_icon.svg",
        product: "Lending"
    },
    'Account': {
        icon: "account1.svg",
        product: "Account"
    },
    'Payments': {
        icon: "payment_transaction.svg",
        product: "Payments"
    },
    'Initial public offering (IPO)': {
        icon: "ipo_transparent.svg",
        product: "Stock Broking"
    },
    'Follow on public offer (FPO)': {
        icon: "fpo_transparent.svg",
        product: "Stock Broking"
    },
    'Sovereign gold bond (SGB)': {
        icon: "sgb_transparent.svg",
        product: "Stock Broking"
    },
    'Buyback': {
        icon: "buyback_transparent.svg",
        product: "Stock Broking"
    },
    'Non convertible debentures (NCD)': {
        icon: "ncd_transparent.svg",
        product: "Stock Broking"
    },
    'Equity and Derivatives': {
        icon: "equity_derivatives_transparent.svg",
        product: "Stock Broking"
    },
}

export const TotalSize = 15000000;