import React from 'react';
import { ResponsivePie } from '@nivo/pie'

export default function IwdPieChart({ data }) {
  return (
    <div style={{ height: '200px', width: '200px' }}>
      <ResponsivePie
        data={data}
        innerRadius={0.65}
        activeInnerRadiusOffset={3}
        activeOuterRadiusOffset={3}
        colors={{ datum: 'data.color' }}
        borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
        enableArcLabels={false}
        enableSliceLabels={false}
        enableRadialLabels={false}
        transitionMode="startAngle"
        legends={[]}
      />
    </div>
  );
}