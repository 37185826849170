import attention_sign from "../../../assets/attention_sign.svg";
import React from 'react';
import ChildRequestActionButton from './ChildRequestActionButton';
import ParentRequestActionButton from "./ParentRequestActionButton";
import { PRODUCT_TYPE_KEY_MAP } from "../../mini-components/IwdProductTypeSelector/constants";
import { format } from 'date-fns';
import { colorConfig } from "../../theme/colorConfig";

export const LINKED_ACCOUNT_TYPES = {
    'child': {
        key: 'child',
        title: 'Family members whose portfolios you can view'
    },
    'parent':{
        key: 'parent',
        title: 'Family members who can view your portfolio '
    }
}

export const CHILD_REQUEST_DIALOG_CONTENT_MAP = {
    removeAccount: {
        content: name => `Would you like to remove ${name}'s account from your list of linked accounts?`,
        secondaryButtonLabel: 'Remove',
        primaryButtonLabel: 'Not Now',
        image: attention_sign
    },
    sendReminder: {
        content: name => `Send a reminder to ${name} to accept your linking request`,
        secondaryButtonLabel: 'Later',
        primaryButtonLabel: 'Remind now',
        image: attention_sign
    }
}

export const PARENT_REQUEST_DIALOG_CONTENT_MAP = {
    removeAccount: {
        content: name => `Would you like to remove ${name}'s account from your list of linked accounts?`,
        secondaryButtonLabel: 'Remove',
        primaryButtonLabel: 'Not Now',
        image: attention_sign
    },
    acceptRequest: {
        content: name => `Would you like to give access to ${name} to track & manage your investments?`,
        secondaryButtonLabel: 'Not Now',
        primaryButtonLabel: 'Give access',
        image: attention_sign
    },
    rejectRequest: {
        content: name => `Would you like to reject ${name}’s account linking request?`,
        secondaryButtonLabel: 'Not Now',
        primaryButtonLabel: 'Reject',
        image: attention_sign
    },
}

const LINKING_STATUS_MAP = {
    linked: {
        label: 'Accepted',
        color: colorConfig.secondary.green
    },
    onhold: {
        label: 'Pending',
        color: colorConfig.secondary.red
    }
}

function getNameElement(type){
   return <div className='iwd-table-name-container'>
        <div className='iwd-tnc-icon'>
            <div>
                {type.relationship_user_name?.[0]}
            </div>
        </div>
        <div>
            <div className='iwd-tnc-info1'>NAME</div>
        <div className='iwd-tnc-info2'>{type.relationship_user_name}</div>
        </div>
    </div>
}

function getRelationElement(type){
    return <div className='iwd-relation-container'>
        <div className='iwd-relation-info1'>Relation</div>
        <div className='iwd-relation-info2'>{type.relation}</div>
    </div>
}

function getPanElement(type){
    return <div className='iwd-pan-container'>
        <div className='iwd-pan-info1'>PAN</div>
        <div className='iwd-pan-info2'>{type.pan_number}</div>
    </div>
}

function getStatusElement({ status }){
    return <div className='iwd-status-container'>
        <div className='iwd-status-info1'>STATUS</div>
        <div className='iwd-status-info2' style={{ color: LINKING_STATUS_MAP[status]?.color }}>
            {LINKING_STATUS_MAP[status].label}
        </div>
    </div>
}

function getDateElement({ linking_date }){
    return <div className='iwd-date-container'>
        <div className='iwd-date-info1'>LINKED DATE</div>
        <div className='iwd-date-info2'>
            {linking_date
                ? format(new Date(linking_date), 'dd/MM/yyyy')
                : '---'
            }</div>
    </div>
}

export const CHILD_REQUESTS_TABLE_HEADERS_MAP = [
    {
        label: "",
        formatter: (type) => {
            return getNameElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getRelationElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getPanElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getStatusElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getDateElement(type);
        }
    },
    {
        label: "",
        formatter: (data) => {
            return <ChildRequestActionButton data={data}/>
        }
    },
]

export const PARENT_REQUESTS_TABLE_HEADERS_MAP = [
    {
        label: "",
        formatter: (type) => {
            return getNameElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getRelationElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getPanElement(type);
        }
    },
    {
        label: "",
        formatter: (type) => {
            return getDateElement(type);
        }
    },
    {
        label: "",
        formatter: (data) => {
            return <ParentRequestActionButton data={data}/>
        }
    },
]

export const ACCOUNT_PRODUCT_TYPE_OPTIONS = [{
    label: 'Mutual funds',
    value: PRODUCT_TYPE_KEY_MAP.mf
}, {
    label: 'Broking',
    value: PRODUCT_TYPE_KEY_MAP.broking
}];