import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import "../common/theme/Style.scss";
import "./common/Style.scss";
import "./pages/Style.scss";
import "./mini-components/Style.scss";
import Login from "./pages/Login";
import Main from "./pages/Main";
import { UserProvider } from "./common/UserContext";
import ConsentFormPage from "./pages/MyAccount/ConsentFormPage";
import SalesView from "./pages/SalesView/SalesView";
import NotFound from "./pages/NotFound";

const InternalWealthDashboard = (props) => {
  const { url } = props.match;

  return (
    <Fragment>
      <UserProvider>
        <Switch>
          <Route path={`${url}/family-view-tnc`} component={ConsentFormPage} />
          {/* <Route exact path={`${url}`} component={Login} /> */}
          <Route exact path={`${url}/login/:view?`} component={Login} />
          <Route path={`${url}/main/:tab`} component={Main} />
          <Route path={`${url}/wm-dashboard`} component={SalesView} />
          <Route path={`${url}/not-found`} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </UserProvider>
    </Fragment>
  );
};

export default InternalWealthDashboard;
