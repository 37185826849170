import React, { useEffect, useState } from 'react';
import ProductTypeSelectorDesktop from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorDesktop';
import LeftPanel from '../../mini-components/LeftPanel';
import { ACCOUNT_PRODUCT_TYPE_OPTIONS } from './constants';
import ProfileInfoCard from './ProfileInfoCard';
import { chunk } from 'lodash';
import { getConfig } from 'utils/functions';
import IwdCarousel, { IwdCarouselCardViewContainer } from '../../mini-components/IwdCarousel/IwdCarousel';
import ProductTypeSelectorMobile from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorMobile';
import { getNomineeDetails } from '../../common/ApiCalls';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';
import get from 'lodash/get';
import IwdScreenLoader from '../../mini-components/IwdScreenLoader';
import TabContentContainer from './TabContentContainer.js';
import isEmpty from 'lodash/isEmpty';
import InternalStorage from '../../InternalStorage';

const isMobileView = getConfig().isMobileDevice;

const getMfNomineesFromRes = (res) => {
  if (res?.mutual_fund_nomination?.nominee_optional) return []; // User skipped nominee entry
  
  const nomineeData = res?.mutual_fund_nomination?.meta_data;
  return [{
    name: nomineeData.name,
    relation: nomineeData.relationship,
    dob: nomineeData.dob
  }];
};
const getBrokingNomineesFromRes = (res) => {
  const list = get(res, 'equity_nomination.eq_nominee_list', []);
  return list
    .map(nominee => get(nominee, 'group_data.group_meta.nominee_meta', {}))
    .reduce((arr, nominee) => {
      arr.push({
        name: nominee.full_name,
        relation: nominee.relation,
        dob: nominee.dob
      });
      return arr;
    }, []);
};

export default function NomineeDetailsTab() {
  const [selectedProductType, setSelectedProductType] = useState(PRODUCT_TYPE_KEY_MAP.mf);
  const [nomineeListMap, setNomineeListMap] = useState({});
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [pageError, setPageError] = useState(false);

  const onProductTypeChange = (value) => {
    setSelectedProductType(value);
  }

  const fetchNomineeDetails = async () => {
    try {
      let res = InternalStorage.getData('nomineeDetails');
      if (isEmpty(res)) {
        setIsApiRunning(true);
        res = await getNomineeDetails();
        InternalStorage.setData('nomineeDetails', res);
      }
      setNomineeListMap({
        [PRODUCT_TYPE_KEY_MAP.mf]: getMfNomineesFromRes(res),
        [PRODUCT_TYPE_KEY_MAP.broking]: getBrokingNomineesFromRes(res)
      });
      setPageError(false);
    } catch (err) {
      console.log(err);
      setPageError(true);
    } finally {
      setIsApiRunning(false);
    }
  }

  useEffect(() => {
    fetchNomineeDetails();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: isMobileView ? "column" : "row" }}>
      <LeftPanel className="iwd-bd-left-panel">
        <ProductTypeSelectorDesktop
          value={selectedProductType}
          onChange={onProductTypeChange}
          disabled={isApiRunning}
          productOptions={ACCOUNT_PRODUCT_TYPE_OPTIONS}
        />
      </LeftPanel>
      <ProductTypeSelectorMobile
        value={selectedProductType}
        onChange={onProductTypeChange}
        className="iwd-bd-product-select-mobile"
        disabled={isApiRunning}
        variant="outlined"
        productOptions={ACCOUNT_PRODUCT_TYPE_OPTIONS}
      />
      <TabContentContainer
        isLoading={isApiRunning}
        noData={isEmpty(nomineeListMap[selectedProductType])}
        hasError={pageError}
      >
        {isApiRunning
          ? <IwdScreenLoader loadingText="Loading data..." />
          : <CardView data={nomineeListMap[selectedProductType]} key={selectedProductType} />
        }
      </TabContentContainer>
    </div>
  );
}

const CardView = React.memo(({
  data = []
}) => {
  const chunkedList = chunk(data, 2);

  const ContainerComponent = isMobileView ? React.Fragment : IwdCarousel;

  return (
    <ContainerComponent>
      {chunkedList.map(([nominee1, nominee2], idx) => (
        <IwdCarouselCardViewContainer>
          {nominee1 && <NomineeCard nominee={nominee1} index={idx * 2 + 1} />}
          {nominee2 && <NomineeCard nominee={nominee2} index={idx * 2 + 2} />}
        </IwdCarouselCardViewContainer>
      ))}
    </ContainerComponent>
  );
});

const NomineeCard = ({ nominee, index }) => (
  <ProfileInfoCard
    headerText={`Nominee ${index}`}
    style={{ width: "50%" }}
    columnCount={2}
    rowCount={2}
  >
    <ProfileInfoCard.Item label="Name">
      {nominee.name}
    </ProfileInfoCard.Item>
    <ProfileInfoCard.Item label="Relation">
      {nominee.relation}
    </ProfileInfoCard.Item>
    <ProfileInfoCard.Item label="Date of birth">
      {nominee.dob}
    </ProfileInfoCard.Item>
  </ProfileInfoCard>
);