import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { nativeCallback } from "../../../utils/native_callback";
import { acceptOrRejectParentRequest, delinkAccount } from "../../common/ApiCalls";
import { OutlinedButton, TextButton } from "../../common/Button";
import ActionConfirmationDialog from "./ActionConfirmationDialog";
import { PARENT_REQUEST_DIALOG_CONTENT_MAP } from "./constants";
import { RelationshipRequestsContext } from './LinkedAccountsTable';
import Checkbox from '@material-ui/core/Checkbox';
import { useUserContext } from "../../common/UserContext";

const REQ_RESPONSE_TYPE_MAP = {
    'accept': 'accept',
    'reject': 'reject'
};

function ParentRequestActionButton({ data }) {
    const { status, relationship_request_id, relationship_user_name } = data; 
    const { refreshRequestsList } = useContext(RelationshipRequestsContext);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({});
    const [requestResponseType, setRequestResponseType] = useState('');
    const [isApiRunning, setIsApiRunning] = useState(false);
    const [tncChecked, setTncChecked] = useState(false);
    const { isWmLogin } = useUserContext();

    const isLinked = status === "linked";
    const isOnhold = status === 'onhold';

    const sendEvents = (user_action, props) => {
        let eventObj = {
          "event_name": 'internal dashboard hni',
          "properties": {
            "user_action": user_action,
            ...props
          }
        };
        nativeCallback({ events: eventObj });
      };

    const removeChildAccount = async () => {
        try {
            setIsApiRunning(true);
            await delinkAccount(relationship_request_id);
            await refreshRequestsList();
        } catch (err) {
            // TODO: Handle errors appropriately
            console.log(err);
        } finally {
            setIsApiRunning(false);
            toggleConfirmationDialog();
        }
    }
    
    const respondToParentRequest = async () => {
        try {
            setIsApiRunning(true);
            await acceptOrRejectParentRequest(
                relationship_request_id,
                requestResponseType === REQ_RESPONSE_TYPE_MAP.accept
            );
            await refreshRequestsList();
        } catch (err) {
            // TODO: Handle errors appropriately
            console.log(err);
        } finally {
            setIsApiRunning(true);
            toggleConfirmationDialog();
        }
    }

    const handleTncCheckbox = (event) => {
        setTncChecked(event.target.checked);
    }

    const triggerConfirmationDialog = (responseType) => () => {
        if (isLinked) {
            sendEvents("delink", {
                screen_name: "parent account table"
            })
            setDialogContent(PARENT_REQUEST_DIALOG_CONTENT_MAP.removeAccount);
        } else {
            sendEvents(responseType === REQ_RESPONSE_TYPE_MAP.accept ? "accept" : "reject", {
                screen_name: "parent account table"
            })
            setRequestResponseType(responseType);
            const dialogContentType = responseType === REQ_RESPONSE_TYPE_MAP.accept ? 'acceptRequest' : 'rejectRequest'
            setDialogContent(PARENT_REQUEST_DIALOG_CONTENT_MAP[dialogContentType]);
        }
        toggleConfirmationDialog();
    }

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
        setTncChecked(false);
    }

    const onPrimaryButtonClick = () => {
        if (isOnhold) respondToParentRequest();
        else if (isLinked){
            sendEvents("not now", {
                screen_name: "delink",
                landed_from: "parent account table"
            });
            toggleConfirmationDialog();
        } 
    }

    const onSecondaryButtonClick = () => {
        if (isLinked){
            sendEvents("remove", {
                screen_name: "delink",
                landed_from: "parent account table"
            });
            removeChildAccount();
        } 
        else if (isOnhold) toggleConfirmationDialog();
    }

    return(
        <>
            <div className='iwd-fv-button-container'>
                {isOnhold &&
                    <TextButton
                        className="iwd-fv-button"
                        onClick={triggerConfirmationDialog(REQ_RESPONSE_TYPE_MAP.reject)}
                        disabled={isWmLogin}
                    >
                        Reject
                    </TextButton>
                }
                <OutlinedButton
                    className="iwd-fv-button"
                    onClick={triggerConfirmationDialog( isLinked ? '' : REQ_RESPONSE_TYPE_MAP.accept)}
                    disabled={isWmLogin}
                >
                    { isLinked ? "Delink" : "Accept"}
                </OutlinedButton>
            </div>
            <ActionConfirmationDialog
                open={openConfirmationDialog}
                onClose={toggleConfirmationDialog}
                secondaryButtonProps={{
                    onClick: onSecondaryButtonClick,
                    title: dialogContent.secondaryButtonLabel,
                    isLoading: isLinked && isApiRunning
                }}
                primaryButtonProps={{
                    onClick: onPrimaryButtonClick,
                    title: dialogContent.primaryButtonLabel,
                    isLoading: isOnhold && isApiRunning,
                    disabled: (requestResponseType === REQ_RESPONSE_TYPE_MAP.accept) && !tncChecked
                }}
                dialogContent={dialogContent}
                contentFunctionParam={relationship_user_name}
            >
                {requestResponseType === REQ_RESPONSE_TYPE_MAP.accept &&
                    <div className="iwd-fv-tnc">
                        <Checkbox
                            classes={{ root: "iwd-fv-tnc-checkbox" }}
                            checked={tncChecked}
                            onChange={handleTncCheckbox}
                            inputProps={{ 'aria-label': 'I agree with Terms and conditions' }}
                        />
                        I agree with&nbsp;
                        <Link 
                            to={`/iw-dashboard/family-view-tnc`}
                            target="_blank"
                        >
                            Terms and conditions
                        </Link>
                    </div>
                }
            </ActionConfirmationDialog>
        </>
    );
}

export default ParentRequestActionButton;