exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}table.iwd-table{width:100% !important}table.iwd-table thead{width:100%}table.iwd-table thead tr{background:rgba(154,134,82,.06);height:40px}table.iwd-table thead tr td{border-bottom:none !important;height:40px;font-weight:bold;font-size:11px;line-height:21px;letter-spacing:1px;text-transform:uppercase;padding-top:10px;padding-bottom:10px;padding-left:50px}table.iwd-table tbody tr{background:rgba(255,255,255,.06)}table.iwd-table tbody tr:nth-child(1)>td{border-top:none !important}table.iwd-table tbody tr:nth-child(2n){background:rgba(154,134,82,.06)}table.iwd-table tbody tr td{height:50px;border-top:none !important;padding-left:50px}table.iwd-table td{padding:10px 15px;border-collapse:collapse;font-size:13px;line-height:21px;color:#70747e;border:none;white-space:nowrap}table.iwd-table td:nth-child(1){padding-left:50px}table.iwd-table td:nth-last-child(1){padding-right:50px}@media only screen and (max-width: 450px){.iwd-table{width:800px !important}}", ""]);

// exports
