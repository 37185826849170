import React from "react";
import IconButton from "material-ui/IconButton";
import close_icn from "assets/close_icn.svg";
import StatusIndicator from "./StatusIndicator";
import useBackNavigation from "./useBackNavigation";
import "./styles.scss";

const Header = ({ showStatus = false, iconStyle = {} }) => {
  const { historyGoBack } = useBackNavigation();

  return (
    <div className="finity-upgrade-header">
      <IconButton onClick={historyGoBack} style={iconStyle}>
        <img src={close_icn} alt="close" />
      </IconButton>
      {showStatus && (
        <div className="fuc-status">
          <StatusIndicator />
        </div>
      )}
    </div>
  );
};

export default Header;
