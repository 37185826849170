const HEADER = {
  title: {
    text: "Update: Migrate from Finity to Fisdom app",
    dataAid: "title",
  },
  subtitle: {
    text: `At Fisdom and Finity, we're constantly seeking ways to enhance your investing journey, making it smoother, faster, and more intuitive. Today, We are excited to invite you to migrate your trading and demat account from Finity to the Fisdom app.`,
    dataAid: "title",
  },
};

const FAQS = {
  title: "Frequently Asked Questions",
  content: [
    {
      question: "Why are we migrating from Finity to Fisdom?",
      answer:
        "We are migrating to Fisdom to offer you an enhanced stock trading and investing experience with advanced features, faster transactions, and a more user-friendly interface.",
    },
    {
      question:
        "Do I have to open another demat and trading account after migration?",
      answer: "No, your demat and trading account remains the same on Fisdom as on Finity. You don’t need to open a new account.",
    },
    {
      question: "What happens to my mutual funds on Finity?",
      answer: "Your mutual funds will remain on Finity. You can continue to monitor, track, invest, and withdraw your funds as usual.",
    },
    {
      question: "Are there any fees for migrating or not migrating?",
      answer: "There are no additional fees for migrating to Fisdom.",
    },
    {
      question: "How will the migration affect my account and investments?",
      answer: "The migration process is designed to be seamless, with no negative impact on your investments. Your account details, including your investment portfolio, will remain the same.",
    },
    {
      question: "What features does Fisdom offer that Finity doesn’t?",
      answer: "Fisdom offers several advanced features, including a self-service portal, lightning-fast option chain, enhanced app speed, direct debit instruction (DDPI) for selling stocks, stock pledging for additional margins, ‘Trade ideas’ section, fully digital F&O and currency trading activation and much more",
    },
    {
      question: "How do I access Fisdom once my account is migrated?",
      answer: "Simply download the Fisdom app from playstore or appstore, and use your existing Finity login credentials to login. You will see all your holdings and positions reflected on the Fisdom app.",
    },
    {
      question:
        "Who can I contact for more information or if I need assistance?",
      answer: "Please reach out to our customer support team at ask@finity.in for any questions or assistance. We’re here to help make this transition as smooth as possible for you.",
    },
    {
      question:
        "Are the fees and charges different on Fisdom as compared to Finity?",
      answer: "No, you will be on exactly the same pricing plan as you are on Finity. There will be no difference.",
    },
  ],
};

const TNC_NOTE = {
  title:
    "I agree to the terms and conditions and give my consent to migrate my demat trading account to fisdom app.",
};

const WHY_MAKE_MOVE = {
  title: "Why make the move?",
  content: [
    "Both Fisdom and Finity operate under SEBI Registration No. INZ000209036, SEBI Depository Participant No. IN-DP-572-2021 and SEBI Research Analyst No. INH000010238.",
    "Both the apps are owned and managed by Finwizard Technology Private Limited. While both Finity and Fisdom offer stock broking under the same license, the Fisdom experience is much superior and we invite you to move to Fisdom.",
    "Once you consent to migrate, you do not have to do anything else. Your Fisdom app will reflect all your holdings, positions and will carry the same pricing plan with much superior features. Just download the Fisdom app and start using the same mobile number as Finity - everything will remain the same.",
  ],
};

const ENHANCED_FEATURES = {
  title: "Enhanced features on Fisdom app",
  content: {
    title: `Here's a glimpse of what awaits you on Fisdom:`,
    subtexts: [
      "Enhanced App Speed: Experience a faster, more responsive app, ensuring your trades and investments are executed without delay",
      "Self-Service Portal: Effortlessly update your email, mobile number, bank details, DDPI, and nominee changes at your convenience.",
      "Lightning-Fast Option Chain: Enjoy an integrated view of LTP, OI, and OI bars, among other vital metrics, all at your fingertips.",
      "Direct Debit Instruction (DDPI): Benefit from one-time approval for selling stocks, eliminating the need for T-PIN delays.",
      "Stock Pledging: Pledge your stock holdings for additional margins, completely paperless and hassle-free.",
      "Trade Ideas Section: Access expert buy and sell recommendations to inform your trading decisions.",
      "Digital F&O and Currency Trading Activation: Activate your F&O and currency trading capabilities digitally, with no paperwork required.",
      "And so much more",
    ],
  },
};

const MF_ON_FINITY = {
  title: "Mutual funds on Finity",
  subtext: `We're pleased to inform you that your mutual funds will continue to be accessible on the Finity app. You can monitor, track, invest, and withdraw your funds with the same ease and convenience. This migration ensures that you enjoy the best of both worlds – advanced stock trading on Fisdom and comprehensive mutual fund management on Finity.`,
};

const FOOTER = {
  title: "MIGRATE NOW",
};

const STATUS_INDICATOR = {
  title: "Important update",
};

const REQUEST_SUBMISSION = {
  title: "Request submitted",
  subtitle: "You will be notified when the migration is done",
  btnTitle: "OKAY",
};

const ACCORDIAN_KEY_MAPPER = {
  WHY_MAKE_MOVE: "why_make_move",
  ENHANCED_FEATURES: "enchanced_feature",
  MF_FINITY: "mf_finity",
  FAQ: "faq",
};

const FINITY_UPGRADE = {
  HEADER,
  FAQS,
  TNC_NOTE,
  WHY_MAKE_MOVE,
  ENHANCED_FEATURES,
  MF_ON_FINITY,
  FOOTER,
  STATUS_INDICATOR,
  REQUEST_SUBMISSION,
  ACCORDIAN_KEY_MAPPER,
};

export default FINITY_UPGRADE;
