exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/montserrat/latin.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-400.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-500.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/index.js!@fontsource/playfair-display/latin-700.css"), "");

// module
exports.push([module.id, ".iwd-purple-card{text-align:left;align-self:flex-start;background:#101828;width:50%;height:auto;padding:34px 50px;padding-right:30px;border-radius:8px 8px 60px 8px}.iwd-purple-card .MuiCircularProgress-colorPrimary{color:#fff}.iwd-purple-card .iwd-card-header{color:#fff;width:85%;float:left}.iwd-purple-card .fisdom-ecb-error-title,.iwd-purple-card .fisdom-ecb-error-text{color:#fff}.iwd-hovering-btn{display:none}.iwd-field-label{font-weight:600;font-size:11px;line-height:18px;letter-spacing:2px;text-transform:uppercase;color:#70747e}@media only screen and (min-width: 300px)and (max-width: 1200px){.iwd-purple-card{width:100%;height:auto;padding:30px 40px;background:#101828;text-align:left;border-bottom-right-radius:70px}.iwd-purple-card .iwd-card-header{text-align:left;width:100%;float:none}.iwd-hovering-btn{position:fixed;z-index:2;bottom:15%;right:30px;display:block;width:60px;height:60px}}.iwd-report-download-desktop{display:flex;align-items:center}.iwd-report-download-desktop .iwd-rd-title{font-size:13px;font-weight:700;letter-spacing:1px;text-align:center;text-transform:uppercase;color:#70747e;cursor:default;margin-left:10px}.iwd-report-download-desktop .iwd-rd-icon-button{width:30px !important;height:30px !important;background-color:#efeeeb !important;border-radius:6px !important;margin-left:10px !important}.iwd-report-download-mobile{display:none !important}@media only screen and (max-width: 450px){.iwd-report-download-desktop{display:none}.iwd-report-download-mobile{background-color:#faf2d8 !important;height:26px !important;width:60px !important;border-radius:100px !important;border:1px solid #e0d4b3 !important;display:block !important}}", ""]);

// exports
