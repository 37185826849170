import { useEffect, useState } from 'react';
import { TABS_LIST, TABS_LIST_PATH_MAP } from '../../common/constants';
import { useUserContext } from '../../common/UserContext';

export default function useFilteredTabs () {
  const { selectedChildUser, isChildUserSelected, hasLinkedChildUsers } = useUserContext();
  const [filteredTabs, setFilteredTabs] = useState([]);

  useEffect(() => {
    let exclusionList = [];
    if (isChildUserSelected) {
      exclusionList = [TABS_LIST_PATH_MAP.familyDashboard, TABS_LIST_PATH_MAP.myAccount];
    } else if (!hasLinkedChildUsers) {
      exclusionList = [TABS_LIST_PATH_MAP.familyDashboard];
    }

    if (exclusionList.length) {
      setFilteredTabs(TABS_LIST.filter(tab => !exclusionList.includes(tab.path)));
    } else {
      setFilteredTabs(TABS_LIST);
    }
  }, [selectedChildUser, isChildUserSelected]);

  return [filteredTabs];
}