// ------------------ Assets ---------------------
import fisdom_hni_logo from 'assets/fisdom/fisdom_hni_logo_gold.svg';
import fisdom_icon_gold from 'assets/fisdom/fisdom_icon_gold.svg';
// ------------------------------------------------
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { nativeCallback } from '../../utils/native_callback';
import useFilteredTabs from '../pages/MyAccount/useFilteredTabs';

const NavBar = (props) => {
  const { match: { params } } = props;
  const [open, setOpen] = useState(false);
  const [tabsToShow] = useFilteredTabs(); 

  const sendEvents = (tabName) => () => {
    const eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        user_action: 'tab clicked',
        tab: tabName
      }
    };

    nativeCallback({ events: eventObj });
  };

  return (
    <div
      id="iwd-nav"
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Link to={`dashboard${props.location.search}`} className='iwd-nav-logo'>
        {open ? FullLogo : IconLogo}
      </Link>
      {tabsToShow.map(({ label, path, icon, selectedIcon }) =>
        <Link to={`${path}${props.location.search}`} key={label} onClick={sendEvents(label)}>
          <div
            className={`
              iwd-nav-item
              ${params.tab === path ? 'selected' : ''}
            `}>
            <img className="iwd-ni-img" src={params.tab === path ? selectedIcon : icon} alt={label} />
            <span className="iwd-ni-label">{label}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default withRouter(NavBar);

const FullLogo = (
  <img
    src={fisdom_hni_logo}
    alt="fisdom"
    width={138}
    height={48}
  />
);

const IconLogo = (
  <img
    src={fisdom_icon_gold}
    alt="fisdom"
  />
);