import React, { useEffect, useState } from 'react';
import ProfileInfoCard from './ProfileInfoCard';
import startCase from 'lodash/startCase';
import { getWMDetails } from '../../common/ApiCalls';
import pick from 'lodash/pick';
import TabContentContainer from './TabContentContainer.js';
import isEmpty from 'lodash/isEmpty';
import InternalStorage from '../../InternalStorage';
import { INTERNAL_STORAGE_CONSTANTS } from '../../common/constants';

export default function WealthManagerDetailsTab() {
  const [wmDetail, setWmDetail] = useState({});
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [pageError, setPageError] = useState(false);

  const fetchWMDetails = async () => {
    try {
      const storedWmDetails = InternalStorage.getData(INTERNAL_STORAGE_CONSTANTS.wmDetails);

      let res;
      if (isEmpty(storedWmDetails)) {
        setIsApiRunning(true);
        res = await getWMDetails();
        InternalStorage.setData(INTERNAL_STORAGE_CONSTANTS.wmDetails, res);
      } else {
        res = storedWmDetails;
      }
      const userObj = pick(
        res.rm_details,
        ['name', 'mob_no', 'email', 'designation', 'city', 'state']
      );
      setWmDetail(userObj);
      setPageError(false);
    } catch (err) {
      console.log(err);
      if (err === "No RM mapped to the user") {
        setWmDetail({});
      } else {
        setPageError(true);
      }
    } finally {
      setIsApiRunning(false);
    }
  }

  useEffect(() => {
    fetchWMDetails();
  }, []);
  
  return (
    <TabContentContainer
      isLoading={isApiRunning}
      noData={isEmpty(wmDetail)}
      hasError={pageError}
    >
      <ProfileInfoCard
        headerText="Basic details"
        style={{ width: "30%" }}
        columnCount={1}
        rowCount={2}
        isLoading={isApiRunning}
      >
        <ProfileInfoCard.Item label="Name">
          {wmDetail.name}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Designation">
          {startCase(wmDetail.designation)}
        </ProfileInfoCard.Item>
      </ProfileInfoCard>
      <ProfileInfoCard
        headerText="Contact details"
        style={{ width: "50%" }}
        rowCount={2}
        columnCount={2}
        isLoading={isApiRunning}
      >
        <ProfileInfoCard.Item label="Phone number">
          {wmDetail.number}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="Email ID" lowercase>
          {wmDetail.email}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="City">
          {wmDetail.city}
        </ProfileInfoCard.Item>
        <ProfileInfoCard.Item label="State">
          {wmDetail.state}
        </ProfileInfoCard.Item>
      </ProfileInfoCard>
    </TabContentContainer>
  );
}