export const PRODUCT_TYPE_KEY_MAP = {
    mf: 'mf',
    pms: 'pms',
    aif: 'aif',
    bond: 'bond',
    unlistedShares: 'unlisted-share',
    broking: 'broking'
}

export const PRODUCT_TYPE_OPTIONS = [{
    label: 'Mutual funds',
    value: PRODUCT_TYPE_KEY_MAP.mf
  }, {
    label: 'PMS',
    value: PRODUCT_TYPE_KEY_MAP.pms
  }, {
    label: 'AIF',
    value: PRODUCT_TYPE_KEY_MAP.aif
  }, {
    label: 'Bonds',
    value: PRODUCT_TYPE_KEY_MAP.bond
  },{
    label: 'Unlisted shares',
    value: PRODUCT_TYPE_KEY_MAP.unlistedShares
  }];

  export const STATEMENT_PRODUCT_TYPE_OPTIONS = [{
    label: 'Mutual funds',
    value: PRODUCT_TYPE_KEY_MAP.mf
  }, {
    label: 'PMS',
    value: PRODUCT_TYPE_KEY_MAP.pms
  }, {
    label: 'AIF',
    value: PRODUCT_TYPE_KEY_MAP.aif
  }];