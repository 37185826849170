import { PRODUCT_TYPE_KEY_MAP } from "../../mini-components/IwdProductTypeSelector/constants";

export const formatHoldingsForProduct = (holdings, product) => {

  switch (product) {
    case PRODUCT_TYPE_KEY_MAP.mf:
      return formatMFHoldings(holdings, product);
    case PRODUCT_TYPE_KEY_MAP.pms:
      return formatPMSHoldings(holdings, product);
    case PRODUCT_TYPE_KEY_MAP.aif:
      return formatAIFHoldings(holdings, product);
    case PRODUCT_TYPE_KEY_MAP.bond:
      return formatBondHoldings(holdings,product);     
    case PRODUCT_TYPE_KEY_MAP.unlistedShares:
      return formatUSHoldings(holdings, product);
    default:
      return []
  }
}

const formatMFHoldings = (holdings, product) => {
  return holdings.map(holding => ({
    productType: product,
    logo: holding.mf?.amc_logo_zoomed,
    name: holding.mf?.name,
    fisdom_rating: holding.mf?.fisdom_rating,
    isin: holding.mf?.isin,
    invested_since: holding.invested_since,
    scheme_type: holding.scheme_type,
    current_value: holding.current,
    invested_value: holding.current_invested,
    xirr: holding.current_earnings?.percent
  }));
}

const formatPMSHoldings = (holdings, product) => {
  return holdings.map(holding => ({
    productType: product,
    logo: holding.scheme_data?.logo,
    name: holding.scheme_data?.scheme_name,
    ...holding
  }));
}

const formatAIFHoldings = (holdings, product) => {
  return holdings.map(holding => ({
    productType: product,
    logo: holding.scheme_data?.logo,
    name: holding.scheme_data?.scheme_name,
    scheme_type: holding.scheme_data?.product_category,
    ...holding
  }));
}

const formatBondHoldings = (holdings, product) => {
  return holdings.map(({
    bond_data, 
    total_consideration_amount,
    purchase_date,
    quantity
  }) => ({
    productType: product,
    logo: bond_data?.logo_url,
    name: bond_data?.bond_name,
    coupon_date: bond_data?.coupon_credit_dates?.dates,    
    total_consideration_amount,
    purchase_date,
    quantity,
    ...bond_data
  }));
}

export const formatUSHoldings = (holdings, product) => {
  return holdings.map(({share_data, invested_amount, purchase_date, quantity}) => ({
    productType: product,
    logo: share_data?.logo_url,
    name: share_data?.share_name,
    invested_amount,
    purchase_date,
    quantity,
    ...share_data
  }));
}

