// ----------- ASSET IMPORTS ----------------
import pdf_icon from 'assets/pdf_icon.svg';
import csv_icon from 'assets/csv_icon.svg';
// ------------------------------------------
import './DownloadTypeDialog.scss';
import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import IwdCard from '../IwdCard';

export default function DownloadTypeDialog({
  isOpen,
  onClose,
  showPdf,
  onPdfDownloadClick,
  showCsv,
  onCsvDownloadClick
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="iwd-download-type-dialog"
    >
      <DialogContent>
        <IwdCard>
          <div id="title">
            Download report:
          </div>
          <div>
            {showPdf &&
              <img src={pdf_icon} alt='pdf_download' onClick={onPdfDownloadClick} style={{ marginRight: '30px' }} />
            }
            {showCsv &&
              <img src={csv_icon} alt='csv_download' onClick={onCsvDownloadClick} />
            }
          </div>
        </IwdCard>
      </DialogContent>
    </Dialog>
  );
}