import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import UpgradeLanding from "./pages/UpgradeLanding";
import RequestSubmission from "./pages/RequestSubmission";
import appUpdate from "./pages/AppUpdate";

const FinityUpgrade = (props) => {
  const { url } = props.match;

  return (
    <Fragment>
      <Switch>
        <Route exact path={`${url}`} component={UpgradeLanding} props={props} />
        <Route
          exact
          path={`${url}/request`}
          component={RequestSubmission}
          props={props}
        />
        <Route
          exact
          path={`${url}/app-update`}
          component={appUpdate}
          props={props}
        />
      </Switch>
    </Fragment>
  );
};

export default FinityUpgrade;
