import React from "react";
import { obscureNumber } from "../../common/commonFunctions";
import ViewClientDetails from "./ViewClientDetails";

export const CLIENT_LIST_HEADERS_MAP = [
  {
    label: "Client Pan",
    accessor: "pan_number",
    tdProps: {
      style: {
        textAlign: "left",
      },
    },
    formatter: value => obscureNumber(value, 3, 2)
  },
  {
    label: "Client Name",
    accessor: "client_name",
    tdProps: {
      style: {
        textAlign: "left",
      },
    },
  },
  {
    label: "View Dashboard",
    formatter: (value) => {
        return <ViewClientDetails value={value}/>;
    },
    tdProps: {
      style: {
        textAlign: "right",
      },
    },
  },
];