import { useState } from 'react';

export default function usePagination(defaultStartPage) {
  const [activePage, setActivePage] = useState(defaultStartPage || 1);
  const [pageMap, setPageMap] = useState([null, null]);

  const pushToPageMap = (url) => {
    if (!url || !!pageMap[activePage + 1]) return;
    setPageMap([...pageMap, url]);
  };

  const resetPageMap = () => {
    setPageMap([null, null]);
  }

  return {
    activePage,
    setActivePage,
    pageMap,
    pushToPageMap,
    resetPageMap
  };
}