import React, { useEffect } from "react";
import Header from "../../common/Header";
import FINITY_UPGRADE from "../../constants";
import iv_order_successfull from "assets/iv_order_successfull.svg";
import "./requestSubmission.scss";
import Button from "../../../common/ui/Button";
import { nativeCallback } from "../../../utils/native_callback";
import { getConfig } from "../../../utils/functions";

const STRINGS = FINITY_UPGRADE.REQUEST_SUBMISSION;

const requestConfirmation = () => {

  useEffect(() => {
    if (getConfig().iOS) {
      nativeCallback({ action: 'hide_top_bar' });
    }  
  }, []);
  const handleCta = () => {
    nativeCallback({ action: "exit_web" });
  };

  return (
    <div className="ContainerWrapper request-submit-container">
      <Header />
      <div className="rs-header">
        <img src={iv_order_successfull} alt="success" />
        <div className="rs-title">{STRINGS.title}</div>
        <div className="rs-subtitle">{STRINGS.subtitle}</div>
      </div>

      <div className="upgrade-footer">
        <Button
          classes={{
            button: "upgrade-button",
          }}
          buttonTitle={STRINGS.btnTitle}
          onClick={handleCta}
          color="default"
        />
      </div>
    </div>
  );
};

export default requestConfirmation;
