import React from "react"
import IwdScreenLoader from "../../mini-components/IwdScreenLoader";
import ProfileInfoCard from "./ProfileInfoCard";

export default function TabContentContainer({ hasError, isLoading, noData, children }) {
  const renderChild = () => {
    if (isLoading) {
      return (
        <IwdScreenLoader loadingText="Loading data..." />
      );
    } else if (hasError || noData) {
      return (
        <ProfileInfoCard
          style={{ width: "100%", height: "100%" }}
          error={hasError}
          errorText="Something went wrong! Please retry after some time"
          noData={noData}
          noDataText="Oops! There is no data to show here currently."
          isLoading={isLoading}
        />
      );
    } return children;
  }
  
  return (
    <div className="iwd-ma-tab-content-container">
      {renderChild()}
    </div>
  )
}