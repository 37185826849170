import React, { useEffect, useMemo, useState, useCallback } from 'react';

// 3RD PART LIBS
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';

// COMMON COMPONENTS
import toast from '../../../common/ui/Toast';
import PageHeader from '../../mini-components/PageHeader';
import SnapScrollContainer from '../../mini-components/SnapScrollContainer';
import IwdFilterDesktop from '../../mini-components/IwdFilter/IwdFilterDesktop';
import IwdFilterMobile from '../../mini-components/IwdFilter/IwdFilterMobile';
import LeftPanel from '../../mini-components/LeftPanel';
import ProductTypeSelectorDesktop from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorDesktop';
import IwdTableContainer from '../../mini-components/IwdTableContainer/IwdTableContainer';
import ProductTypeSelectorMobile from '../../mini-components/IwdProductTypeSelector/ProductTypeSelectorMobile';
import IwdReportDownloadMobile from '../../mini-components/IwdReportDownload/IwdReportDownloadMobile';
import ScrollTopBtn from '../../mini-components/ScrollTopBtn';
import IwdDisclaimerNote from '../../mini-components/IwdDisclaimerNote/IwdDisclaimerNote';
import IwdReportDownloadDesktop from '../../mini-components/IwdReportDownload/IwdReportDownloadDesktop';
import IwdViewTypeSwitch from '../../mini-components/IwdViewTypeSwitch/IwdViewTypeSwitch';

// HOOKS
import useProductType from '../../hooks/useProductType';
import { useUserContext } from '../../common/UserContext';

// HELPERS
import { scrollElementToPos } from '../../common/commonFunctions';
import { formatHoldingsForProduct } from './functions';
import { buildUrlWithQueryParams } from '../../../utils/functions';
import { formattedDate, open_browser_web } from '../../../utils/validators';
import { nativeCallback } from '../../../utils/native_callback';
import { getHoldings, getUserIdForRequest } from '../../common/ApiCalls';
import { getConfig } from 'utils/functions';

// CONSTANTS
import {
  PRODUCT_FILTER_OPTIONS_MAP,
  LIST_VIEW_HEADERS_MAP,
  AGGREGATE_INFO_HEADERS_MAP,
  CARD_VIEW_FIELDS_MAP,
  PRODUCT_TYPE_PAGE_PROPS_MAP,
  PRODUCT_FILTER_FUNCTIONS_MAP
} from './constants';
import {
  PRODUCT_TYPE_KEY_MAP,
  PRODUCT_TYPE_OPTIONS
} from '../../mini-components/IwdProductTypeSelector/constants';

// INTERNAL COMPONENTS
import HoldingCard from './HoldingCard';
import HoldingsTable from './HoldingsTable';

const isMobileView = getConfig().isMobileDevice;
const PRODUCT_KEY = 'iwd-holding-productType';

const Holdings = () => {
  const [holdingsData, setHoldingsData] = useState({});
  const [filteredHoldingsList, setFilteredHoldingsList] = useState(null);
  useEffect(() => {
    filterHoldings();
    scrollElementToPos('iwd-scroll-contain', 0, 0);
  }, [holdingsData]);
  
  const [showCardView, setShowCardView] = useState(true);
  const [isApiRunning, setIsApiRunning] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const { selectedProductType, updateSelectedProductType } = useProductType(PRODUCT_KEY);
  useEffect(() => {
    fetchHoldings();
  }, [selectedProductType]);

  const [filterVal, setFilterVal] = useState(null);
  const { isWmLogin } = useUserContext();

  const filterOptions = useMemo(() => (
    PRODUCT_FILTER_OPTIONS_MAP[selectedProductType]
  ), [selectedProductType]);
  const productTypePageProps = useMemo(() => (
    PRODUCT_TYPE_PAGE_PROPS_MAP[selectedProductType]
  ), [selectedProductType]);

  const sendEvents = (userAction, additionalProps = {}) => {
    const eventObj = {
      "event_name": 'internal dashboard hni',
      "properties": {
        screen_name: 'holdings',
        user_action: userAction,
        product_selected: selectedProductType,
        ...(["download report csv", "download report pdf"].includes(userAction) ? filterVal : {}),
        ...additionalProps
      }
    };

    nativeCallback({ events: eventObj });
  };

  const fetchHoldings = async () => {
    try {
      setIsApiRunning(true);
      
      const result = await getHoldings(selectedProductType);
      if (isEmpty(result)) {
        throw new Error("Something went wrong! Please try again");
      }
      const formattedHoldings = formatHoldingsForProduct(
        result?.individual_holdings_details,
        selectedProductType
      );
      setHoldingsData({
        holdingsList: formattedHoldings,
        aggregatedHoldingsInfo: result?.aggregated_holdings_details
      });
    } catch (e) {
      console.log(e);
      setHoldingsData({});
      toast(e);
    } finally {
      setIsApiRunning(false);
    }
  };

  const filterHoldings = (filter) => {
    const data = holdingsData?.holdingsList || [];
    if (isEmpty(data)) {
      setFilteredHoldingsList(null);
    }
        
    let newData = [];
    setIsFilterLoading(true);

    if (!isEmpty(filter)) {
      const filterFunc = PRODUCT_FILTER_FUNCTIONS_MAP[selectedProductType];
      newData = filterFunc(data, filter) || [];
    } else {
      newData = [...data];
    }
    
    setTimeout(() => {
      if (newData?.length > 0) {
        setFilteredHoldingsList(newData);
      } else {
        setFilteredHoldingsList(null);
      }
      setIsFilterLoading(false);
    }, 500);
  };

  const handleFilterChange = (newFilterData) => {
    if (isEmpty(newFilterData)) { // For when filter is cleared
      if (!isEmpty(filterVal)) { // Avoid triggering unnecessary filter change if already empty
        setFilterVal(null);
        filterHoldings(null);
      }
      return;
    }
    
    let processedFilterObj = {};
    
    if (newFilterData) {
      Object.keys(newFilterData).forEach(key => {
        // Only take filter key-value pairs that have a value set (ignore empty ones)
        if (newFilterData[key]) processedFilterObj[key] = newFilterData[key];
      });
    }
    if (isEmpty(processedFilterObj)) {
      processedFilterObj = null;
    }
    setFilterVal(processedFilterObj);
    filterHoldings(processedFilterObj);
  };

  const onProductTypeChange = (value) => {
    if (!value) return;
    
    if (value !== selectedProductType) {
      updateSelectedProductType(value);
      sendEvents('product change', {
        product_selected: value,
      });
    }
  };

  const onViewTypeChange = (event) => {
    const { checked } = event.target;
    setShowCardView(checked);
    sendEvents(
      checked ? 'card' : 'list',
    )
  }

  const downloadPdfReport = (e) => {
    e.stopPropagation();
    sendEvents('download report pdf');
    downloadTransactions('pdf');
  }

  const downloadCsvReport = (e) => {
    e.stopPropagation();
    sendEvents('download report csv');
    downloadTransactions('csv');
  }

  const downloadTransactions = async (fileType) => {
    const userId = getUserIdForRequest();
    if (filteredHoldingsList?.length > 0) {
      try {
        const baseURL = getConfig().base_url;

        const urlToHit = `${baseURL}/api/privatewealth/report/download/holdings/${selectedProductType}/${fileType}`;
        
        open_browser_web(
          buildUrlWithQueryParams(urlToHit, {...(filterVal || {}), user_id: userId}), 
          '_blank'
        );
      } catch (err) {
        console.log(err);
        toast(err);
      }
    } else {
      toast('No holdings to download');
    }
  };

  return (
    <div className='iwd-page' id='iwd-holdings'>
      <PageHeader hideLogout={isWmLogin}>
        <div className='iwd-header-title'>
          Holdings
        </div>
      </PageHeader>
      <section style={{ display: 'flex', width: '100%' }}>
        <LeftPanel>
          <ProductTypeSelectorDesktop
            value={selectedProductType}
            onChange={onProductTypeChange}
            disabled={isApiRunning || isFilterLoading}
            productOptions={PRODUCT_TYPE_OPTIONS}
          />
          <div className="iwd-left-panel-divider"></div>
          {filterOptions &&
            <IwdFilterDesktop
              key={selectedProductType}
              filterOptions={filterOptions}
              onFilterChange={handleFilterChange}
              filterValue={filterVal}
              disabled={isApiRunning}
            />
          }
        </LeftPanel>
        <div className={showCardView ? 'iwd-page-with-card' : 'iwd-page-with-table'}>
          {!(isApiRunning || isFilterLoading) &&
            <>
              <ProductTypeSelectorMobile
                value={selectedProductType}
                onChange={onProductTypeChange}
                productOptions={PRODUCT_TYPE_OPTIONS}
              />
              <div className="iwd-h-page-controls iwd-fade">
                <IwdReportDownloadDesktop
                  className="iwd-h-report-download"
                  showPdf={productTypePageProps?.showPdf}
                  showCsv={productTypePageProps?.showCsv}
                  onPdfDownloadClick={downloadPdfReport}
                  onCsvDownloadClick={downloadCsvReport}
                />
                <IwdReportDownloadMobile
                  dialogProps={{
                    showPdf: productTypePageProps?.showPdf,
                    showCsv: productTypePageProps?.showCsv,
                    onPdfDownloadClick: downloadPdfReport,
                    onCsvDownloadClick: downloadCsvReport
                  }}
                />
                <IwdViewTypeSwitch
                  value={showCardView}
                  onChange={onViewTypeChange}
                  disabled={!filteredHoldingsList?.length}
                />
              </div>
            </>
          }
          {showCardView ?
            <CardView
              // hasError={hasError}
              onErrorBtnClick={fetchHoldings}
              isFilterEmpty={isEmpty(filterVal)}
              holdings={filteredHoldingsList}
              isLoading={isApiRunning || isFilterLoading}
              productType={selectedProductType}
              sendEvents={sendEvents}
            /> :
            <ListView
              isLoading={isApiRunning || isFilterLoading}
              aggregatedHoldingsInfo={holdingsData.aggregatedHoldingsInfo}
              holdings={filteredHoldingsList}
              isFilterEmpty={isEmpty(filterVal)}
              productType={selectedProductType}
            />
          }
          {filterOptions &&
            <IwdFilterMobile
              key={selectedProductType}
              showFilterButton={!holdingsData?.holdings}
              filterValue={filterVal}
              filterOptions={filterOptions}
              onFilterChange={handleFilterChange}
            />
          }
        </div>
      </section>
    </div>
  );
};

export default Holdings;

const ListView = ({ isLoading, aggregatedHoldingsInfo, holdings, productType, isFilterEmpty }) => {
  const isHoldingsEmpty = isEmpty(holdings);
  const {
    tableHeadersMap,
    aggregateInfoHeadersMap,
    productTypePageProps
  } = useMemo(() => ({
    tableHeadersMap: LIST_VIEW_HEADERS_MAP[productType],
    aggregateInfoHeadersMap: AGGREGATE_INFO_HEADERS_MAP[productType],
    productTypePageProps : PRODUCT_TYPE_PAGE_PROPS_MAP[productType] 
  }), [productType]);


  return (
    <div>
      {!isEmpty(aggregatedHoldingsInfo) && !isEmpty(aggregateInfoHeadersMap) && !isLoading &&
        <div className="iwd-holdings-aggregate iwd-animatedFade">
          {aggregateInfoHeadersMap.map(({ title, accessor, formatter }, idx) => (
            <React.Fragment key={idx}>
              {idx !== 0 &&
                <div className="iwd-ha-item-divider"></div>
              }
              <div className="iwd-ha-item">
                <div className="iwd-ha-value">
                  {isFunction(formatter) ?
                    formatter(accessor ? aggregatedHoldingsInfo[accessor] : aggregatedHoldingsInfo)
                    :
                    aggregatedHoldingsInfo[accessor]
                  }
                </div>
                {title && <div className="iwd-ha-label">{title}</div>}
              </div>
            </React.Fragment>
          ))}
        </div>
      }
      <IwdTableContainer
        key={productType}
        isLoading={isLoading}
        loadingText="Fetching holdings..."
        noData={isHoldingsEmpty}
        noDataMessage={
          isFilterEmpty ?
            'Oops! There is no data to show here currently.' :
            'Oops! We couldn’t find any data for the selected filter. Try removing or changing the filter.'
        }
      >
        <IwdTableContainer.TableContent>
          <HoldingsTable holdings={holdings} headersMap={tableHeadersMap} />
        </IwdTableContainer.TableContent>
      </IwdTableContainer>

      {productTypePageProps?.disclaimerMsg && !isLoading &&
        <IwdDisclaimerNote
          showNote
          message={productTypePageProps.disclaimerMsg}
          className='iwd-animatedFade'  
        />
      }
    </div>
  );
}

const CardView = ({
  hasError,
  onErrorBtnClick,
  isLoading,
  holdings,
  isFilterEmpty,
  productType,
  sendEvents
}) => {

  const productTypePageProps = useMemo(() => PRODUCT_TYPE_PAGE_PROPS_MAP[productType], [productType]);
  
  const holdingCardProps = useCallback((holding) => {
    const detailsKeyMap = CARD_VIEW_FIELDS_MAP[productType];
    
    switch (productType) {
      case PRODUCT_TYPE_KEY_MAP.mf:
        return {
          rating: holding.fisdom_rating,
          headerContentRow1: (
            <>
              {holding.scheme_type &&
                <>
                  {holding.scheme_type}
                  <span>|</span>
                </>
              }
              Since {formattedDate(holding.invested_since, 'm y')}
            </>
          ),
          logo: holding.logo,
          title: holding.name,
          detailsKeyMap,
          showDetails : true
        }
      case PRODUCT_TYPE_KEY_MAP.pms:
        return {
          headerContentRow1: `Since ${formattedDate(holding.invested_since, 'm y')}`,
          logo: holding.logo,
          title: holding.name,
          detailsKeyMap,
          showDetails : true
        }
      case PRODUCT_TYPE_KEY_MAP.aif:
        return {
          headerContentRow1: (
            <>
              Since {formattedDate(holding.invested_since, 'd m y')}
              {holding.invested_since && holding.scheme_type &&
                <span>|</span>
              }
              {holding.scheme_type}
            </>
          ),
          headerContentRow2: `Folio no: ${holding.folio_number}`,
          logo: holding.logo,
          title: holding.name,
          detailsKeyMap,
        }
      case PRODUCT_TYPE_KEY_MAP.bond:
        return {
          headerContentRow1: (
            <>
              {holding.bond_type}
              <span>|</span>
              Since {formattedDate(holding.bond_issue_date, 'd m y', true)}
            </>
          ),
          headerContentRow2: `ISIN - ${holding.isin}`,
          logo: holding.logo,
          title: holding.name,
          detailsKeyMap
        }
      case PRODUCT_TYPE_KEY_MAP.unlistedShares:
        return {
          headerContentRow1: "EQUITY",
          headerContentRow2: `ISIN - ${holding.isin}`,
          logo: holding.logo,
          title: holding.name,
          detailsKeyMap
        }
      default:
        return {};
    }
  }, [productType]);

  return (
    <SnapScrollContainer
      hideFooter
      scrollOnChange
      error={hasError}
      onErrorBtnClick={onErrorBtnClick}
      isLoading={isLoading}
      noData={isEmpty(holdings)}
      noDataText={
        isFilterEmpty?
          'Oops! There is no data to show here currently.' :
          'Oops! We couldn’t find any data for the selected filter. Try removing or changing the filter.'
      }
      loadingText='Fetching holdings...'
    >
      {holdings?.map((holding, idx) =>     
        <HoldingCard
          {...holdingCardProps(holding)}
          sendEvents={sendEvents}
          data={holding}
          key={idx}
        />
      )}
      {productTypePageProps?.disclaimerMsg && !isLoading &&
        <IwdDisclaimerNote
          showNote
          message={productTypePageProps.disclaimerMsg}
          className='iwd-animatedFade'  
        />
      }
      {isMobileView && holdings?.length > 1 && <ScrollTopBtn />}
    </SnapScrollContainer>
  );
}