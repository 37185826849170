import nextArrow from 'assets/ic_next_arrow_gold.svg';

import { memo, useState } from "react";
import { withRouter } from "react-router-dom";
import React from 'react';
import IwdCard from "../../mini-components/IwdCard";
import { isFunction } from "../../../utils/validators";
import RatingStar from "../../../fund_details/common/RatingStar";
import HoldingDetail from "./HoldingDetail";
import { TextButton } from '../../common/Button';

const HoldingCard = ({
  data = {},
  rating,
  headerContentRow1,
  headerContentRow2,
  logo,
  title = "",
  detailsKeyMap = [],
  sendEvents,
  showDetails
}) => {
  const [openDetail, setOpenDetail] = useState(false);
  
  const onMoreDetailsClicked = () => {
    setOpenDetail(true);
    sendEvents('more details');
  }
  
  return(
    <IwdCard className="iwd-holding-card" animation="iwd-fade">
      {openDetail &&
        <HoldingDetail
          investmentDetail={data}
          onCloseClick={() => setOpenDetail(false)}
        />
      }
      <div className="iwd-hc-header-container"> {/* TODO: to create three generic headings according to all the holdings*/}
        <div className="iwd-hch-left">
          {!!rating &&
            <RatingStar value={rating} />
          }
          {headerContentRow1 &&
            <div className="iwd-hch-row1">
              {headerContentRow1}
            </div>
          }
          {headerContentRow2 &&
            <div className="iwd-hch-row2">
              {headerContentRow2}
            </div>
          }
        </div>
        {logo &&
          <div className="iwd-hch-right">
            <img src={logo} alt="" height="40" />
          </div>
        }
      </div>

      {title &&
        <div className="iwd-hc-title">
          <span title={title}>{title}</span>
        </div>
      }

      <div className="iwd-hc-container">
        {detailsKeyMap.map(({ label, accessor, formatter, customHeader, labelProps = {}, key })=>(
          <div className="iwd-hcn-item" key={key}>
            <div className = "iwd-hcni-value" {...labelProps}>
              {
                isFunction(formatter) ?
                  formatter(accessor ? data[accessor] : data)
                  : data[accessor]
              }
            </div>
            <div className="iwd-hcni-label">
              {
                isFunction(customHeader) ? 
                  customHeader(label ? label : data)
                  : label
              }
            </div>
          </div>
        ))}
      </div>
      {showDetails &&
        <TextButton
          classes={{
            root: 'iwd-hc-more'
          }}
          onClick={onMoreDetailsClicked}
        >
          <span>More details</span>
          <img src={nextArrow} alt="" style={{ marginLeft: '15px' }} />
        </TextButton>
      }
    </IwdCard>
  );
};

export default memo(withRouter(HoldingCard));