import React from 'react';

const CTabPanel = (props) => {
    const { value, index, children } = props;
    return(
        <div>
            {value === index && (
                <div style={{ padding: '30px 0 0' }}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default CTabPanel;
