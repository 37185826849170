import './FamilyAllocationGraphs.scss';
import React from 'react';
import IwdPieChart from '../../mini-components/IwdPieChart/IwdPieChart';
import isEmpty from 'lodash/isEmpty';
import IwdCard from '../../mini-components/IwdCard';
import IwdLegend from '../../mini-components/IwdLegend/IwdLegend';
import { useState } from 'react';
import { getConfig } from 'utils/functions';
import { useEffect } from 'react';
import { GRAPH_COLOR_PALETTE } from '../../common/constants';
import IwdLineChart from '../../mini-components/IwdLineChart/IwdLineChart';
import { addGraphColors } from '../../common/commonFunctions';

const isMobileView = getConfig().isMobileDevice;

const FamilyAllocationGraphs = ({
  productAllocation,
  individualContributions,
  isLoading
}) => {
  const [processedProductAlloc, setProcessedProductAlloc] = useState([]);
  const [processedIndividualContributions, setProcessedIndividualContributions] = useState([]);

  const processAllocGraphData = (data) => {
    return Object
      .entries(data)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value], idx) => ({
        id: key,
        label: key,
        value: Number(value || 0),
        color: GRAPH_COLOR_PALETTE[idx]
      }))
  }

  useEffect(() => {
    const productAlloc = processAllocGraphData(productAllocation);
    setProcessedProductAlloc(productAlloc);
  }, [productAllocation]);

  useEffect(() => {
    const contributions = addGraphColors(individualContributions).sort((a, b) => b.contribution - a.contribution);
    setProcessedIndividualContributions(contributions);
  }, [individualContributions]);

  return (
    <>
      <IwdCard
        className='iwd-fd-chart'
        id="iwd-fd-individual-contribution"
        headerText='Individual contribution'
        error={
          isEmpty(individualContributions)
        }
        errorText='Something went wrong! Please retry after some time or contact your wealth manager'
        isLoading={isLoading}
      >
        <div className='iwd-chart'>
          <IwdLineChart
            labelKey='name'
            valueKey='contribution'
            data={processedIndividualContributions}
          />
        </div>
        <IwdLegend
          data={processedIndividualContributions}
          labelKey='name'
          valueKey='contribution'
          columns={isMobileView ? 2 : 3}
          classes={{
            container: 'iwd-fdic-legend',
            child: 'iwd-fdic-legend-child',
          }}
        />
      </IwdCard>
      <IwdCard
        className='iwd-fd-chart'
        id="iwd-fd-product-alloc"
        headerText='Product allocation'
        error={
          isEmpty(productAllocation)
        }
        errorText='Something went wrong! Please retry after some time or contact your wealth manager'
        isLoading={isLoading}
      >
        <div id='iwd-fdpa-container'>
          <div className='iwd-chart'>
            <IwdPieChart
              data={processedProductAlloc}
            />
          </div>
          <IwdLegend
            data={processedProductAlloc}
            classes={{
              container: 'iwd-fdpac-legend',
              child: 'iwd-fdpac-legend-child',
            }}
          />
        </div>
      </IwdCard>
    </>
  );
}

export default React.memo(FamilyAllocationGraphs);