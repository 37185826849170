import xls_icon from "assets/xls_icon.svg";
import pdf_icon from "assets/pdf_icon.svg";
import React, { useMemo } from "react";
import { getUserIdForRequest } from "../../common/ApiCalls";
import SnapScrollContainer from "../../mini-components/SnapScrollContainer";
import { getConfig } from "../../../utils/functions";
import { nativeCallback } from "../../../utils/native_callback";
import IwdCard from "../../mini-components/IwdCard";
import { useUserContext } from "../../common/UserContext";
import { isEmpty } from "../../../utils/validators";

const PortfolioSummary = () => {
  const title = "Consolidated report";
  const { userKycDetails = {} } = useUserContext();

  const isKycCompleted = (kyc) => {
    if (isEmpty(kyc)) return false;
    if (kyc?.kyc_status === "compliant") {
      return kyc?.application_status_v2 === "complete";
    } else {
      return (
        kyc?.application_status_v2 === "complete" &&
        kyc.sign_status === "signed"
      );
    }
  };

  const isUserKycCompleted = useMemo(() => {
    return isKycCompleted(userKycDetails);
  }, [userKycDetails]);

  return (
    <div className="iwd-statement-report-container">
      <div className="iwd-statement-title">{title}</div>
      <SnapScrollContainer
        hideFooter={true}
        loadingText="Fetching ..."
        noData={!isUserKycCompleted}
        noDataText="No Consolidate report to display"
      >
        <PortfolioSummaryCard showPdf />
      </SnapScrollContainer>
    </div>
  );
};

export default PortfolioSummary;

const PortfolioSummaryCard = ({ showPdf = false, showCsv = false }) => {

  const { base_url } = useMemo(getConfig, [])

  const handleDownloadReportPdf = async () => {
    sendEvents("download report pdf");
    const requestUserId = getUserIdForRequest();
    const url = `${
      base_url
    }/api/privatewealth/report/download/consolidated/holdings/pdf?user_id=${requestUserId}`;
    window.open(url, "_blank");
  };

  const handleDownloadReportCsv = async () => {
    sendEvents("download report csv");
    const requestUserId = getUserIdForRequest();
    const url = `${
      base_url
    }/api/privatewealth/report/download/consolidated/holdings/csv?user_id=${requestUserId}`;
    window.open(url, "_blank");
  };

  const sendEvents = (user_action) => {
    let eventObj = {
      event_name: "internal dashboard hni",
      properties: {
        screen_name: "statements",
        user_action: user_action,
        sub_tab: "portfolio summary",
      },
    };
    nativeCallback({ events: eventObj });
  };

  return (
    <IwdCard className="iwd-statement-card">
      <>
        <div className="iwd-statement-text-container">
          <span className="iwd-statement-year-heading">As of</span>
          <span className="iwd-statement-year-value">Today</span>
        </div>
        <div className="iwd-statement-file-container">
          {showPdf && (
            <img
              src={pdf_icon}
              className="iwd-statement-icon"
              alt="pdf_icon"
              onClick={handleDownloadReportPdf}
            />
          )}
          {showCsv && (
            <img
              src={xls_icon}
              className="iwd-statement-icon"
              alt="csv_icon"
              onClick={handleDownloadReportCsv}
            />
          )}
        </div>
      </>
    </IwdCard>
  );
};
