import React, { useState } from "react";
import { map } from "lodash";
import FINITY_UPGRADE from "../../../constants";
import minus_icon from "assets/minus_icon.svg";
import plus_icon from "assets/plus_icon.svg";
import CollapsibleSection from "../../../../common/components/CollapsibleSection/CollapsibleSection";

const STRINGS = FINITY_UPGRADE.FAQS;

const FaqList = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>
      {map(STRINGS.content, (el, index) => {
        const isLastItem = index === STRINGS.content.length - 1;
        return (
          <CollapsibleSection
            className={`fuc-faq-wrapper ${!isLastItem && "fuc-hr"}`}
            key={index}
            title={el.question}
            openIconName={minus_icon}
            closeIconName={plus_icon}
            isOpen={currentIndex === index}
            onClick={() => onClick(index)}
          >
            <div className="fuc-subtext">{el.answer}</div>
          </CollapsibleSection>
        );
      })}
    </div>
  );
};

export default FaqList;
