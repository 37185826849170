import { isEmpty } from "../../utils/validators";
import Api from "../../utils/api";
const genericErrMsg = "Something Went wrong";

export const get_migration_consent = async () => {
  try {
    const res = await Api.get(
      "api/plutususerdata/migration/acknowledge/consent/campaign"
    );

    if (
      res.pfwstatus_code !== 200 ||
      !res.pfwresponse ||
      isEmpty(res.pfwresponse)
    ) {
      throw genericErrMsg;
    }
    const { result, status_code: status } = res.pfwresponse;
    if (status === 200) {
      return result;
    } else {
      throw result.error || result.message || genericErrMsg;
    }
  } catch (err) {
    throw err;
  }
};
