import { format } from 'date-fns/esm';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import RatingStar from '../../../fund_details/common/RatingStar';
import { capitalize, formattedDate, inrFormatDecimal, nonRoundingToFixed } from '../../../utils/validators';
import { formatNumVal, returnsFormatter } from '../../common/commonFunctions';
import { getTransactionTypeElement } from '../../common/constants';
import { PRODUCT_TYPE_KEY_MAP } from '../../mini-components/IwdProductTypeSelector/constants';
import IwdReturns from '../../mini-components/IwdReturns/IwdReturns';
import IwdTooltip from '../../mini-components/IwdTooltip/IwdTooltip';
import { colorConfig } from '../../theme/colorConfig';
import HoldingsListActionColumn from './HoldingsListActionColumn';

const ERROR_RED = colorConfig.secondary.red;
const SUCCESS_GREEN = colorConfig.secondary.green;

const MF_FILTERS = [{
  id: 'scheme_type',
  type: 'radio',
  category: 'Fund Type',
  filters: [
    {
      label: 'Equity',
      value: 'equity',
    },
    {
      label: 'Debt',
      value: 'debt',
    },
    {
      label: 'Hybrid',
      value: 'hybrid',
    },
    {
      label: 'ELSS',
      value: 'elss',
    },
  ],
},
{
  id: 'current_value_type',
  type: 'radio',
  category: 'Current Value',
  filters: [
    {
      label: '<1 lacs',
      value: '1',
    },
    {
      label: '1-5 lacs',
      value: '2',
    },
    {
      label: '5-10 lacs',
      value: '3',
    },
    {
      label: '10+lacs',
      value: '4',
    },
  ],
},
{
  id: 'fisdom_rating',
  type: 'radio',
  category: 'Fisdom Rating',
  filters: [
    {
      label: '3 & Below',
      value: '1',
    },
    {
      label: '4 & above',
      value: '2',
    },
  ],
}];

export const PRODUCT_FILTER_OPTIONS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_FILTERS,
  [PRODUCT_TYPE_KEY_MAP.pms]: null,
  [PRODUCT_TYPE_KEY_MAP.aif]: null,
  [PRODUCT_TYPE_KEY_MAP.bond]: null
}

const MF_LIST_VIEW_HEADERS_MAP = [
  {
    label: 'Fund name',
    accessor: 'name',
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'FISDOM RATING',
    accessor: 'fisdom_rating',
    formatter: (value) => {
      return <RatingStar value={value} />;
    },
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'FUND TYPE',
    accessor: 'scheme_type',
    formatter: value => value === 'elss' ? 'ELSS' : upperFirst(value || ''),
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Invested amount(₹)',
    accessor: 'invested_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Current amount(₹)',
    accessor: 'current_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'XIRR',
    accessor: 'xirr',
    formatter: (value) => (
      value ?
        <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>{value + '%'}</span> :
        '--'
    ),
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Action',
    formatter: fundData => <HoldingsListActionColumn isin={fundData.isin} fundData={fundData} productType={PRODUCT_TYPE_KEY_MAP.mf} />,
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
];

const PMS_LIST_VIEW_HEADERS_MAP = [
  {
    label: 'Fund name',
    accessor: 'name',
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Invested amount(₹)',
    accessor: 'invested_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Current amount(₹)',
    accessor: 'current_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Unrealized Gain/ Loss(₹)',
    accessor: 'unrealised_gain_loss',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Portfolio Weightage',
    accessor: 'portfolio_weightage',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    ),
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Annualised Return',
    accessor: 'annualised_return',
    formatter: (value) => {
      const formattedVal = returnsFormatter(value);

      return formattedVal ?
        <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>{formattedVal}</span> :
        '--'
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Action',
    formatter: fundData => <HoldingsListActionColumn fundData={fundData} productType={PRODUCT_TYPE_KEY_MAP.pms} />,
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
]

const AIF_LIST_VIEW_HEADERS_MAP = [
  {
    label: 'inception Date',
    accessor: 'invested_since',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y') :
        '--'
    ),
  },
  {
    label: 'Fund name',
    accessor: 'name',
  },
  {
    label: 'Folio Number',
    accessor: 'folio_number',
  },
  {
    label: 'Commitment amount',
    accessor: 'commitment_amount',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Invested amount',
    accessor: 'invested_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Current amount',
    formatter: ({ current_value, statement_date }) => {
      const statementDateInfo = statement_date
        ? <><br />(as of {format(new Date(statement_date), 'dd/MM/yyyy')})</>
        : '';
      
      return <>{formatNumVal(current_value, true)}{statementDateInfo}</>;
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Unrealised gain/loss',
    accessor: 'unrealised_gain',
    formatter: value => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Annualised Return',
    accessor: 'annualised_return',
    formatter: value => {
      const formattedVal = returnsFormatter(value);

      return formattedVal ?
        <span style={{ color: value < 0 ? ERROR_RED : SUCCESS_GREEN }}>{formattedVal}</span> :
        '--'
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
]

const BOND_LIST_VIEW_HEADERS_MAP = [
  {
    label: 'Purchase date',
    accessor: 'purchase_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y', true) :
        '--'
    )
  },
  {
    label: 'Bond',
    formatter: (value) => (
      `${value.name} (${value.bond_type})`
    )
  },
  {
    label: 'Coupon rate',
    accessor: 'coupon_rate',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  },
  {
    label: 'Coupon frequency',
    accessor: 'coupon_frequency',
    formatter: (value) => {
      return capitalize(value);
    }
  },
  {
    label: 'Coupon date',
    formatter: (value) => {
      switch (value.coupon_frequency) {
        case "monthly":
          return `${formattedDate(value.coupon_date[0], 'd')} of every month`;

        case "annually":
        case "semi-annually":
        case "quarterly":
          return value.coupon_date.map(date => formattedDate(date, 'd m')).join(", ");
        
        default:
          return "--";
      }
    }
  },
  {
    label: 'Maturity date',
    accessor: 'maturity_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y', true) :
        '--'
    )
  },
  {
    label: 'Call/put date',
    formatter: (date) => (
      <>
        {date.call_date && <div>{formattedDate(date.call_date, 'd m y', true) + " (C)"}</div>}
        {date.put_date && <div>{formattedDate(date.put_date, 'd m y', true) + " (P)"}</div>}
        {!date.call_date && !date.put_date && "--"}
      </>
    )
  },
  {
    label: 'Face value',
    accessor: 'face_value',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Quantity',
    accessor: 'quantity',
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Invested amount',
    accessor: 'total_consideration_amount',
    formatter: (value) => {
      return formatNumVal(value, true);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    },
    renderCustomHeader: (title) => {
      return <div style={{ display: "flex" }}>
        <div>{title}</div>
        <IwdTooltip
          title="Invested Value"
          content="The amount shown includes accrued interest, DP and regulatory charges"
        >
          <img
            src={require(`assets/fisdom/ic-info-primary.svg`)}
            width={14}
            id="wr-i-btn"
            alt=""
            style={{
              position: "relative",
              top: "3px",
              left: "5px"
            }}
          />
        </IwdTooltip>
      </div>
    }
  }
]

const US_LIST_VIEW_HEADERS_MAP = [
  {
    label: 'Securities',
    accessor: 'name'
  },
  {
    label: 'ISIN',
    accessor: 'isin'
  },
  {
    label: 'Purchase date',
    accessor: 'purchase_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y', true) :
        '--'
    )
  },
  {
    label: 'Quantity',
    accessor: 'quantity'
  },
  {
    label: 'Invested amount',
    accessor: 'invested_amount',
    formatter: (value) => {
      return formatNumVal(value, true);
    }
  }
]

export const LIST_VIEW_HEADERS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_LIST_VIEW_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_LIST_VIEW_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: AIF_LIST_VIEW_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.bond]: BOND_LIST_VIEW_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.unlistedShares]: US_LIST_VIEW_HEADERS_MAP
};

export const MF_CARD_VIEW_FIELDS_MAP = [
  {
    label: 'Current',
    accessor: 'current_value',
    key: 'current_value',
    formatter: formatNumVal
  },
  {
    label: 'Invested',
    accessor: 'invested_value',
    key: 'invested_value',
    formatter: formatNumVal
  },
  {
    key: 'xirr',
    formatter: (value) => {
      return <IwdReturns
        value={value.xirr}
        label={"XIRR"}
        classes={{
          root: 'iwd-hcn-item'
        }}
      />
    }
  }
]

export const PMS_CARD_VIEW_FIELDS_MAP = [
  {
    label: 'Current',
    accessor: 'current_value',
    key: 'current_value',
    formatter: formatNumVal
  },
  {
    label: 'Invested',
    accessor: 'invested_value',
    key: 'invested_value',
    formatter: formatNumVal

  },
  {
    key: 'xirr',
    formatter: (value) => {
      return <IwdReturns
        value={value.annualised_return}
        label={"Annualised Return"}
        classes={{
          root: 'iwd-hcn-item full-width'
        }}
      />
    }
  }
]

export const AIF_CARD_VIEW_FIELDS_MAP = [
  {
    customHeader: ({ statement_date }) => {
      const statementDateInfo = statement_date
        ? <><br />(as of {format(new Date(statement_date), 'dd/MM/yyyy')})</>
        : '';

      return <>Current {statementDateInfo}</>;
    },
    accessor: 'current_value',
    key: 'current_value',
    formatter: formatNumVal
  },
  {
    label: '',
    accessor: '',
    key: '',
  },
  {
    label: 'Commitment amt',
    accessor: 'commitment_amount',
    key: 'commitment_amt',
    formatter: formatNumVal
  },
  {
    accessor: 'annualised_return',
    key: 'annualised_return',
    formatter: value => (
      <IwdReturns
        value={value}
        label="Annualised Return"
        classes={{
          root: 'iwd-hcn-item full-width'
        }}
      />
    )
  }
]

export const BOND_CARD_VIEW_FIELDS_MAP = [
  {
    label: 'Invested',
    accessor: 'total_consideration_amount',
    key: 'total_consideration_amount',
    formatter: formatNumVal
  },
  {
    key: 'date',
    customHeader : (data) => (
      data.maturity_date ?
        "Maturity date" :
      data.call_date ?
        "Call date" :
      data.put_date ? 
        "Put date" : 
      "Maturity date"
    ),
    formatter : (data) => (
      data.maturity_date ?
        formattedDate(data.maturity_date, 'd m y', true) :
      data.call_date ?
        formattedDate(data.call_date, 'd m y', true) :
      data.put_date ? 
        formattedDate(data.put_date, 'd m y', true) : 
      "--"
    )
  },
  {
    label: 'Coupon frequency',
    accessor: 'coupon_frequency',
    key: 'coupon_frequency',
    formatter: (value) => {
      return value ? capitalize(value) : '';
    }
  },

  {
    label: 'Coupon rate',
    accessor: 'coupon_rate',
    key: 'coupon_rate',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  }
]

const US_CARD_VIEW_FIELDS_MAP = [    
  {
    label: 'INVESTED',
    accessor : 'invested_amount',
    key : 'invested_amount',
    formatter: (invested_value) => (
      formatNumVal(invested_value)
    )
  },{
    label: 'PURCHASE DATE',
    accessor : 'purchase_date',
    key : 'purchase_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y', true) :
        '--'
    )
  },{
    label: 'Quantity',
    accessor: 'quantity',
    key: 'quantity'
  },
]

export const CARD_VIEW_FIELDS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_CARD_VIEW_FIELDS_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_CARD_VIEW_FIELDS_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: AIF_CARD_VIEW_FIELDS_MAP,
  [PRODUCT_TYPE_KEY_MAP.bond]: BOND_CARD_VIEW_FIELDS_MAP,
  [PRODUCT_TYPE_KEY_MAP.unlistedShares]: US_CARD_VIEW_FIELDS_MAP
}

const MF_DETAIL_INFO_MAP = {
  left: [{
    title: 'Current',
    accessor: 'current_value',
    formatter: formatNumVal
  }, {
    title: 'Invested',
    accessor: 'invested_value',
    formatter: formatNumVal
  }, {
    title: 'XIRR',
    accessor: 'xirr',
    formatter: (value) => returnsFormatter(value) || '--'
  }],
  right: [{
    title: 'Launch date',
    accessor: 'start_date',
    formatter: (start_date) => (
      start_date ?
        formattedDate(start_date, 'd m y') :
        '--'
    )
  }, {
    title: '1 yr return',
    accessor: 'one_year_return',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  }, {
    title: '3 yrs return',
    accessor: 'three_year_return',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  }, {
    title: '5 yrs return',
    accessor: 'five_year_return',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  }]
}

const PMS_DETAIL_INFO_MAP = {
  left: [{
    title: 'Current',
    accessor: 'current_value',
    formatter: formatNumVal
  }, {
    title: 'Invested',
    accessor: 'invested_value',
    formatter: formatNumVal
  }, {
    // title: 'Annualised Return',  Title handled within formatter itself
    accessor: 'annualised_return',
    formatter: (value) =>
      <IwdReturns
        value={value}
        label="Annualised Return"
      />
  }],
  right: [{
    title: 'Unrealized Gain/ Loss',
    accessor: 'unrealised_gain_loss',
    formatter: formatNumVal
  }, {
    title: 'Portfolio Weightage',
    accessor: 'portfolio_weightage',
    formatter: (value) => (
      value ?
        `${nonRoundingToFixed(value, 2)}%` :
        '--'
    )
  }]
}

export const HOLDING_DETAIL_INFO_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_DETAIL_INFO_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_DETAIL_INFO_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: null
}

const MF_HOLDING_TRANSACTION_HEADERS_MAP = [
  {
    label: 'Date',
    accessor: 'tdate',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y') :
        '--'
    ),
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Type',
    accessor: 'ttype',
    formatter: (type) => {
      return getTransactionTypeElement(PRODUCT_TYPE_KEY_MAP.mf, type);
    },
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Unit',
    accessor: 'units',
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Unit Price',
    accessor: 'nav',
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
  {
    label: 'Amount',
    accessor: 'amount',
    formatter: (value) => {
      return inrFormatDecimal(value, 2);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  },
]

const PMS_HOLDING_TRANSACTION_HEADERS_MAP = [
  {
    label: 'Investment Date',
    accessor: 'transaction_date',
    formatter: (date) => (
      date ?
        formattedDate(date, 'd m y') :
        '--'
    ),
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Type',
    accessor: 'transaction_type',
    formatter: (type) => {
      return getTransactionTypeElement(PRODUCT_TYPE_KEY_MAP.pms, type);
    },
    tdProps: {
      style: {
        textAlign: 'left'
      }
    }
  },
  {
    label: 'Amount',
    accessor: 'amount',
    formatter: (value) => {
      return inrFormatDecimal(value, 2);
    },
    tdProps: {
      style: {
        textAlign: 'right'
      }
    }
  }
];

export const HOLDING_TRANSACTION_HEADERS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_HOLDING_TRANSACTION_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_HOLDING_TRANSACTION_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: null
}

const MF_AGGR_HEADERS_MAP = [{
  title: 'Total funds',
  accessor: 'total_funds',
}, {
  title: 'Invested',
  accessor: 'invested_value',
  formatter: (invested_value) => formatNumVal(invested_value)
}, {
  title: 'Current',
  accessor: 'current_value',
  formatter: (current_value) => formatNumVal(current_value)
}, {
  // title: '', Title handled within formatter itself
  accessor: 'xirr',
  formatter: (value) =>
    <IwdReturns
      value={value}
      label="XIRR"
    />
}];

const PMS_AGGR_HEADERS_MAP = [{
  title: 'Total funds',
  accessor: 'total_funds',
}, {
  title: 'Invested',
  accessor: 'total_invested_value',
  formatter: (invested_value) => formatNumVal(invested_value)
}, {
  title: 'Current',
  accessor: 'current_value',
  formatter: (current_value) => formatNumVal(current_value)
}, {
  // title: '', Title handled within formatter itself
  accessor: 'absolute_return',
  formatter: (value) =>
    <IwdReturns
      value={value}
      label="Absolute Return"
    />
}];

const AIF_AGGR_HEADERS_MAP = [{
  title: 'Total funds',
  accessor: 'total_funds',
}, {
  title: 'Invested',
  accessor: 'total_invested_value',
  formatter: (invested_value) => formatNumVal(invested_value)
}, {
  title: 'Current',
  accessor: 'current_value',
  formatter: (current_value) => formatNumVal(current_value)
}, {
  accessor: 'absolute_return',
  formatter: (value) => (
    <IwdReturns
      value={value}
      label="Absolute Return"
    />
  )
}];

const BOND_AGGR_HEADERS_MAP = [{
  title: 'Total funds',
  accessor: 'total_funds',
}, {
  title: 'Invested',
  accessor: 'total_invested_value',
  formatter: (invested_value) => formatNumVal(invested_value)
}];

const US_AGGR_HEADERS_MAP = [{
  title: 'Total funds',
  accessor: 'total_funds',
}, {
  title: 'Invested',
  accessor: 'total_invested_value',
  formatter: (invested_value) => formatNumVal(invested_value)
}];


export const AGGREGATE_INFO_HEADERS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_AGGR_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.pms]: PMS_AGGR_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.aif]: AIF_AGGR_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.bond]: BOND_AGGR_HEADERS_MAP,
  [PRODUCT_TYPE_KEY_MAP.unlistedShares]: US_AGGR_HEADERS_MAP
}

export const PRODUCT_TYPE_PAGE_PROPS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: {
    showPdf: true,
    showCsv: true
  },
  [PRODUCT_TYPE_KEY_MAP.pms]: {
    showPdf: true,
    showCsv: true
  },
  [PRODUCT_TYPE_KEY_MAP.aif]: {
    showPdf: true,
    showCsv: true,
  },
  [PRODUCT_TYPE_KEY_MAP.bond]: {
    showPdf: true,
    showCsv: true,
    disclaimerMsg: "Contact your wealth manager for the valuation report of the bonds",
  },
  [PRODUCT_TYPE_KEY_MAP.unlistedShares]: {
    showPdf: true,
    showCsv: true,
    disclaimerMsg: "Contact your wealth manager for the valuation report of the unlisted shares",
  }
}

const MF_FILTER_FUNCTION = () => {
  // Creating closure here for constants and helper functions
  const schemeMap = {
    hybrid: ['hybrid', 'hybrid (c)', 'hybrid (nc)'],
    equity: ['equity', 'equity(g)', 'balanced', 'fof', 'equity(s)'],
    debt: ['debt', 'bond', 'income', 'mip', 'gilt', 'liquid'],
    elss: ['elss'],
  };

  const checkSchemeType = (filterSchemeType, currentSchemeType) => {
    return schemeMap[filterSchemeType].includes(currentSchemeType);
  };

  const currentValue = (current_value_type) => {
    const lac = 100000;
    switch (current_value_type) {
      case 1:
        return {
          min: 0,
          max: lac,
        };
      case 2:
        return {
          min: lac,
          max: 5 * lac,
        };
      case 3:
        return {
          min: 5 * lac,
          max: 10 * lac,
        };
      case 4:
        return {
          max: 10 * lac,
        };
      default:
        return {
          min: '',
          max: '',
        };
    }
  };

  return (data, filterVal) => { // Actual filtering function
    return data.filter((el) => {
      let bool = true;
      if (
        filterVal?.scheme_type &&
        !checkSchemeType(filterVal?.scheme_type?.toLowerCase(), el?.scheme_type?.toLowerCase())
      ) {
        bool = false;
      }
      if (bool && filterVal?.fisdom_rating) {
        let fisdomRating = parseInt(filterVal?.fisdom_rating, 10);
        bool = fisdomRating === 1 ? el.fisdom_rating <= 3 : el.fisdom_rating >= 4;
      }
      if (bool && filterVal?.current_value_type) {
        let currentValueType = parseInt(filterVal?.current_value_type, 10);
        if (currentValueType === 4) {
          bool = el.current_value > currentValue(currentValueType).max;
        } else {
          bool =
            el.current_value >= currentValue(currentValueType).min &&
            el.current_value <= currentValue(currentValueType).max;
        }
      }
      return bool;
    });
  }
}

export const PRODUCT_FILTER_FUNCTIONS_MAP = {
  [PRODUCT_TYPE_KEY_MAP.mf]: MF_FILTER_FUNCTION() //Uses closures internally
  // ADD OTHER PRODUCTS AS REQUIRED
}