import './AddMembers.scss';
import PaperPlaneIcon from "assets/fisdom/ic_paper_plane.svg";
// -------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react';
import IwdInput from "../../mini-components/IwdInput/IwdInput";
import { Dialog } from 'material-ui';
import { searchUserByPan, sendRelationshipRequest } from '../../common/ApiCalls';
import DotDotLoader from '../../../common/ui/DotDotLoader';
import { PrimaryButton } from '../../common/Button';
import PageCloseBtn from '../../mini-components/PageCloseBtn'; 
import IwdDialog from '../../mini-components/IwdDialog/IwdDialog';
import { nativeCallback } from '../../../utils/native_callback';

function AddMembers(props){
    const [pan, setPan] = useState("");
    const [isVerifyingPan, setIsVerifyingPan] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [panError, setPanError] = useState("");
    const [memberUserId, setMemberUserId] = useState("");
    const [relation, setRelation] = useState("");

    const panInputSubtitle = useMemo(() => {
        if (panError) return panError;
        if (isVerifyingPan) return <DotDotLoader className="iwd-dot-loader" />;
        return "Enter full PAN, in the case of minors, enter the guardian’s PAN";
    }, [panError, isVerifyingPan]);

    const sendEvents = (user_action) => {
        const eventObj = {
            "event_name": 'internal dashboard hni',
            "properties": {
                screen_name: 'add member',
                user_action,
            }
        };
        nativeCallback({ events: eventObj });
    };

    const verifyPan = async () => {
        try {
            setIsVerifyingPan(true);
            const res = await searchUserByPan(pan);
            setMemberUserId(res.user_id);
            setIsVerifyingPan(false);
        } catch (err) {
            setPanError(err);
        } finally {
            setIsVerifyingPan(false);
        }
    }

    const sendAddRequest = async () => {
        try {
            sendEvents('confirm');
            setIsSendingRequest(true);
            await sendRelationshipRequest({
                pan,
                relation,
                c_id: memberUserId,
            });
            setIsSuccessDialogOpen(true);
        } catch (err) {
            setPanError(err);
        } finally {
            setIsSendingRequest(false);
        }
    }

    useEffect(() => {
        if (pan.length === 10) {
            verifyPan();
        } else if (pan.length < 10) {
            setMemberUserId("");
        }
    }, [pan])

    const handlePanInput = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setPanError("");
            setPan(value);
        }
    }
    
    const handleRelationInput = (e) => {
        const value = e.target.value;
        setRelation(value);
    }

    const onCtaClick = () => {
        sendAddRequest();
    }

    const onSuccessDialogClose = () => {
        setIsSuccessDialogOpen(false);
        props.onClose(true);
    }

    const onCloseClick = () => {
        sendEvents('close');
        props.onClose();
    }

    return(
        <Dialog fullScreen={true} open={props.open} classes={{ paper: 'iwd-add-member-detail'}}>
            <div className="iwd-add-member-container">
                <PageCloseBtn onClick={onCloseClick} />
                <header>
                    <div className='iwd-header-container'>
                        <div className="iwd-page-header1">Add member</div>
                    </div>
                </header>

                <div className="iwd-amc-input-label">Add PAN</div>
                <IwdInput
                    onChange={handlePanInput}
                    subtitle={panInputSubtitle}
                    disabled={isVerifyingPan}
                    hasError={!!panError}
                    value={pan}
                />

                <div className="iwd-amc-input-label" style={{ marginTop: '30px' }}>Add Relation</div>
                <IwdInput
                    onChange={handleRelationInput}
                    disabled={!memberUserId}
                    value={relation}
                />
                <PrimaryButton
                    classes={{ raised: "iwd-confirm-button" }}
                    onClick={onCtaClick}
                    disabled={!memberUserId || !relation || panError}
                    isLoading={isSendingRequest}
                    uppercaseText
                >
                    Confirm
                </PrimaryButton>
                <SuccessDialog
                    open={isSuccessDialogOpen}
                    onClose={onSuccessDialogClose}
                />
            </div>
        </Dialog>
    )
}

export default AddMembers;

const SuccessDialog = ({ open, onClose }) => {
    return (
        <IwdDialog
            open={open}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            primaryButtonProps={{
                onClick: onClose,
                title: 'Okay',
                fullWidth: true
            }}
        >
            <div className="iwd-am-diag-img">
                <img src={PaperPlaneIcon} alt="paper plane" width="104" />
            </div>
            <div className="iwd-am-dialog-content">
                Account linking request sent! Please wait until it’s approved
            </div>
        </IwdDialog>
    );
}