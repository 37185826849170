import React from 'react';

const Icon = (props) => (
  <img
    alt=""
    src={props.src}
    width={props.width} />
);

export default Icon;
