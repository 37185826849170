import React from "react";
import fisdom_hni_logo from 'assets/fisdom/fisdom_hni_logo_gold.svg';
import { useUserContext } from "../common/UserContext";
import { navigate as navigateFunc } from "../common/commonFunctions";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../common/Button";
import isEmpty from 'lodash/isEmpty';

const NotFound = () => {
  const { userDetails } = useUserContext();
  const history = useHistory();
  const navigate = navigateFunc.bind({ history });

  const redirectUser = () => {
    if (!isEmpty(userDetails)) {
      navigate("main/dashboard");
    } else {
      navigate("login");
    }
  };

  return (
    <div id="not-found-page-container">
      <div id="nf-img-container">
        <img
          src={fisdom_hni_logo}
          alt="fisdom"
          className="not-found-page-img"
        />
      </div>
      <div id="not-found-content-container">
        <h2 className="not-found-pc-header1">404</h2>
        <h2 className="not-found-pc-header2">NOT FOUND</h2>
        <div id="nf-button-container">
          <PrimaryButton
            classes={{
              root: "not-found-btn",
              label: "not-found-btn-text",
            }}
            onClick={redirectUser}
            fullWidth={true}
          >
            Redirect Me
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
