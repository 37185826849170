import positive from 'assets/ic_positive.svg';
import negative from 'assets/ic_negative.svg';
import './IwdReturns.scss';
import React from 'react';
import { useMemo } from 'react';
import { returnsFormatter } from '../../common/commonFunctions';

const IwdReturns = ({
  value,
  label,
  classes = {}
}) => {
  const formattedValue = useMemo(() => returnsFormatter(value), [value]);
  const numericValue = Number(value);

  return (
    <div className={`iwd-returns ${classes.root}`}>
      <div>
        {formattedValue && numericValue !== 0
          ? <img src={numericValue > 0 ? positive : negative} alt="" className={`iwd-returns-img ${classes.img}`} />
          : ''
        }
        <span className={`iwd-returns-value ${classes.value}`}>{formattedValue || '--'}</span>
      </div>
      <div className={`iwd-returns-label ${classes.label}`}>{label}</div>
    </div>
  );
}

export default IwdReturns;